/**
 * Indicate that we launch a request to retrieve profile informations of connected user from API
 * @type {string}
 */
export const RETRIEVE_PROFILE_INFORMATION_VALUES_REQUEST = 'GET_PROFILE_INFORMATION_VALUES_REQUEST';

/**
 * Indicate that the request to retrieve profile informations of connected user from API was successful
 * @type {string}
 */
export const RETRIEVE_PROFILE_INFORMATION_VALUES_SUCCESS = 'GET_PROFILE_INFORMATION_VALUES_SUCCESS';

/**
 * Indicate that the request to retrieve profile informations of connected user from API failed
 * @type {string}
 */
export const RETRIEVE_PROFILE_INFORMATION_VALUES_FAILURE = 'GET_PROFILE_INFORMATION_VALUES_FAILURE';

/**
 * Indicate that the we launch a request to save and update profile informations of connected user from API
 * @type {string}
 */
export const UPDATE_PROFILE_INFORMATION_VALUES_REQUEST = 'UPDATE_PROFILE_INFORMATION_VALUES_REQUEST';

/**
 * Indicate that the request to save and update profile informations of connected user from API was successful
 * @type {string}
 */
export const UPDATE_PROFILE_INFORMATION_VALUES_SUCCESS = 'UPDATE_PROFILE_INFORMATION_VALUES_SUCCESS';

/**
 * Indicate that the request to save and update profile informations of connected user from API failed
 * @type {string}
 */
export const UPDATE_PROFILE_INFORMATION_VALUES_FAILURE = 'UPDATE_PROFILE_INFORMATION_VALUES_FAILURE';

/**
 * Indicate that we want to clear all informations of profile
 * @type {string}
 */
export const CLEAR_PROFILE_INFORMATION_VALUES = 'CLEAR_PROFILE_INFORMATION_VALUES';
