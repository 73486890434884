import {get} from "axios"
import {GET_TEXTS_ENDPOINT} from "../../../constants/endpoints"
import {RETRIEVE_TEXTS_FAILURE, RETRIEVE_TEXTS_REQUEST, RETRIEVE_TEXTS_SUCCESS} from "./textsActionTypes"
import {getErrorMessage} from "../../../services/api"

/**
 * Thunk to retrieve customized texts.
 * Dispatch on request and on success or failure.
 * @param espaceType the name of the application
 * @param contractId the id of the contract
 */
export const retrieveTexts = (espaceType, contractId) => dispatch => {
    dispatch({
        type: RETRIEVE_TEXTS_REQUEST,
    })

    // Build query parameters.
    const params = {
        numeroContrat: contractId,
        typeEspace: espaceType,
    }

    return get(GET_TEXTS_ENDPOINT, {params}).then(
        response => {
            dispatch({
                type: RETRIEVE_TEXTS_SUCCESS,
                response: response.data,
            })

            return Promise.resolve(response.data)
        },
        error => {
            dispatch({
                type: RETRIEVE_TEXTS_FAILURE,
                message: getErrorMessage(error),
                numeroContrat: contractId,
            })
        },
    )
}
