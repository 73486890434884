/**
 * Indicate that we start a get refund request
 */
export const GET_REFUNDS_OF_INTERVENANT_REQUEST = "GET_REFUNDS_OF_INTERVENANT_REQUEST"
/**
 * Indicate that the get of refund request was successful
 */
export const GET_REFUNDS_OF_INTERVENANT_SUCCESS = "GET_REFUNDS_OF_INTERVENANT_SUCCESS"
/**
 * Indicate that the get of refund request has failed
 */
export const GET_REFUNDS_OF_INTERVENANT_FAILURE = "GET_REFUNDS_OF_INTERVENANT_FAILURE"
