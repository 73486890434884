/**
 * Indicate that we want to fill a table with new data.
 * @type {string}
 */
export const FILL_TABLE_DATA = "FILL_TABLE_DATA"

/**
 * Indicate that we want to add a table data
 * @type {String}
 */
export const ADD_TABLE_DATA = "ADD_TABLE_DATA"

/**
 * Indicate that we want to remove table data
 * @type {String}
 */
export const REMOVE_TABLE_DATA = "REMOVE_TABLE_DATA"

/**
 * Indicate that we want to empty a table.
 * @type {string}
 */
export const EMPTY_TABLE = "EMPTY_TABLE"

/**
 * Indicate that we want to clear all data in a table.
 * @type {string}
 */
export const CLEAR_TABLE = "CLEAR_TABLE"

/**
 * Indicate that we want to select all data of a table.
 * @type {string}
 */
export const SELECT_ALL_TABLE_DATA = "SELECT_ALL_TABLE_DATA"

/**
 * Indicate that we want to deselect all data of a table.
 * @type {string}
 */
export const DESELECT_ALL_TABLE_DATA = "DESELECT_ALL_TABLE_DATA"

/**
 * Indicate that we ant to select a line of a table.
 * @type {string}
 */
export const TOGGLE_SELECT_LINE_TABLE_DATA = "TOGGLE_SELECT_LINE_TABLE_DATA"

/**
 * Indicate that we want to activate a line of a table.
 * @type {string}
 */
export const TOGGLE_ACTIVATE_LINE_TABLE_DATA = "TOGGLE_ACTIVATE_LINE_TABLE_DATA"

/**
 * Indicate that we want to activate many lines of a table.
 * @type {string}
 */
export const TOGGLE_ACTIVATE_MANY_LINES_TABLE_DATA = "TOGGLE_ACTIVATE_MANY_LINES_TABLE_DATA"

/**
 * Indicate that we want to activate many lines of a table.
 * @type {string}
 */
export const ACTIVATE_MANY_LINES_TABLE_DATA = "ACTIVATE_MANY_LINES_TABLE_DATA"

/**
 * Indicate that we want to desactivate many lines of a table.
 * @type {string}
 */
export const DESACTIVATE_MANY_LINES_TABLE_DATA = "DESACTIVATE_MANY_LINES_TABLE_DATA"

/**
 * Indicate that we want to edit a line of a table.
 * @type {string}
 */
export const EDIT_LINE_TABLE_DATA = "EDIT_LINE_TABLE_DATA"
