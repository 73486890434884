import PropTypes from "prop-types"
import React from "react"
import {Dimmer, Loader} from "semantic-ui-react"

/**
 * Draw a round turning loader with children component under it.
 * TODO: delete.
 */
const RoundLoader = ({children}) => (
    <Dimmer active inverted>
        <Loader active indeterminate inverted size="big">
            {children}
        </Loader>
    </Dimmer>
)

RoundLoader.defaultProps = {
    children: null,
}

RoundLoader.propTypes = {
    children: PropTypes.any,
}

export default RoundLoader
