/**
 * Indicate that we launched a request to retrieve web accesses.
 */
export const RETRIEVE_WEB_ACCESS_REQUEST = "RETRIEVE_WEB_ACCESS_REQUEST";
/**
 * Indicate that we retrieved successfully web accesses.
 */
export const RETRIEVE_WEB_ACCESS_SUCCESS = "RETRIEVE_WEB_ACCESS_SUCCESS";
/**
 * Indicate that we didn't receive web accesses.
 */
export const RETRIEVE_WEB_ACCESS_FAILURE = "RETRIEVE_WEB_ACCESS_FAILURE";
