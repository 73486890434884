/**
 * Indicate that we launched a request to search a town.
 * @type {string}
 */
export const SEARCH_TOWN_REQUEST = "SEARCH_TOWN_REQUEST"

/**
 * Indicate that we successfully search a town.
 * @type {string}
 */
export const SEARCH_TOWN_SUCCESS = "SEARCH_TOWN_SUCCESS"

/**
 * Indicate that the town searched failed.
 * @type {string}
 */
export const SEARCH_TOWN_FAILURE = "SEARCH_TOWN_FAILURE"
