import { get, post, patch } from "axios"
import { addTokenToHeader, removeTokenFromHeader } from "../../../services/configureAxios"
import {
    AUTH_ENDPOINTS,
    CONNECT_USER_ENDPOINT,
    GET_USER_INFO_ENDPOINT,
    PASSWORD_ENDPOINTS,
} from "../../../constants/endpoints"
import {
    CONNECT_USER_FAILURE,
    CONNECT_USER_REQUEST,
    CONNECT_USER_SUCCESS,
    DISCONNECT_USER,
    GET_USER_INFO_SUCCESS,
    GET_USER_INFO_FAILURE,
    GET_USER_INFO_REQUEST,
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    SET_BYPASSED_AUTHENTICATION,
} from "./userActionTypes"
import { saveDynamicConfiguration } from "../../localStorage/dynamicConfiguration/dynamicConfigurationActions"
import { DYN_CONF_KEY_CONTRACT_ID } from "../../../constants/dynamicConfigurationKeys"
import { getErrorMessage } from "../../../services/api"
import { RequestInfo, RequestKeys } from "../../../constants/requests"
import { REQUEST_SUCCESS, REQUEST, REQUEST_FAILURE } from "../../../store/requests/requestsReducer"
import { genericThunk } from "../../actionsUtils"
import httpMethod from "../../../constants/httpMethods"
import { LOGIN_MINIMUM_DELAY } from "../../../constants/forms"
import { addNotification } from "../../notifications/notificationsActions"
import uuidv4 from "uuid"

/**
 * Thunk to connect a user.
 * Dispatch on request and on success or failure.
 * @param login the user name
 * @param password the user password
 * @param typeEspace the application area where you want to connect
 */
export const connectUser =
    (login, password, typeEspace, confirmation = null, startTime = null) =>
        dispatch => {
            dispatch({
                type: CONNECT_USER_REQUEST,
            })

            // Build request body.
            const body = {
                login,
                password,
                typeEspace,
            }

            // Calcul du temps de saisie du formulaire
            const totalTime = startTime ? Date.now() - startTime : null
            // La valeur confirmation provient d'un champ caché dans la page d'inscription, pour tester si le formulaire est rempli par un robot
            if (confirmation !== null || (totalTime && totalTime < LOGIN_MINIMUM_DELAY)) {
                dispatch(
                    addNotification({
                        id: uuidv4(),
                        type: "error",
                        header: "Erreur lors de l'envoi du formulaire",
                        content: "Veuillez réessayer",
                        autoDismiss: true,
                    }),
                )

                dispatch({
                    type: CONNECT_USER_FAILURE,
                    message: "Erreur lors de l'envoi du formulaire",
                })
                removeTokenFromHeader()

                return Promise.reject()
            } else {
                return post(CONNECT_USER_ENDPOINT, body).then(
                    response => {
                        addTokenToHeader(response.data.token)
                        dispatch({
                            type: CONNECT_USER_SUCCESS,
                            response: response.data,
                        })

                        return Promise.resolve()
                    },
                    error => {
                        dispatch({
                            type: CONNECT_USER_FAILURE,
                            message: getErrorMessage(error),
                        })
                        removeTokenFromHeader()

                        return Promise.reject()
                    },
                )
            }
        }

/**
 * Thunk to disconnect an user.
 */
export const disconnectUser = () => dispatch => {
    dispatch({
        type: DISCONNECT_USER,
    })
    removeTokenFromHeader()
    return Promise.resolve()
}

/**
 * Thunk to retrieve connected user information
 */

export const connectedUserInfo = () => dispatch => {
    dispatch({
        type: GET_USER_INFO_REQUEST,
    })

    return get(GET_USER_INFO_ENDPOINT).then(
        response => {
            dispatch({
                type: GET_USER_INFO_SUCCESS,
                response: response.data,
            })

            if (response.data.idContrat) {
                dispatch(saveDynamicConfiguration(DYN_CONF_KEY_CONTRACT_ID, response.data.idContrat))
            }

            return Promise.resolve()
        },
        error => {
            dispatch({
                type: GET_USER_INFO_FAILURE,
                message: getErrorMessage(error),
            })

            return Promise.reject()
        },
    )
}

/**
 * Thunk to start the recover login/password process
 */
export const recoverLoginPassword = (typeEspace, login, siret, birthDate) => dispatch => {
    const params = {
        typeEspace,
        login,
        siret,
        birthDate,
    }

    const url = AUTH_ENDPOINTS.recoverLoginPassword

    return genericThunk(
        null,
        httpMethod.GET,
        dispatch,
        url,
        params,
        RequestInfo[RequestKeys.RECOVER_LOGIN_PASSWORD].name,
        null,
    )
}

export const changePassword = (newPassword, resetPasswordToken) => dispatch => {
    dispatch({
        type: CHANGE_PASSWORD_REQUEST,
    })

    const body = {
        nouveauMotDePasse: newPassword,
        resetPasswordToken,
    }

    return post(PASSWORD_ENDPOINTS.changePassword, body).then(
        response => {
            dispatch({
                type: CHANGE_PASSWORD_SUCCESS,
            })

            return Promise.resolve(response)
        },
        error => {
            dispatch({
                type: CHANGE_PASSWORD_FAILURE,
                message: getErrorMessage(error),
            })

            return Promise.reject()
        },
    )
}

/**
 * Thunk to give the possibility to a user to change his password into his authentication informations
 * @param oldPassword   oldPassword user
 * @param newPassword   newPassword user
 * @param newPasswordConfirmation   newPasswordConfirmation user
 *  */
export const changeActualPassword = (oldPassword, newPassword, newPasswordConfirmation) => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.CHANGE_ACTUAL_PASSWORD].name,
    })

    const body = {
        ancienMotDePasse: oldPassword,
        nouveauMotDePasse: newPassword,
        nouveauMotDePasseConfirmation: newPasswordConfirmation,
    }

    return patch(PASSWORD_ENDPOINTS.changeActualPassword, body).then(
        () => {
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.CHANGE_ACTUAL_PASSWORD].name,
            })

            return Promise.resolve()
        },
        error => {
            const message = getErrorMessage(error)
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.CHANGE_ACTUAL_PASSWORD].name,
                message,
            })

            return Promise.reject(message)
        },
    )
}

/**
 * Thunk to mimiq user connection.
 * Dispatch on request and on success or failure.
 * @param sourceId the ciphered user id
 * @param userLogin the ciphered user login
 * @param areaType the application area where you want to connect
 * @param bypassToken the token used to bypass the login
 */
export const bypassLogin = (sourceId, userLogin, bypassToken, areaType, preOrderSessionId) => dispatch => {
    dispatch({
        type: CONNECT_USER_REQUEST,
    })

    // Build request body.
    const body = {
        identifiantSource: sourceId,
        identifiantCible: userLogin,
        jetonConnexion: bypassToken,
        typeEspace: areaType,
        idSessionPreCommande: preOrderSessionId ? preOrderSessionId : null,
    }

    return post(AUTH_ENDPOINTS.bypassLogin, body).then(
        response => {
            dispatch({
                type: CONNECT_USER_SUCCESS,
                response: response.data,
            })
            dispatch({
                type: SET_BYPASSED_AUTHENTICATION,
            })
            addTokenToHeader(response.data.token)

            return Promise.resolve()
        },
        error => {
            const message = getErrorMessage(error)
            dispatch({
                type: CONNECT_USER_FAILURE,
                message,
            })
            removeTokenFromHeader()

            return Promise.reject(message)
        },
    )
}

export const recoverLoginBeneficiaryArea = (lastname, firstname, birthDate, postalCode, contractNumber) => dispatch => {
    const body = {
        nom: lastname,
        prenom: firstname,
        dateNaissance: birthDate,
        codePostal: postalCode,
        numeroContrat: contractNumber,
    }

    const url = AUTH_ENDPOINTS.recoverLoginBeneficiaryArea

    const payloadHandler = response => ({
        clientType: response.data.typeClient,
        mailSentTo: response.data.destinataires,
    })

    return genericThunk(
        body,
        httpMethod.POST,
        dispatch,
        url,
        undefined,
        RequestInfo[RequestKeys.RECOVER_LOGIN_BENEFICIARY_AREA].name,
        payloadHandler,
    )
}
