import {CLEAR_DYNAMIC_CONFIGURATION, SAVE_DYNAMIC_CONFIGURATION_VALUE} from "./dynamicConfigurationActionTypes";

/**
 * Thunk to save a dynamic configuration key/value pair.
 */
export const saveDynamicConfiguration = (key, value) => dispatch => {
    dispatch({
        type: SAVE_DYNAMIC_CONFIGURATION_VALUE,
        key,
        value,
    });
};

/**
 * Thunk to clear all the dynamic configuration.
 */
export const clearDynamicConfiguration = () => dispatch => {
    dispatch({
        type: CLEAR_DYNAMIC_CONFIGURATION,
    });
};
