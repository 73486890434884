import * as React from "react"
import "./errorBoundary.scss"
import logo from "../../img/logo.jpg"
import Button from "../Button/button"
import PropTypes from "prop-types"

const ErrorBoundary = ({redirectToHomePage}, {texts}) => (
    <div className="error-component">
        <div className="outer">
            <div className="inner">
                <div className="content">
                    <img alt="logo" className="logo" src={logo} />
                    <div className="oops font-bold">Oups ...</div>
                    <div className="text">{texts.technicalErrorText}</div>
                    <Button onClick={redirectToHomePage}>Retourner à la page d'accueil</Button>
                </div>
            </div>
        </div>
    </div>
)

ErrorBoundary.propTypes = {
    /**
     * A function that redirects to the home page
     */
    redirectToHomePage: PropTypes.func.isRequired,
}

ErrorBoundary.contextTypes = {
    texts: PropTypes.object,
}

export default ErrorBoundary
