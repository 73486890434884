/**
 * Indicate that we launched a request to get the invoice calculations rules.
 */
export const GET_RULES_INVOICE_CALCULATIONS_REQUEST = "GET_RULES_INVOICE_CALCULATIONS_REQUEST";
/**
 * Indicate that we successfully get the invoice calculations rules.
 */
export const GET_RULES_INVOICE_CALCULATIONS_SUCCESS = "GET_RULES_INVOICE_CALCULATIONS_SUCCESS";
/**
 * Indicate that we didn't get the invoice calculations rules.
 */
export const GET_RULES_INVOICE_CALCULATIONS_FAILURE = "GET_RULES_INVOICE_CALCULATIONS_FAILURE";

/**
 * Indicate that we launched a request to get the invoice calculations TVAs.
 */
export const GET_TVA_INVOICE_CALCULATIONS_REQUEST = "GET_TVA_INVOICE_CALCULATIONS_REQUEST";
/**
 * Indicate that we successfully get the invoice calculations TVAs.
 */
export const GET_TVA_INVOICE_CALCULATIONS_SUCCESS = "GET_TVA_INVOICE_CALCULATIONS_SUCCESS";
/**
 * Indicate that we didn't get the invoice calculations TVAs.
 */
export const GET_TVA_INVOICE_CALCULATIONS_FAILURE = "GET_TVA_INVOICE_CALCULATIONS_FAILURE";
