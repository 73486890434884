import React from "react"
import PropTypes from "prop-types"
import MenuItemContainer from "../MenuItem/menuItemContainer"
import HamburgerButton from "../HamburgerButton/hamburgerButton"
import MenuDropdownPushContainer from "../MenuDropdownPush/menuDropdownPushContainer"
import {displayMenu, isFeatureEnabled} from "../../services/displayMenu"

import "./mobileMenu.scss"

/**
 * Mobile menu, for small screens.
 */
const MobileMenu = (
    {
        isResponsiveMenuOpened,
        toggleResponsiveMenu,
        content,
        logo,
        onImageClick,
        onDisconnectClick,
        isConnected,
        isPetitClient,
        contractId,
        onProfilClick,
        title,
        userPermissions,
        displayNotificationFunction,
        displayNotification,
    },
    {sheet},
) => {
    const menus = content.map((c, index) => {
        switch (c.type) {
            // Menu item.
            case "ITEM":
                return (
                    displayMenu(isConnected, c.permissions, c.userConnected, userPermissions, c.isFeatureEnabled) && (
                        <MenuItemContainer
                            active={c.active}
                            displayNotificationFunction={displayNotificationFunction}
                            features={c.feature}
                            key={index}
                            onClick={c.onClick}
                        >
                            {c.text}
                        </MenuItemContainer>
                    )
                )

            // Menu dropdown list.
            case "DROPDOWN":
                return (
                    displayMenu(
                        isConnected,
                        c.permissions,
                        c.userConnected,
                        userPermissions,
                        isFeatureEnabled(c.choices),
                    ) && (
                        <MenuDropdownPushContainer
                            choices={c.choices}
                            displayNotificationFunction={displayNotificationFunction}
                            isConnected={isConnected}
                            key={index}
                            text={c.text}
                            userPermissions={userPermissions}
                        />
                    )
                )

            default:
                return <div>UNKOWN_TYPE</div>
        }
    })

    const displayHamburgerButton = menus.filter(menu => menu !== false).length > 0

    return (
        <div className="mobile-menu-component">
            {/* Header is just the logo and a hamburger menu button. */}
            <div className="mobile-menu-component-responsive-header">
                <div className="mobile-menu-component-div">
                    <img alt="logo" className="mobile-menu-component-div-logo" onClick={onImageClick} src={logo} />
                </div>
                <h1 className={sheet.classes.textSecondaryColor + " mobile-menu-component-title"}>{title}</h1>
                <div>
                    {displayHamburgerButton && (
                        <HamburgerButton displayNotification={displayNotification} onClick={toggleResponsiveMenu} />
                    )}
                </div>
            </div>

            {isResponsiveMenuOpened && (
                <div className="mobile-menu-component-responsive-inner">
                    {/* Loop through menu content. */}
                    {menus}

                    {/* User is connected, put the profil choice. */}
                    {isConnected && isPetitClient && (
                        <MenuItemContainer active={false} onClick={() => onProfilClick()}>
                            Profil
                        </MenuItemContainer>
                    )}
                    {/* User is connected, put the disconnection choice. */}
                    {isConnected && (
                        <MenuItemContainer active={false} onClick={() => onDisconnectClick(contractId)}>
                            Se déconnecter
                        </MenuItemContainer>
                    )}
                </div>
            )}
        </div>
    )
}

MobileMenu.propTypes = {
    // Is menu opened ?
    isResponsiveMenuOpened: PropTypes.bool,
    // Open/close menu.
    toggleResponsiveMenu: PropTypes.func.isRequired,

    content: PropTypes.arrayOf(
        PropTypes.oneOfType([
            // An item.
            PropTypes.shape({
                type: PropTypes.oneOf(["ITEM"]),
                text: PropTypes.string.isRequired,
                onClick: PropTypes.func.isRequired,
                active: PropTypes.bool,
                permissions: PropTypes.arrayOf(PropTypes.string),
            }),
            // A dropdown list.
            PropTypes.shape({
                type: PropTypes.oneOf(["DROPDOWN"]),
                text: PropTypes.string.isRequired,
                permissions: PropTypes.arrayOf(PropTypes.string),
                // Panel alignment.
                alignment: PropTypes.oneOf(["LEFT", "RIGHT"]),
                choices: PropTypes.arrayOf(
                    PropTypes.oneOfType([
                        // A dropdown item.
                        PropTypes.shape({
                            type: PropTypes.oneOf(["ITEM"]),
                            text: PropTypes.string.isRequired,
                            onClick: PropTypes.func.isRequired,
                            active: PropTypes.bool,
                            permissions: PropTypes.arrayOf(PropTypes.string),
                        }),
                        // A dropdown text.
                        PropTypes.shape({
                            type: PropTypes.oneOf(["DROPDOWN"]),
                            text: PropTypes.string.isRequired,
                            permissions: PropTypes.arrayOf(PropTypes.string),
                            choices: PropTypes.arrayOf(
                                PropTypes.shape({
                                    type: PropTypes.oneOf(["ITEM"]),
                                    text: PropTypes.string.isRequired,
                                    onClick: PropTypes.func.isRequired,
                                    active: PropTypes.bool,
                                    permissions: PropTypes.arrayOf(PropTypes.string),
                                }),
                            ),
                        }),
                    ]),
                ).isRequired,
            }),
        ]),
    ).isRequired,

    // Source of the logo.
    logo: PropTypes.string.isRequired,
    onImageClick: PropTypes.func.isRequired,
    onDisconnectClick: PropTypes.func.isRequired,
    isConnected: PropTypes.bool.isRequired,
    isPetitClient: PropTypes.bool,
    contractId: PropTypes.string,

    // Title
    title: PropTypes.string.isRequired,

    userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    displayNotificationFunction: PropTypes.func,
}

MobileMenu.contextTypes = {
    sheet: PropTypes.object,
}

export default MobileMenu
