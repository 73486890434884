import {SAVE_COOKIES_CHOICES} from "../../../actions/localStorage/cookies/cookiesActionTypes"

/**
 * Cookies reducer.
 * Stock in store the new cookies choices and other parameters.
 */
const cookies = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_COOKIES_CHOICES:
            return {
                ...state,
                ...action.values,
            }

        default:
            return state
    }
}

export default cookies

/**
 * Retrieve the cookies choices
 */
export const getCookiesChoices = state => state
