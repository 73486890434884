/**
 * Default values for global style.
 * The structure is a JSON containing key/value pairs.
 */
const defaultStyle = {
    primaryColor: "#6bb6e6",
    secondaryColor: "#1d3561",
    tertiaryColor: "#a1b1bc",
    successColor: "#7bc200",
    warningColor: "#ffd600",
    dangerColor: "#e84136",
}

export default defaultStyle
