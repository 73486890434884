/**
 * The dynamic configuration key for the contract ID.
 */
export const DYN_CONF_KEY_CONTRACT_ID = "DYN_CONF_KEY_CONTRACT_ID"
/**
 * The dynamic configuration key for the inscription type
 */
export const DYN_CONF_KEY_BENEFICIARY_REGISTRATION_TYPE = "DYN_CONF_KEY_BENEFICIARY_REGISTRATION_TYPE"
/**
 * The dynamic configuration key for the registration contract type
 */
export const DYN_CONF_KEY_BENEFICIARY_REGISTRATION_CONTRACT_TYPE = "DYN_CONF_KEY_BENEFICIARY_REGISTRATION_CONTRACT_TYPE"
