import {
    RETRIEVE_BENEFICIARIES_REQUEST,
    RETRIEVE_BENEFICIARIES_SUCCESS,
    RETRIEVE_BENEFICIARIES_FAILURE,
    RETRIEVE_BENEFICIARY_ACTIVE_CESU_ACCOUNTS_REQUEST,
    RETRIEVE_BENEFICIARY_ACTIVE_CESU_ACCOUNTS_SUCCESS,
    RETRIEVE_BENEFICIARY_ACTIVE_CESU_ACCOUNTS_FAILURE,
    RETRIEVE_BENEFICIARY_CONVERTIBLE_CESU_CHECKS_REQUEST,
    RETRIEVE_BENEFICIARY_CONVERTIBLE_CESU_CHECKS_SUCCESS,
    RETRIEVE_BENEFICIARY_CONVERTIBLE_CESU_CHECKS_FAILURE,
    RETRIEVE_BENEFICIARY_USABLE_CESU_CHECKS_REQUEST,
    RETRIEVE_BENEFICIARY_USABLE_CESU_CHECKS_SUCCESS,
    RETRIEVE_BENEFICIARY_USABLE_CESU_CHECKS_FAILURE,
    RETRIEVE_BENEFICIARY_OPENED_SESSIONS_SUCCESS,
    RETRIEVE_BENEFICIARY_OPENED_SESSIONS_FAILURE,
    RETRIEVE_BENEFICIARY_OPENED_SESSIONS_REQUEST,
    RETRIEVE_BENEFICIARY_ORDER_HISTORY_REQUEST,
    RETRIEVE_BENEFICIARY_ORDER_HISTORY_SUCCESS,
    RETRIEVE_BENEFICIARY_ORDER_HISTORY_FAILURE,
    RETRIEVE_BENEFICIARY_ACCOUNT_STATEMENT_REQUEST,
    RETRIEVE_BENEFICIARY_ACCOUNT_STATEMENT_SUCCESS,
    RETRIEVE_BENEFICIARY_ACCOUNT_STATEMENT_FAILURE,
    RETRIEVE_BENEFICIARY_ACCOUNT_PAYMENTS_HISTORY_REQUEST,
    RETRIEVE_BENEFICIARY_ACCOUNT_PAYMENTS_HISTORY_SUCCESS,
    RETRIEVE_BENEFICIARY_ACCOUNT_PAYMENTS_HISTORY_FAILURE,
    RETRIEVE_BENEFICIARY_SEARCHED_INTERVENANTS_REQUEST,
    RETRIEVE_BENEFICIARY_SEARCHED_INTERVENANTS_SUCCESS,
    RETRIEVE_BENEFICIARY_SEARCHED_INTERVENANTS_FAILURE,
    RETRIEVE_BENEFICIARY_CHECKS_INTERVENANT_PAYMENT_REQUEST,
    RETRIEVE_BENEFICIARY_CHECKS_INTERVENANT_PAYMENT_SUCCESS,
    RETRIEVE_BENEFICIARY_CHECKS_INTERVENANT_PAYMENT_FAILURE,
    RETRIEVE_BENEFICIARY_CHECKBOOK_STATEMENT_REQUEST,
    RETRIEVE_BENEFICIARY_CHECKBOOK_STATEMENT_SUCCESS,
    RETRIEVE_BENEFICIARY_CHECKBOOK_STATEMENT_FAILURE,
    RETRIEVE_BENEFICIARY_CHECK_PAYMENTS_HISTORY_REQUEST,
    RETRIEVE_BENEFICIARY_CHECK_PAYMENTS_HISTORY_SUCCESS,
    RETRIEVE_BENEFICIARY_CHECK_PAYMENTS_HISTORY_FAILURE,
    RETRIEVE_BENEFICIARY_TAX_DOCUMENT_REQUEST,
    RETRIEVE_BENEFICIARY_TAX_DOCUMENT_SUCCESS,
    RETRIEVE_BENEFICIARY_TAX_DOCUMENT_FAILURE,
} from "../actions/entities/beneficiaries/beneficiariesActionTypes"
import {
    RETRIEVE_INTERVENANTS_REQUEST,
    RETRIEVE_INTERVENANTS_SUCCESS,
    RETRIEVE_INTERVENANTS_FAILURE,
} from "../actions/entities/intervenants/intervenantsActionTypes"
import {
    RETRIEVE_ORDERS_REQUEST,
    RETRIEVE_ORDERS_SUCCESS,
    RETRIEVE_ORDERS_FAILURE,
} from "../actions/entities/orders/orderActionTypes"
import {
    RETRIEVE_ORDER_DEPOSITS_REQUEST,
    RETRIEVE_ORDER_DEPOSITS_SUCCESS,
    RETRIEVE_ORDER_DEPOSITS_FAILURE,
} from "../actions/entities/orderDeposits/orderDepositActionTypes"
import {
    RETRIEVE_PRODUCTS_SUCCESS,
    RETRIEVE_PRODUCTS_REQUEST,
    RETRIEVE_PRODUCTS_FAILURE,
} from "../actions/entities/products/productsActionTypes"
import {
    RETRIEVE_ALL_SERVICE_TYPES_REQUEST,
    RETRIEVE_ALL_SERVICE_TYPES_SUCCESS,
    RETRIEVE_ALL_SERVICE_TYPES_FAILURE,
} from "../actions/entities/serviceTypes/serviceTypesActionTypes"
import {
    RETRIEVE_STAFF_CATEGORIES_REQUEST,
    RETRIEVE_STAFF_CATEGORIES_SUCCESS,
    RETRIEVE_STAFF_CATEGORIES_FAILURE,
} from "../actions/entities/staffCategories/staffCategoriesActionTypes"
import {
    RETRIEVE_SESSION_GROUPS_REQUEST,
    RETRIEVE_SESSION_GROUPS_SUCCESS,
    RETRIEVE_SESSION_GROUPS_FAILURE,
} from "../actions/entities/sessionGroups/sessionGroupsActionTypes"
import {INSERT_STYLEGUIDE_DATA} from "../actions/entities/styleguideData/styleguideDataActionTypes"
import {accountProductIds, checkbookProductIds} from "../store/ids/products/productsIdsReducer"
import {
    RETRIEVE_BILLING_STATUSES_FAILURE,
    RETRIEVE_BILLING_STATUSES_REQUEST,
    RETRIEVE_BILLING_STATUSES_SUCCESS,
} from "../actions/entities/billingStatuses/billingStatusesActionTypes"
import {
    RETRIEVE_MANAGEMENT_REQUEST_FILES_FAILURE,
    RETRIEVE_MANAGEMENT_REQUEST_FILES_REQUEST,
    RETRIEVE_MANAGEMENT_REQUEST_FILES_SUCCESS,
    RETRIEVE_MANAGEMENT_REQUEST_IN_CHARGE_INTERLOCUTORS_FAILURE,
    RETRIEVE_MANAGEMENT_REQUEST_IN_CHARGE_INTERLOCUTORS_REQUEST,
    RETRIEVE_MANAGEMENT_REQUEST_IN_CHARGE_INTERLOCUTORS_SUCCESS,
    RETRIEVE_MANAGEMENT_REQUEST_CREATORS_INTERLOCUTORS_FAILURE,
    RETRIEVE_MANAGEMENT_REQUEST_CREATORS_INTERLOCUTORS_REQUEST,
    RETRIEVE_MANAGEMENT_REQUEST_CREATORS_INTERLOCUTORS_SUCCESS,
    RETRIEVE_MANAGEMENT_REQUEST_FOLLOW_UPS_FAILURE,
    RETRIEVE_MANAGEMENT_REQUEST_FOLLOW_UPS_REQUEST,
    RETRIEVE_MANAGEMENT_REQUEST_FOLLOW_UPS_SUCCESS,
    RETRIEVE_MANAGEMENT_REQUESTS_FAILURE,
    RETRIEVE_MANAGEMENT_REQUESTS_REQUEST,
    RETRIEVE_MANAGEMENT_REQUESTS_SUCCESS,
} from "../actions/entities/managementRequests/managementRequestsActionTypes"
import {
    RETRIEVE_WEB_ACCESS_FAILURE,
    RETRIEVE_WEB_ACCESS_REQUEST,
    RETRIEVE_WEB_ACCESS_SUCCESS,
} from "../actions/entities/webAccess/webAccessActionTypes"
import {
    RETRIEVE_ORDER_LINES_REQUEST,
    RETRIEVE_ORDER_LINES_SUCCESS,
    RETRIEVE_ORDER_LINES_FAILURE,
} from "../actions/entities/orderLines/orderLinesActionTypes"
import {
    GET_PREORDER_SESSION_LINES_FAILURE,
    GET_PREORDER_SESSION_LINES_REQUEST,
    GET_PREORDER_SESSION_LINES_SUCCESS,
    GET_PREORDER_SESSIONS_FAILURE,
    GET_PREORDER_SESSIONS_REQUEST,
    GET_PREORDER_SESSIONS_SUCCESS,
} from "../actions/entities/preOrderSessions/preOrderSessionsActionTypes"
import {
    RETRIEVE_BILLS_SUCCESS,
    RETRIEVE_BILLS_REQUEST,
    RETRIEVE_BILLS_FAILURE,
} from "../actions/entities/bills/billsActionTypes"
import {
    RETRIEVE_INTERLOCUTORS_REQUEST,
    RETRIEVE_INTERLOCUTORS_SUCCESS,
    RETRIEVE_INTERLOCUTORS_FAILURE,
} from "../actions/entities/interlocutors/interlocutorsActionTypes"
import {
    RETRIEVE_INTERLOCUTOR_BENEFICIARIES_REQUEST,
    RETRIEVE_INTERLOCUTOR_BENEFICIARIES_SUCCESS,
    RETRIEVE_INTERLOCUTOR_BENEFICIARIES_FAILURE,
    RETRIEVE_BENEFICIARIES_FOR_ACCESS_REQUEST,
    RETRIEVE_BENEFICIARIES_FOR_ACCESS_SUCCESS,
    RETRIEVE_BENEFICIARIES_FOR_ACCESS_FAILURE,
} from "../actions/entities/interlocutorBeneficiaries/interlocutorBeneficiariesActionTypes"
import {
    RETRIEVE_FILE_REFUND_DEPOSITS_REQUEST,
    RETRIEVE_FILE_REFUND_DEPOSITS_SUCCESS,
    RETRIEVE_FILE_REFUND_DEPOSITS_FAILURE,
} from "../actions/entities/fileRefundDeposits/fileRefundDepositsActionType"
import {
    GET_REFUNDS_OF_INTERVENANT_REQUEST,
    GET_REFUNDS_OF_INTERVENANT_SUCCESS,
    GET_REFUNDS_OF_INTERVENANT_FAILURE,
} from "../actions/entities/refunds/refundsActionTypes"
import {
    GET_CHECKBOOKS_OF_REFUND_REQUEST,
    GET_CHECKBOOKS_OF_REFUND_SUCCESS,
    GET_CHECKBOOKS_OF_REFUND_FAILURE,
} from "../actions/entities/checkbooks/checkbooksActionsType"
import {
    GET_PAYMENTS_OF_INTERVENANT_REQUEST,
    GET_PAYMENTS_OF_INTERVENANT_SUCCESS,
    GET_PAYMENTS_OF_INTERVENANT_FAILURE,
} from "../actions/entities/payments/paymentsActionType"
import {
    GET_TRANSFERS_OF_INTERVENANT_REQUEST,
    GET_TRANSFERS_OF_INTERVENANT_SUCCESS,
    GET_TRANSFERS_OF_INTERVENANT_FAILURE,
} from "../actions/entities/transfers/transfersActionType"
import {
    GET_TRANSFER_DETAILS_OF_INTERVENANT_REQUEST,
    GET_TRANSFER_DETAILS_OF_INTERVENANT_SUCCESS,
    GET_TRANSFER_DETAILS_OF_INTERVENANT_FAILURE,
} from "../actions/entities/transfers/transfersActionType"
import {
    GET_BENEFICIARY_TITLES_HISTORY_REQUEST,
    GET_BENEFICIARY_TITLES_HISTORY_SUCCESS,
    GET_BENEFICIARY_TITLES_HISTORY_FAILURE,
    GET_BENEFICIARY_ACCOUNTS_HISTORY_REQUEST,
    GET_BENEFICIARY_ACCOUNTS_HISTORY_SUCCESS,
    GET_BENEFICIARY_ACCOUNTS_HISTORY_FAILURE,
    GET_INTERVENANT_TITLES_HISTORY_REQUEST,
    GET_INTERVENANT_TITLES_HISTORY_SUCCESS,
    GET_INTERVENANT_TITLES_HISTORY_FAILURE,
    GET_INTERVENANT_ACCOUNTS_HISTORY_REQUEST,
    GET_INTERVENANT_ACCOUNTS_HISTORY_SUCCESS,
    GET_INTERVENANT_ACCOUNTS_HISTORY_FAILURE,
} from "../actions/temporaries/statisticsFinanceur/statisticsFinanceurActionTypes"
import {
    RETRIEVE_BENEFICIARY_DECLARATION_HISTORY_REQUEST,
    RETRIEVE_BENEFICIARY_DECLARATION_HISTORY_SUCCESS,
    RETRIEVE_BENEFICIARY_DECLARATION_HISTORY_FAILURE,
} from "../actions/temporaries/urssafCesu/urssafCesuActionTypes"
import {
    RETRIEVE_BENEFICIARY_URSSAF_PAJE_DECLARATION_HISTORY_REQUEST,
    RETRIEVE_BENEFICIARY_URSSAF_PAJE_DECLARATION_HISTORY_SUCCESS,
    RETRIEVE_BENEFICIARY_URSSAF_PAJE_DECLARATION_HISTORY_FAILURE,
} from "../actions/temporaries/urssafPaje/urssafPajeActionTypes"

/**
 * Object containing all entity keys
 */
export const Entity = {
    BANK: "BANK",
    BENEFICIARY: "BENEFICIARY",
    BENEFICIARY_ACCOUNT_STATEMENT: "BENEFICIARY_ACCOUNT_STATEMENT",
    BENEFICIARY_ACTIVE_CESU_ACCOUNTS: "BENEFICIARY_ACTIVE_CESU8_ACCOUNTS",
    BENEFICIARY_CHECKS_INTERVENANT_PAYMENT: "BENEFICIARY_CHECKS_INTERVENANT_PAYMENT",
    BENEFICIARY_CONVERTIBLE_CESU_CHECKS: "BENEFICIARY_CONVERTIBLE_CESU_CHECKS",
    BENEFICIARY_USABLE_CESU_CHECKS: "BENEFICIARY_USABLE_CESU_CHECKS",
    BENEFICIARY_ACCOUNTS_HISTORY: "BENEFICIARY_ACCOUNTS_HISTORY",
    BENEFICIARY_TITLES_HISTORY: "BENEFICIARY_TITLES_HISTORY",
    BENEFICIARY_ACCOUNT_PAYMENTS_HISTORY: "BENEFICIARY_ACCOUNT_PAYMENTS_HISTORY",
    BENEFICIARY_CHECK_PAYMENTS_HISTORY: "BENEFICIARY_CHECK_PAYMENTS_HISTORY",
    BENEFICIARY_SEARCHED_INTERVENANTS: "BENEFICIARY_SEARCHED_INTERVENANTS",
    BENEFICIARY_CHECKBOOK_STATEMENT: "BENEFICIARY_CHECKBOOK_STATEMENT",
    BENEFICIARY_TAX_DOCUMENT: "BENEFICIARY_TAX_DOCUMENT",
    BILL: "BILL",
    BILLING_STATUSES: "BILLING_STATUSES",
    CHECKBOOKS: "CHECKBOOKS",
    DECLARATION_HISTORY: "DECLARATION_HISTORY",
    FILE_REFUND_DEPOSITS: "FILE_REFUND_DEPOSITS",
    INTERLOCUTOR: "INTERLOCUTOR",
    INTERLOCUTOR_BENEFICIARY: "INTERLOCUTOR_BENEFICIARY",
    INTERLOCUTOR_BENEFICIARY_FOR_ACCESS: "INTERLOCUTOR_BENEFICIARY_FOR_ACCESS",
    INTERVENANT: "INTERVENANT",
    INTERVENANT_ACCOUNTS_HISTORY: "INTERVENANT_ACCOUNTS_HISTORY",
    INTERVENANT_TITLES_HISTORY: "INTERVENANT_TITLES_HISTORY",
    MANAGEMENT_REQUEST: "MANAGEMENT_REQUEST",
    MANAGEMENT_REQUEST_CREATORS_INTERLOCUTORS: "MANAGEMENT_REQUEST_CREATORS_INTERLOCUTORS",
    MANAGEMENT_REQUEST_IN_CHARGE_INTERLOCUTORS: "MANAGEMENT_REQUEST_IN_CHARGE_INTERLOCUTORS",
    MANAGEMENT_REQUEST_FILE: "MANAGEMENT_REQUEST_FILE",
    MANAGEMENT_REQUEST_FOLLOW_UP: "MANAGEMENT_REQUEST_FOLLOW_UP",
    ORDER: "ORDER",
    ORDER_DEPOSIT: "ORDER_DEPOSIT",
    ORDER_HISTORY: "ORDER_HISTORY",
    ORDER_LINE: "ORDER_LINE",
    ORDER_TYPE: "ORDER_TYPE",
    ORDER_SESSIONS: "ORDER_SESSIONS",
    PAYMENTS: "PAYMENTS",
    PREORDER_SESSION: "PREORDER_SESSION",
    PREORDER_SESSION_LINE: "PREORDER_SESSION_LINE",
    PRODUCT: "PRODUCT",
    REFUNDS: "REFUNDS",
    SERVICE_TYPE: "SERVICE_TYPE",
    SESSION_GROUP: "SESSION_GROUP",
    STAFF_CATEGORY: "STAFF_CATEGORY",
    STYLEGUIDE_DATA: "STYLEGUIDE_DATA",
    TRANSFERS: "TRANSFERS",
    TRANSFER_DETAILS: "TRANSFER_DETAILS",
    URSSAF_PAJE_DECLARATION_HISTORY: "URSSAF_PAJE_DECLARATION_HISTORY",
    WEB_ACCESS: "WEB_ACCESS",
}

/**
 * Object describing all entity information
 */
export const EntityInfo = {
    [Entity.BANK]: {
        name: "banks",
    },
    [Entity.BENEFICIARY]: {
        name: "beneficiaries",
        actionTypeRequest: RETRIEVE_BENEFICIARIES_REQUEST,
        actionTypeSuccess: RETRIEVE_BENEFICIARIES_SUCCESS,
        actionTypeFailure: RETRIEVE_BENEFICIARIES_FAILURE,
    },
    [Entity.BENEFICIARY_ACCOUNT_STATEMENT]: {
        name: "beneficiaryAccountStatement",
        actionTypeRequest: RETRIEVE_BENEFICIARY_ACCOUNT_STATEMENT_REQUEST,
        actionTypeSuccess: RETRIEVE_BENEFICIARY_ACCOUNT_STATEMENT_SUCCESS,
        actionTypeFailure: RETRIEVE_BENEFICIARY_ACCOUNT_STATEMENT_FAILURE,
    },
    [Entity.BENEFICIARY_CHECKBOOK_STATEMENT]: {
        name: "beneficiaryCheckbookStatement",
        actionTypeRequest: RETRIEVE_BENEFICIARY_CHECKBOOK_STATEMENT_REQUEST,
        actionTypeSuccess: RETRIEVE_BENEFICIARY_CHECKBOOK_STATEMENT_SUCCESS,
        actionTypeFailure: RETRIEVE_BENEFICIARY_CHECKBOOK_STATEMENT_FAILURE,
    },
    [Entity.BENEFICIARY_ACTIVE_CESU_ACCOUNTS]: {
        name: "beneficiaryActiveCesuAccounts",
        actionTypeRequest: RETRIEVE_BENEFICIARY_ACTIVE_CESU_ACCOUNTS_REQUEST,
        actionTypeSuccess: RETRIEVE_BENEFICIARY_ACTIVE_CESU_ACCOUNTS_SUCCESS,
        actionTypeFailure: RETRIEVE_BENEFICIARY_ACTIVE_CESU_ACCOUNTS_FAILURE,
    },
    [Entity.BENEFICIARY_USABLE_CESU_CHECKS]: {
        name: "beneficiaryUsableCesuChecks",
        actionTypeRequest: RETRIEVE_BENEFICIARY_USABLE_CESU_CHECKS_REQUEST,
        actionTypeSuccess: RETRIEVE_BENEFICIARY_USABLE_CESU_CHECKS_SUCCESS,
        actionTypeFailure: RETRIEVE_BENEFICIARY_USABLE_CESU_CHECKS_FAILURE,
    },
    [Entity.BENEFICIARY_CHECKS_INTERVENANT_PAYMENT]: {
        name: "beneficiaryCesuChecksIntervenantPayment",
        actionTypeRequest: RETRIEVE_BENEFICIARY_CHECKS_INTERVENANT_PAYMENT_REQUEST,
        actionTypeSuccess: RETRIEVE_BENEFICIARY_CHECKS_INTERVENANT_PAYMENT_SUCCESS,
        actionTypeFailure: RETRIEVE_BENEFICIARY_CHECKS_INTERVENANT_PAYMENT_FAILURE,
    },
    [Entity.BENEFICIARY_CONVERTIBLE_CESU_CHECKS]: {
        name: "beneficiaryConvertibleCesuChecks",
        actionTypeRequest: RETRIEVE_BENEFICIARY_CONVERTIBLE_CESU_CHECKS_REQUEST,
        actionTypeSuccess: RETRIEVE_BENEFICIARY_CONVERTIBLE_CESU_CHECKS_SUCCESS,
        actionTypeFailure: RETRIEVE_BENEFICIARY_CONVERTIBLE_CESU_CHECKS_FAILURE,
    },
    [Entity.BENEFICIARY_ACCOUNTS_HISTORY]: {
        name: "beneficiaryAccountsHistory",
        actionTypeRequest: GET_BENEFICIARY_ACCOUNTS_HISTORY_REQUEST,
        actionTypeSuccess: GET_BENEFICIARY_ACCOUNTS_HISTORY_SUCCESS,
        actionTypeFailure: GET_BENEFICIARY_ACCOUNTS_HISTORY_FAILURE,
    },
    [Entity.BENEFICIARY_TITLES_HISTORY]: {
        name: "beneficiaryTitlesHistory",
        actionTypeRequest: GET_BENEFICIARY_TITLES_HISTORY_REQUEST,
        actionTypeSuccess: GET_BENEFICIARY_TITLES_HISTORY_SUCCESS,
        actionTypeFailure: GET_BENEFICIARY_TITLES_HISTORY_FAILURE,
    },
    [Entity.BENEFICIARY_ACCOUNT_PAYMENTS_HISTORY]: {
        name: "beneficiaryAccountPaymentsHistory",
        actionTypeRequest: RETRIEVE_BENEFICIARY_ACCOUNT_PAYMENTS_HISTORY_REQUEST,
        actionTypeSuccess: RETRIEVE_BENEFICIARY_ACCOUNT_PAYMENTS_HISTORY_SUCCESS,
        actionTypeFailure: RETRIEVE_BENEFICIARY_ACCOUNT_PAYMENTS_HISTORY_FAILURE,
    },
    [Entity.BENEFICIARY_CHECK_PAYMENTS_HISTORY]: {
        name: "beneficiaryCheckPaymentsHistory",
        actionTypeRequest: RETRIEVE_BENEFICIARY_CHECK_PAYMENTS_HISTORY_REQUEST,
        actionTypeSuccess: RETRIEVE_BENEFICIARY_CHECK_PAYMENTS_HISTORY_SUCCESS,
        actionTypeFailure: RETRIEVE_BENEFICIARY_CHECK_PAYMENTS_HISTORY_FAILURE,
    },
    [Entity.BENEFICIARY_SEARCHED_INTERVENANTS]: {
        name: "beneficiarySearchedIntervenants",
        actionTypeRequest: RETRIEVE_BENEFICIARY_SEARCHED_INTERVENANTS_REQUEST,
        actionTypeSuccess: RETRIEVE_BENEFICIARY_SEARCHED_INTERVENANTS_SUCCESS,
        actionTypeFailure: RETRIEVE_BENEFICIARY_SEARCHED_INTERVENANTS_FAILURE,
    },
    [Entity.BENEFICIARY_TAX_DOCUMENT]: {
        name: "beneficiaryTaxDocument",
        actionTypeRequest: RETRIEVE_BENEFICIARY_TAX_DOCUMENT_REQUEST,
        actionTypeSuccess: RETRIEVE_BENEFICIARY_TAX_DOCUMENT_SUCCESS,
        actionTypeFailure: RETRIEVE_BENEFICIARY_TAX_DOCUMENT_FAILURE,
    },
    [Entity.BILL]: {
        name: "bills",
        actionTypeRequest: RETRIEVE_BILLS_REQUEST,
        actionTypeSuccess: RETRIEVE_BILLS_SUCCESS,
        actionTypeFailure: RETRIEVE_BILLS_FAILURE,
    },
    [Entity.BILLING_STATUSES]: {
        name: "billingStatuses",
        actionTypeRequest: RETRIEVE_BILLING_STATUSES_REQUEST,
        actionTypeSuccess: RETRIEVE_BILLING_STATUSES_SUCCESS,
        actionTypeFailure: RETRIEVE_BILLING_STATUSES_FAILURE,
    },
    [Entity.CHECKBOOKS]: {
        name: "checkbooks",
        actionTypeRequest: GET_CHECKBOOKS_OF_REFUND_REQUEST,
        actionTypeSuccess: GET_CHECKBOOKS_OF_REFUND_SUCCESS,
        actionTypeFailure: GET_CHECKBOOKS_OF_REFUND_FAILURE,
    },
    [Entity.DECLARATION_HISTORY]: {
        name: "beneficiaryDeclarationHistory",
        actionTypeRequest: RETRIEVE_BENEFICIARY_DECLARATION_HISTORY_REQUEST,
        actionTypeSuccess: RETRIEVE_BENEFICIARY_DECLARATION_HISTORY_SUCCESS,
        actionTypeFailure: RETRIEVE_BENEFICIARY_DECLARATION_HISTORY_FAILURE,
    },
    [Entity.URSSAF_PAJE_DECLARATION_HISTORY]: {
        name: "beneficiaryUrssafPajeDeclarationHistory",
        actionTypeRequest: RETRIEVE_BENEFICIARY_URSSAF_PAJE_DECLARATION_HISTORY_REQUEST,
        actionTypeSuccess: RETRIEVE_BENEFICIARY_URSSAF_PAJE_DECLARATION_HISTORY_SUCCESS,
        actionTypeFailure: RETRIEVE_BENEFICIARY_URSSAF_PAJE_DECLARATION_HISTORY_FAILURE,
    },
    [Entity.FILE_REFUND_DEPOSITS]: {
        name: "fileRefundDeposits",
        actionTypeRequest: RETRIEVE_FILE_REFUND_DEPOSITS_REQUEST,
        actionTypeSuccess: RETRIEVE_FILE_REFUND_DEPOSITS_SUCCESS,
        actionTypeFailure: RETRIEVE_FILE_REFUND_DEPOSITS_FAILURE,
    },
    [Entity.INTERLOCUTOR]: {
        name: "interlocutors",
        actionTypeRequest: RETRIEVE_INTERLOCUTORS_REQUEST,
        actionTypeSuccess: RETRIEVE_INTERLOCUTORS_SUCCESS,
        actionTypeFailure: RETRIEVE_INTERLOCUTORS_FAILURE,
    },
    [Entity.INTERLOCUTOR_BENEFICIARY]: {
        name: "interlocutorBeneficiaries",
        actionTypeRequest: RETRIEVE_INTERLOCUTOR_BENEFICIARIES_REQUEST,
        actionTypeSuccess: RETRIEVE_INTERLOCUTOR_BENEFICIARIES_SUCCESS,
        actionTypeFailure: RETRIEVE_INTERLOCUTOR_BENEFICIARIES_FAILURE,
    },
    [Entity.INTERLOCUTOR_BENEFICIARY_FOR_ACCESS]: {
        name: "interlocutorBeneficiariesForAccess",
        actionTypeRequest: RETRIEVE_BENEFICIARIES_FOR_ACCESS_REQUEST,
        actionTypeSuccess: RETRIEVE_BENEFICIARIES_FOR_ACCESS_SUCCESS,
        actionTypeFailure: RETRIEVE_BENEFICIARIES_FOR_ACCESS_FAILURE,
    },
    [Entity.INTERVENANT]: {
        name: "intervenants",
        actionTypeRequest: RETRIEVE_INTERVENANTS_REQUEST,
        actionTypeSuccess: RETRIEVE_INTERVENANTS_SUCCESS,
        actionTypeFailure: RETRIEVE_INTERVENANTS_FAILURE,
    },
    [Entity.INTERVENANT_ACCOUNTS_HISTORY]: {
        name: "intervenantAccountsHistory",
        actionTypeRequest: GET_INTERVENANT_ACCOUNTS_HISTORY_REQUEST,
        actionTypeSuccess: GET_INTERVENANT_ACCOUNTS_HISTORY_SUCCESS,
        actionTypeFailure: GET_INTERVENANT_ACCOUNTS_HISTORY_FAILURE,
    },
    [Entity.INTERVENANT_TITLES_HISTORY]: {
        name: "intervenantTitlesHistory",
        actionTypeRequest: GET_INTERVENANT_TITLES_HISTORY_REQUEST,
        actionTypeSuccess: GET_INTERVENANT_TITLES_HISTORY_SUCCESS,
        actionTypeFailure: GET_INTERVENANT_TITLES_HISTORY_FAILURE,
    },
    [Entity.MANAGEMENT_REQUEST]: {
        name: "managementRequests",
        actionTypeRequest: RETRIEVE_MANAGEMENT_REQUESTS_REQUEST,
        actionTypeSuccess: RETRIEVE_MANAGEMENT_REQUESTS_SUCCESS,
        actionTypeFailure: RETRIEVE_MANAGEMENT_REQUESTS_FAILURE,
    },
    [Entity.MANAGEMENT_REQUEST_CREATORS_INTERLOCUTORS]: {
        name: "managementRequestCreatorsInterlocutors",
        actionTypeRequest: RETRIEVE_MANAGEMENT_REQUEST_CREATORS_INTERLOCUTORS_REQUEST,
        actionTypeSuccess: RETRIEVE_MANAGEMENT_REQUEST_CREATORS_INTERLOCUTORS_SUCCESS,
        actionTypeFailure: RETRIEVE_MANAGEMENT_REQUEST_CREATORS_INTERLOCUTORS_FAILURE,
    },
    [Entity.MANAGEMENT_REQUEST_IN_CHARGE_INTERLOCUTORS]: {
        name: "managementRequestInChargeInterlocutors",
        actionTypeRequest: RETRIEVE_MANAGEMENT_REQUEST_IN_CHARGE_INTERLOCUTORS_REQUEST,
        actionTypeSuccess: RETRIEVE_MANAGEMENT_REQUEST_IN_CHARGE_INTERLOCUTORS_SUCCESS,
        actionTypeFailure: RETRIEVE_MANAGEMENT_REQUEST_IN_CHARGE_INTERLOCUTORS_FAILURE,
    },
    [Entity.MANAGEMENT_REQUEST_FILE]: {
        name: "managementRequestFiles",
        actionTypeRequest: RETRIEVE_MANAGEMENT_REQUEST_FILES_REQUEST,
        actionTypeSuccess: RETRIEVE_MANAGEMENT_REQUEST_FILES_SUCCESS,
        actionTypeFailure: RETRIEVE_MANAGEMENT_REQUEST_FILES_FAILURE,
    },
    [Entity.MANAGEMENT_REQUEST_FOLLOW_UP]: {
        name: "managementRequestFollowUps",
        actionTypeRequest: RETRIEVE_MANAGEMENT_REQUEST_FOLLOW_UPS_REQUEST,
        actionTypeSuccess: RETRIEVE_MANAGEMENT_REQUEST_FOLLOW_UPS_SUCCESS,
        actionTypeFailure: RETRIEVE_MANAGEMENT_REQUEST_FOLLOW_UPS_FAILURE,
    },
    [Entity.ORDER]: {
        name: "orders",
        actionTypeRequest: RETRIEVE_ORDERS_REQUEST,
        actionTypeSuccess: RETRIEVE_ORDERS_SUCCESS,
        actionTypeFailure: RETRIEVE_ORDERS_FAILURE,
    },
    [Entity.ORDER_HISTORY]: {
        name: "beneficiaryOrderHistory",
        actionTypeRequest: RETRIEVE_BENEFICIARY_ORDER_HISTORY_REQUEST,
        actionTypeSuccess: RETRIEVE_BENEFICIARY_ORDER_HISTORY_SUCCESS,
        actionTypeFailure: RETRIEVE_BENEFICIARY_ORDER_HISTORY_FAILURE,
    },
    [Entity.ORDER_REASON]: {
        name: "orderReasons",
    },
    [Entity.ORDER_DEPOSIT]: {
        name: "orderDeposits",
        actionTypeRequest: RETRIEVE_ORDER_DEPOSITS_REQUEST,
        actionTypeSuccess: RETRIEVE_ORDER_DEPOSITS_SUCCESS,
        actionTypeFailure: RETRIEVE_ORDER_DEPOSITS_FAILURE,
    },
    [Entity.ORDER_LINE]: {
        name: "orderLines",
        actionTypeRequest: RETRIEVE_ORDER_LINES_REQUEST,
        actionTypeSuccess: RETRIEVE_ORDER_LINES_SUCCESS,
        actionTypeFailure: RETRIEVE_ORDER_LINES_FAILURE,
    },
    [Entity.ORDER_SESSIONS]: {
        name: "beneficiaryOpenedSessions",
        actionTypeRequest: RETRIEVE_BENEFICIARY_OPENED_SESSIONS_REQUEST,
        actionTypeSuccess: RETRIEVE_BENEFICIARY_OPENED_SESSIONS_SUCCESS,
        actionTypeFailure: RETRIEVE_BENEFICIARY_OPENED_SESSIONS_FAILURE,
    },
    [Entity.ORDER_TYPE]: {
        name: "orderTypes",
    },
    [Entity.TRANSFERS]: {
        name: "transfers",
        actionTypeRequest: GET_TRANSFERS_OF_INTERVENANT_REQUEST,
        actionTypeSuccess: GET_TRANSFERS_OF_INTERVENANT_SUCCESS,
        actionTypeFailure: GET_TRANSFERS_OF_INTERVENANT_FAILURE,
    },
    [Entity.TRANSFER_DETAILS]: {
        name: "transferDetails",
        actionTypeRequest: GET_TRANSFER_DETAILS_OF_INTERVENANT_REQUEST,
        actionTypeSuccess: GET_TRANSFER_DETAILS_OF_INTERVENANT_SUCCESS,
        actionTypeFailure: GET_TRANSFER_DETAILS_OF_INTERVENANT_FAILURE,
    },
    [Entity.PAYMENTS]: {
        name: "payments",
        actionTypeRequest: GET_PAYMENTS_OF_INTERVENANT_REQUEST,
        actionTypeSuccess: GET_PAYMENTS_OF_INTERVENANT_SUCCESS,
        actionTypeFailure: GET_PAYMENTS_OF_INTERVENANT_FAILURE,
    },
    [Entity.PREORDER_SESSION]: {
        name: "preOrderSessions",
        actionTypeRequest: GET_PREORDER_SESSIONS_REQUEST,
        actionTypeSuccess: GET_PREORDER_SESSIONS_SUCCESS,
        actionTypeFailure: GET_PREORDER_SESSIONS_FAILURE,
    },
    [Entity.PREORDER_SESSION_LINE]: {
        name: "preOrderSessionLines",
        actionTypeRequest: GET_PREORDER_SESSION_LINES_REQUEST,
        actionTypeSuccess: GET_PREORDER_SESSION_LINES_SUCCESS,
        actionTypeFailure: GET_PREORDER_SESSION_LINES_FAILURE,
    },
    [Entity.PRODUCT]: {
        name: "products",
        actionTypeRequest: RETRIEVE_PRODUCTS_REQUEST,
        actionTypeSuccess: RETRIEVE_PRODUCTS_SUCCESS,
        actionTypeFailure: RETRIEVE_PRODUCTS_FAILURE,
        idsReducers: [
            {
                reducer: accountProductIds,
                name: "accountProductIds",
            },
            {
                reducer: checkbookProductIds,
                name: "checkbookProductIds",
            },
        ],
    },
    [Entity.REFUNDS]: {
        name: "refunds",
        actionTypeRequest: GET_REFUNDS_OF_INTERVENANT_REQUEST,
        actionTypeSuccess: GET_REFUNDS_OF_INTERVENANT_SUCCESS,
        actionTypeFailure: GET_REFUNDS_OF_INTERVENANT_FAILURE,
    },
    [Entity.SERVICE_TYPE]: {
        name: "serviceTypes",
        actionTypeRequest: RETRIEVE_ALL_SERVICE_TYPES_REQUEST,
        actionTypeSuccess: RETRIEVE_ALL_SERVICE_TYPES_SUCCESS,
        actionTypeFailure: RETRIEVE_ALL_SERVICE_TYPES_FAILURE,
    },
    [Entity.SESSION_GROUP]: {
        name: "sessionGroups",
        actionTypeRequest: RETRIEVE_SESSION_GROUPS_REQUEST,
        actionTypeSuccess: RETRIEVE_SESSION_GROUPS_SUCCESS,
        actionTypeFailure: RETRIEVE_SESSION_GROUPS_FAILURE,
    },
    [Entity.STAFF_CATEGORY]: {
        name: "staffCategories",
        actionTypeRequest: RETRIEVE_STAFF_CATEGORIES_REQUEST,
        actionTypeSuccess: RETRIEVE_STAFF_CATEGORIES_SUCCESS,
        actionTypeFailure: RETRIEVE_STAFF_CATEGORIES_FAILURE,
    },
    [Entity.STYLEGUIDE_DATA]: {
        name: "styleguideData",
        actionTypeSuccess: INSERT_STYLEGUIDE_DATA,
    },
    [Entity.WEB_ACCESS]: {
        name: "webAccesses",
        actionTypeRequest: RETRIEVE_WEB_ACCESS_REQUEST,
        actionTypeSuccess: RETRIEVE_WEB_ACCESS_SUCCESS,
        actionTypeFailure: RETRIEVE_WEB_ACCESS_FAILURE,
    },
}
