import {CHANGE_ACTUAL_STEP} from "../../../actions/temporaries/stepBar/stepBarActionTypes"

/**
 * Step bar reducer.
 * Stock in store the selected step.
 */
const stepBar = (state = {actualStep: 0, clickable: false}, action = {}) => {
    switch (action.type) {
        case CHANGE_ACTUAL_STEP:
            return {
                actualStep: action.actualStep,
                clickable: action.clickable === undefined ? true : action.clickable,
            }

        default:
            return state
    }
}

export default stepBar

/**
 * Return the actual step selected in the step bar.
 */
export const getActualStep = state => state.actualStep
/**
 * Return if in actual step the user can click to be redirect
 */
export const isStepsClickable = state => state.clickable
