/**
 * Indicate that we start a retrieve order request
 */
export const RETRIEVE_ORDER_REQUEST = "RETRIEVE_ORDER_REQUEST"
/**
 * Indicate that the retrieve order request was successful
 */
export const RETRIEVE_ORDER_SUCCESS = "RETRIEVE_ORDER_SUCCESS"
/**
 * Indicate that the retrieve order has failed
 */
export const RETRIEVE_ORDER_FAILURE = "RETRIEVE_ORDER_FAILURE"
/**
 * Indicate that we start a retrieve orders request
 */
export const RETRIEVE_ORDERS_REQUEST = "RETRIEVE_ORDERS_REQUEST"
/**
 * Indicate that the retrieve orders request was successful
 */
export const RETRIEVE_ORDERS_SUCCESS = "RETRIEVE_ORDERS_SUCCESS"
/**
 * Indicate that the retrieve orders has failed
 */
export const RETRIEVE_ORDERS_FAILURE = "RETRIEVE_ORDERS_FAILURE"

export const RETRIEVE_PURCHASE_ORDER = "RETRIEVE_PURCHASE_ORDER"
