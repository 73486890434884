import {GET_CUSTOMIZATION_FILE_ENDPOINT} from "../constants/endpoints"
import {defaults} from "axios"
import {isEmpty} from "lodash"

/**
 * Construct an URL to get an image for the Filer.
 * If there is no remote path, construct the default URL.
 */
export const getImageFromRemotePath = (remotePath, areaType = "", imageDefault = "", staticLogo) => {
    if (staticLogo) {
        return staticLogo
    }

    const prefix = defaults.baseURL + GET_CUSTOMIZATION_FILE_ENDPOINT + "?path="

    if (isEmpty(remotePath)) {
        // No image, ask the one by default.
        return prefix + areaType + "/PERSONNALISATION/DEFAUT/" + imageDefault
    } else {
        return prefix + remotePath
    }
}
