import PropTypes from "prop-types"
import React, {useMemo} from "react"
import {Button as SemanticButton, Icon as SemanticIcon} from "semantic-ui-react"
import "./buttonPrimary.scss"

const ButtonPrimary = ({
    children,
    actionType,
    className,
    dataTestId,
    height,
    icon,
    iconPosition,
    width,
    disabled,
    loading,
    ...props
}) => {
    const style = useMemo(() => {
        let style = {}
        if (width) {
            style.width = width + "rem"
        }

        if (height) {
            style.height = height + "rem"
        }

        return style
    }, [height, width])

    return (
        <SemanticButton
            className={className}
            disabled={disabled || loading}
            loading={loading}
            primary
            style={style}
            type={actionType}
            {...props}
        >
            {icon && iconPosition === "left" && <SemanticIcon className="left" name={icon} />}
            <div className="button-primary-children">{children}</div>
            {icon && iconPosition === "right" && <SemanticIcon className="right" name={icon} />}
        </SemanticButton>
    )
}

ButtonPrimary.defaultProps = {
    actionType: undefined,
    className: null,
    dataTestId: undefined,
    disabled: false,
    height: undefined,
    icon: undefined,
    iconPosition: "left",
    loading: false,
    onClick: null,
    width: undefined,
}

ButtonPrimary.propTypes = {
    actionType: PropTypes.oneOf(["button", "reset", "submit"]),
    children: PropTypes.string.isRequired,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
    disabled: PropTypes.bool,
    height: PropTypes.string,
    icon: PropTypes.string,
    iconPosition: PropTypes.oneOf(["left", "right"]),
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    width: PropTypes.string,
}

ButtonPrimary.defaultProps = {
    disabled: false,
    loading: false,
}

export default ButtonPrimary
