import {SAVE_CONFIRMATION, RESET_CONFIRMATION} from "../../../actions/localStorage/confirmation/confirmationActionTypes"

/**
 * Reducer to save confirmation data in the localstorage
 * to retrieve them after the payment
 */
const confirmation = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_CONFIRMATION:
            return {
                contractId: action.contractId,
                email: action.email,
                millesime: action.millesime,
                oldMillesime: action.oldMillesime,
                supportType: action.supportType,
                tradeType: action.tradeType,
                totalAmount: action.totalAmount,
                totalFees: action.totalFees,
            }
        case RESET_CONFIRMATION:
            return {}
        default:
            return state
    }
}

export default confirmation

/**
 * Get contract id
 */
export const getContractId = state => state.contractId
/**
 * Get user email
 */
export const getEmail = state => state.email
/**
 * Get millesime
 */
export const getMillesime = state => state.millesime
/**
 * Get old millesime
 */
export const getOldMillesime = state => state.oldMillesime
/**
 * Get support type
 */
export const getSupportType = state => state.supportType
/**
 * Get trade type
 */
export const getTradeType = state => state.tradeType
/**
 * Get total amount
 */
export const getTotalAmount = state => state.totalAmount
/**
 * Get total fees
 */
export const getTotalFees = state => state.totalFees
