import {
    RECOVER_LOGIN_PASSWORD_REQUEST,
    RECOVER_LOGIN_PASSWORD_SUCCESS,
    RECOVER_LOGIN_PASSWORD_FAILURE,
} from "../actions/sessionStorage/user/userActionTypes"
import {
    RETRIEVE_PROFILE_INFORMATION_VALUES_FAILURE,
    RETRIEVE_PROFILE_INFORMATION_VALUES_REQUEST,
    RETRIEVE_PROFILE_INFORMATION_VALUES_SUCCESS,
    UPDATE_PROFILE_INFORMATION_VALUES_FAILURE,
    UPDATE_PROFILE_INFORMATION_VALUES_REQUEST,
    UPDATE_PROFILE_INFORMATION_VALUES_SUCCESS,
} from "../actions/temporaries/profile/profileActionTypes"
import {
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
} from "../actions/sessionStorage/user/userActionTypes"
import {
    VERIFY_MARKETING_OPERATION_FAILURE,
    VERIFY_MARKETING_OPERATION_REQUEST,
    VERIFY_MARKETING_OPERATION_SUCCESS,
} from "../actions/entities/marketingOperations/marketingOperationsActionTypes"
import {
    SEARCH_TOWN_FAILURE,
    SEARCH_TOWN_REQUEST,
    SEARCH_TOWN_SUCCESS,
} from "../actions/temporaries/search/searchActionTypes"
import {
    EMPTY_SIRET_VALIDATION,
    SIRET_VALIDATION_FAILURE,
    SIRET_VALIDATION_REQUEST,
    SIRET_VALIDATION_SUCCESS,
} from "../actions/temporaries/siretValidation/siretValidationActionTypes"
import {
    RETRIEVE_STYLE_FAILURE,
    RETRIEVE_STYLE_REQUEST,
    RETRIEVE_STYLE_SUCCESS,
} from "../actions/localStorage/style/styleActionTypes"
import {
    RETRIEVE_TEXTS_FAILURE,
    RETRIEVE_TEXTS_REQUEST,
    RETRIEVE_TEXTS_SUCCESS,
} from "../actions/localStorage/texts/textsActionTypes"
import {
    GET_RULES_INVOICE_CALCULATIONS_FAILURE,
    GET_RULES_INVOICE_CALCULATIONS_REQUEST,
    GET_RULES_INVOICE_CALCULATIONS_SUCCESS,
    GET_TVA_INVOICE_CALCULATIONS_FAILURE,
    GET_TVA_INVOICE_CALCULATIONS_REQUEST,
    GET_TVA_INVOICE_CALCULATIONS_SUCCESS,
} from "../actions/temporaries/invoiceCalculations/invoiceCalculationsActionTypes"
import {
    CALCULATE_ONLINE_ORDER_COSTS_FAILURE,
    CALCULATE_ONLINE_ORDER_COSTS_REQUEST,
    CALCULATE_ONLINE_ORDER_COSTS_SUCCESS,
    GET_ONLINE_ORDER_RESULTS_FAILURE,
    GET_ONLINE_ORDER_RESULTS_REQUEST,
    GET_ONLINE_ORDER_RESULTS_SUCCESS,
    SAVE_ONLINE_ORDER_FAILURE,
    SAVE_ONLINE_ORDER_REQUEST,
    SAVE_ONLINE_ORDER_SUCCESS,
} from "../actions/temporaries/onlineOrder/onlineOrderActionTypes"
import {
    CONNECT_USER_FAILURE,
    CONNECT_USER_REQUEST,
    CONNECT_USER_SUCCESS,
    DISCONNECT_USER,
    GET_USER_INFO_REQUEST,
    GET_USER_INFO_SUCCESS,
    GET_USER_INFO_FAILURE,
} from "../actions/sessionStorage/user/userActionTypes"
import {
    CREATE_ORDER_DEPOSIT_REQUEST,
    CREATE_ORDER_DEPOSIT_SUCCESS,
    CREATE_ORDER_DEPOSIT_FAILURE,
} from "../actions/entities/orderDeposits/orderDepositActionTypes"
import {
    CREATE_SESSION_GROUP_REQUEST,
    CREATE_SESSION_GROUP_FAILURE,
    CREATE_SESSION_GROUP_SUCCESS,
} from "../actions/entities/sessionGroups/sessionGroupsActionTypes"
import {
    CHANGE_BENEFICIARIES_ACTIVATIONS_FAILURE,
    CHANGE_BENEFICIARIES_ACTIVATIONS_REQUEST,
    CHANGE_BENEFICIARIES_ACTIVATIONS_SUCCESS,
    RETRIEVE_BENEFICIARIES_FAILURE,
    RETRIEVE_BENEFICIARIES_REQUEST,
    RETRIEVE_BENEFICIARIES_SUCCESS,
} from "../actions/entities/beneficiaries/beneficiariesActionTypes"
import {
    GET_SCELLIUS_DATA_FAILURE,
    GET_SCELLIUS_DATA_REQUEST,
    GET_SCELLIUS_DATA_SUCCESS,
} from "../actions/temporaries/scellius/scelliusActionTypes"
import {
    RETRIEVE_ORDER_REQUEST,
    RETRIEVE_ORDER_SUCCESS,
    RETRIEVE_ORDER_FAILURE,
} from "../actions/entities/orders/orderActionTypes"
import {
    GET_DUPLICATION_INFORMATION_REQUEST,
    GET_DUPLICATION_INFORMATION_SUCCESS,
    GET_DUPLICATION_INFORMATION_FAILURE,
} from "../actions/temporaries/onlineOrder/onlineOrderActionTypes"
import {
    UPDATE_MANAGEMENT_REQUEST_FAILURE,
    UPDATE_MANAGEMENT_REQUEST_REQUEST,
    UPDATE_MANAGEMENT_REQUEST_SUCCESS,
    UPLOAD_MANAGEMENT_REQUEST_FILE_REQUEST,
    UPLOAD_MANAGEMENT_REQUEST_FILE_SUCCESS,
    UPLOAD_MANAGEMENT_REQUEST_FILE_FAILURE,
} from "../actions/entities/managementRequests/managementRequestsActionTypes"
import { EXCEL_DOWNLOAD_REQUEST, EXCEL_DOWNLOAD_SUCCESS, EXCEL_DOWNLOAD_FAILURE } from "../actions/excel/excelActionTypes"
import {
    GET_REPORTS_TREE_FAILURE,
    GET_REPORTS_TREE_REQUEST,
    GET_REPORTS_TREE_SUCCESS,
    SAVE_NEW_REPORTS_FILE_FAILURE,
    SAVE_NEW_REPORTS_FILE_REQUEST,
    SAVE_NEW_REPORTS_FILE_SUCCESS,
    SAVE_NEW_REPORTS_FOLDER_FAILURE,
    SAVE_NEW_REPORTS_FOLDER_REQUEST,
    SAVE_NEW_REPORTS_FOLDER_SUCCESS,
} from "../actions/temporaries/reports/reportsActionTypes"
import {
    GET_PREORDER_SESSION_ORDERS_FAILURE,
    GET_PREORDER_SESSION_ORDERS_REQUEST,
    GET_PREORDER_SESSION_ORDERS_SUCCESS,
    GET_PREORDER_SESSION_STATISTICS_FAILURE,
    GET_PREORDER_SESSION_STATISTICS_REQUEST,
    GET_PREORDER_SESSION_STATISTICS_SUCCESS,
    GET_PREORDER_SESSIONS_MATRICULE_FAILURE,
    GET_PREORDER_SESSIONS_MATRICULE_REQUEST,
    GET_PREORDER_SESSIONS_MATRICULE_SUCCESS,
    VALIDATE_CONTROL_FILE_REQUEST,
    VALIDATE_CONTROL_FILE_SUCCESS,
    VALIDATE_CONTROL_FILE_FAILURE,
    PREORDER_SESSION_CLOSE_REQUEST,
    PREORDER_SESSION_CLOSE_SUCCESS,
    PREORDER_SESSION_CLOSE_FAILURE,
    PREORDER_SESSION_CREATE_ORDER_REQUEST,
    PREORDER_SESSION_CREATE_ORDER_SUCCESS,
    PREORDER_SESSION_CREATE_ORDER_FAILURE,
    PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_REQUEST,
    PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_SUCCESS,
    PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_FAILURE,
    PREORDER_SESSION_UPDATE_LINES_REQUEST,
    PREORDER_SESSION_UPDATE_LINES_SUCCESS,
    PREORDER_SESSION_UPDATE_LINES_FAILURE,
    PREORDER_SESSION_CREATE_UPDATE_REQUEST,
    PREORDER_SESSION_CREATE_UPDATE_SUCCESS,
    PREORDER_SESSION_CREATE_UPDATE_FAILURE,
    PREORDER_SESSION_INFORMATION_FAILURE,
    PREORDER_SESSION_INFORMATION_REQUEST,
    PREORDER_SESSION_INFORMATION_SUCCESS,
} from "../actions/entities/preOrderSessions/preOrderSessionsActionTypes"
import {
    CLEAR_FILER_DOWNLOAD_REQUEST,
    FILER_DOWNLOAD_FAILURE,
    FILER_DOWNLOAD_REQUEST,
    FILER_DOWNLOAD_SUCCESS,
} from "../actions/filer/filerActionTypes"
import {
    HANDOVER_CHECKBOOK_FAILURE,
    HANDOVER_CHECKBOOK_REQUEST,
    HANDOVER_CHECKBOOK_SUCCESS,
} from "../actions/temporaries/handOverCheckbooks/handOverCheckbooksActionTypes"
import {
    GET_INTERVENANT_VALIDATION_PRE_REGISTRATION_FAILURE,
    GET_INTERVENANT_VALIDATION_PRE_REGISTRATION_REQUEST,
    GET_INTERVENANT_VALIDATION_PRE_REGISTRATION_SUCCESS,
} from "../actions/localStorage/intervenantRegistration/intervenantRegistrationActionTypes"
import {
    GET_LEGAL_FORMS_FAILURE,
    GET_LEGAL_FORMS_REQUEST,
    GET_LEGAL_FORMS_SUCCESS,
} from "../actions/temporaries/legalForms/legalFormsActionsTypes"
import {
    GET_MILLESIME_IN_TRADE_REQUEST,
    GET_MILLESIME_IN_TRADE_SUCCESS,
    GET_MILLESIME_IN_TRADE_FAILURE,
} from "../actions/temporaries/millesime/millesimeActionsTypes"

/**
 * Object containing all request keys.
 */
export const RequestKeys = {
    ADD_MANAGEMENT_REQUEST_FOLLOW_UP: "ADD_MANAGEMENT_REQUEST_FOLLOW_UP",
    BENEFICIARIES_SEARCH: "BENEFICIARIES_SEARCH",
    BENEFICIARY_FIND_BY_ID: "BENEFICIARY_FIND_BY_ID",
    CANCEL_BENEFICIARY_ORDER: "CANCEL_BENEFICIARY_ORDER",
    CALCULATE_ONLINE_ORDER_COSTS: "CALCULATE_ONLINE_ORDER_COSTS",
    CALCULATE_FACIAL_VALUES: "CALCULATE_FACIAL_VALUES",
    CALCULATE_ACCOUNT_TRADING_FEES: "CALCULATE_ACCOUNT_TRADING_FEES",
    CALCULATE_TRADING_FEES: "CALCULATE_TRADING_FEES",
    CALCULATE_BENEFICIARY_PREORDER_FEES: "CALCULATE_BENEFICIARY_PREORDER_FEES",
    CALCULATE_RELOADING_FEES: "CALCULATE_RELOADING_FEES",
    CALCUL_PAYMENT_COMPLEMENT_FEES: "CALCUL_PAYMENT_COMPLEMENT_FEES",
    CONVERT_ACCOUNT_TO_CHECKBOOK: "CONVERT_ACCOUNT_TO_CHECKBOOK",
    CONVERT_CHECKBOOK_TO_ACCOUNT: "CONVERT_CHECKBOOK_TO_ACCOUNT",
    CHANGE_BENEFICIARIES_ACTIVATIONS: "CHANGE_BENEFICIARIES_ACTIVATIONS",
    CHANGE_ACTUAL_PASSWORD: "CHANGE_ACTUAL_PASSWORD",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    CHECK_CONTROL_INFORMATIONS_FOR_BENEFICIARY_REGISTRATION: "CHECK_CONTROL_INFORMATIONS_FOR_BENEFICIARY_REGISTRATION",
    CHECK_IF_ALREADY_REGISTERED: "CHECK_IF_ALREADY_REGISTERED",
    CHECK_SESSION_CONTROL_INFORMATIONS_FOR_BENEFICIARY: "CHECK_SESSION_CONTROL_INFORMATIONS_FOR_BENEFICIARY",
    CHECK_CRCESU_WEB_SERVICE_AVAILABILITY: "CHECK_CRCESU_WEB_SERVICE_AVAILABILITY",
    CHECK_SECRET_CODE_TITLE: "CHECK_SECRET_CODE_TITLE",
    CHECK_VALID_TITLES: "CHECK_VALID_TITLES",
    CREATE_SIMPLE_HANDOVER: "CREATE_SIMPLE_HANDOVER",
    CREATE_BARCODE_HANDOVER: "CREATE_BARCODE_HANDOVER",
    CREATE_FILE_HANDOVER: "CREATE_FILE_HANDOVER",
    CREATE_MANAGEMENT_REQUEST: "CREATE_MANAGEMENT_REQUEST",
    CREATE_ORDER_DEPOSIT: "CREATE_ORDER_DEPOSIT",
    CREATE_SESSION_GROUP: "CREATE_SESSION_GROUP",
    CREATE_PREORDER: "CREATE_PREORDER",
    CREATE_INTERLOCUTOR: "CREATE_INTERLOCUTOR",
    DELETE_INTERLOCUTOR: "DELETE_INTERLOCUTOR",
    CREATE_INTERLOCUTOR_BENEFICIARY: "CREATE_INTERLOCUTOR_BENEFICIARY",
    UPDATE_INTERLOCUTOR_BENEFICIARY: "UPDATE_INTERLOCUTOR_BENEFICIARY",
    DELETE_INTERLOCUTOR_BENEFICIARY: "DELETE_INTERLOCUTOR_BENEFICIARY",
    EXCEL_DOWNLOAD: "EXCEL_DOWNLOAD",
    FILER_DOWNLOAD: "FILER_DOWNLOAD",
    FINANCEUR_GENERAL_DATA: "FINANCEUR_GENERAL_DATA",
    GENERATE_ACCOUNT_STATEMENT_PDF: "GENERATE_ACCOUNT_STATEMENT_PDF",
    GENERATE_BILL_TRANSFER: "GENERATE_BILL_TRANSFER",
    GENERATE_HANDOVER_PDF: "GENERATE_HANDOVER_PDF",
    GENERATE_BENEFICIARY_BYPASS_LOGIN_TOKEN: "GENERATE_BENEFICIARY_BYPASS_LOGIN_TOKEN",
    GET_ACCOUNT_TRADE_ORDER_SCELLIUS_DATA: "GET_ACCOUNT_TRADE_ORDER_SCELLIUS_DATA",
    GET_ALL_KINDS_WAY_ADDRESS: "GET_ALL_KINDS_WAY_ADDRESS",
    GET_ALL_TYPES_WAY_ADDRESS: "GET_TYPES_KINDS_WAY_ADDRESS",
    GET_BANKING_ESTABLISHMENT_WITH_IBAN: "GET_BANKING_ESTABLISHMENT_WITH_IBAN",
    GET_BENEFICIARY_STATS: "GET_BENEFICIARY_STATS",
    GET_BENEFICIARY_PRE_ORDER_SCELLIUS_DATA: "GET_BENEFICIARY_PRE_ORDER_SCELLIUS_DATA",
    GET_BENEFICIARY_INTERVENANT_ACCOUNT_PAYMENT_SCELLIUS_DATA:
        "GET_BENEFICIARY_INTERVENANT_ACCOUNT_PAYMENT_SCELLIUS_DATA",
    GET_CLIENT_INFORMATIONS: "GET_CLIENT_INFORMATIONS",
    GET_DOMISERVE_RIB: "GET_DOMISERVE_RIB",
    GET_ENABLED_FEATURES: "GET_ENABLED_FEATURES",
    GET_BENEFICIARY_CONTRACTS: "GET_BENEFICIARY_CONTRACTS",
    GET_BENEFICIARY_CONVERTIBLE_ACCOUNTS: "GET_BENEFICIARY_CONVERTIBLE_ACCOUNTS",
    GET_BENEFICIARY_CONVERTIBLE_CHECKBOOKS: "GET_BENEFICIARY_CONVERTIBLE_CHECKBOOKS",
    GET_BENEFICIARY_CONVERTIBLE_CHECKS: "GET_BENEFICIARY_CONVERTIBLE_CHECKS",
    GET_BENEFICIARY_ORDER_INFORMATIONS_FROM_SESSION: "GET_BENEFICIARY_ORDER_INFORMATIONS_FROM_SESSION",
    GET_BENEFICIARY_ORDER_OVERVIEW_FROM_SESSION: "GET_BENEFICIARY_ORDER_OVERVIEW_FROM_SESSION",
    GET_BENEFICIARY_ORDER_CEILING: "GET_BENEFICIARY_ORDER_CEILING",
    GET_BENEFICIARY_ORDER_PARTS: "GET_BENEFICIARY_ORDER_PARTS",
    GET_DUPLICATION_INFORMATION: "GET_DUPLICATION_INFORMATION",
    GET_ESPACE_BENEFICIARY_LOGIN_URL: "GET_ESPACE_BENEFICIARY_LOGIN_URL",
    GET_ESPACE_BENEFICIARY_REGISTRATION_URL: "GET_ESPACE_BENEFICIARY_REGISTRATION_URL",
    GET_ESPACE_BENEFICIARY_URL: "GET_ESPACE_BENEFICIARY_URL",
    GET_API_HEALTH: "GET_API_HEALTH",
    GET_INTERVENANT_VALIDATION_PRE_REGISTRATION: "GET_INTERVENANT_VALIDATION_PRE_REGISTRATION",
    GET_INTERVENANT_PROFILE: "GET_INTERVENANT_PROFILE",
    GET_INTERVENANT_PROFILE_MAIN_INFORMATIONS: "GET_INTERVENANT_PROFILE_MAIN_INFORMATIONS",
    GET_INTERVENANT_PROFILE_REFUND_INFORMATIONS: "GET_INTERVENANT_PROFILE_REFUND_INFORMATIONS",
    GET_INTERVENANT_ACCOUNTS_STATS: "GET_INTERVENANT_ACCOUNTS_STATS",
    GET_INTERVENANT_TITLES_STATS: "GET_INTERVENANT_TITLES_STATS",
    GET_MILLESIME_IN_TRADE: "GET_MILLESIME_IN_TRADE",
    GET_ONLINE_ORDER_RESULTS: "GET_ONLINE_ORDER_RESULTS",
    GET_REGISTRATION_PARAMETERS: "GET_REGISTRATION_TYPE",
    GET_PARAM_ESPACE_DOMISERVE: "GET_PARAM_ESPACE_DOMISERVE",
    GET_PAYMENT_REQUEST_NUMBER_BY_TRANSACTION_REFERENCE: "GET_PAYMENT_REQUEST_NUMBER_BY_TRANSACTION_REFERENCE",
    GET_PREORDER_SESSIONS_MATRICULE: "GET_PREORDER_SESSIONS_MATRICULE",
    GET_PREORDER_SESSION_DETAILS: "GET_PREORDER_SESSION_DETAILS",
    GET_PREORDER_SESSION_ORDERS: "GET_PREORDER_SESSION_ORDERS",
    GET_PREORDER_SESSION_STATISTICS: "GET_PREORDER_SESSION_STATISTICS",
    GET_REPORTS_TREE: "GET_REPORTS_TREE",
    GET_RULES_INVOICE_CALCULATIONS: "GET_RULES_INVOICE_CALCULATIONS",
    GET_SESSIONS_OF_CONTRACT: "GET_SESSIONS_OF_CONTRACT",
    GET_SCELLIUS_DATA: "GET_SCELLIUS_DATA",
    GET_TRADE_ORDER_SCELLIUS_DATA: "GET_TRADE_ORDER_SCELLIUS_DATA",
    GET_TVA_INVOICE_CALCULATIONS: "GET_TVA_INVOICE_CALCULATIONS",
    GET_SESSION_COMPLEMENTARY_INFO_FOR_BENEFICIARY: "GET_SESSION_COMPLEMENTARY_INFO_FOR_BENEFICIARY",
    HANDOVER_CHECKBOOK: "HANDOVER_CHECKBOOK",
    HAS_ACCOUNTS_OR_CHECKS_CONVERTIBLES: "HAS_ACCOUNTS_OR_CHECKS_CONVERTIBLES",
    LEGAL_FORMS: "LEGAL_FORMS",
    MAKE_BENEFICIARY_REGISTRATION: "MAKE_BENEFICIARY_REGISTRATION",
    MAKE_INTERVENANT_ACCOUNT_PAYMENT_REQUEST: "MAKE_INTERVENANT_ACCOUNT_PAYMENT_REQUEST",
    MAKE_INTERVENANT_CHECK_PAYMENT_REQUEST: "MAKE_INTERVENANT_CHECK_PAYMENT_REQUEST",
    ORDER_DETAILS: "ORDER_DETAILS",
    PREORDER_SESSION_CLOSE_REQUEST: "PREORDER_SESSION_CLOSE_REQUEST",
    PREORDER_SESSION_CREATE_ORDER_REQUEST: "PREORDER_SESSION_CREATE_ORDER_REQUEST",
    PREORDER_SESSION_CREATE_UPDATE_REQUEST: "PREORDER_SESSION_CREATE_UPDATE_REQUEST",
    PREORDER_SESSION_INFORMATION_REQUEST: "PREORDER_SESSION_INFORMATION_REQUEST",
    PREORDER_SESSION_UPDATE_LINES_REQUEST: "PREORDER_SESSION_UPDATE_LINES_REQUEST",
    PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_REQUEST: "PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_REQUEST",
    RECOVER_LOGIN_BENEFICIARY_AREA: "RECOVER_LOGIN_BENEFICIARY_AREA",
    RECOVER_LOGIN_PASSWORD: "RECOVER_LOGIN_PASSWORD",
    REGISTER_NEW_INTERVENANT: "REGISTER_NEW_INTERVENANT",
    REOPEN_MANAGEMENT_REQUEST: "REOPEN_MANAGEMENT_REQUEST",
    RETRIEVE_BANK: "RETRIEVE_BANK",
    RETRIEVE_BANK_ACCOUNT: "RETRIEVE_BANK_ACCOUNT",
    RETRIEVE_BENEFICIARY_ACCOUNTS_FOR_STATEMENT: "RETRIEVE_BENEFICIARY_ACCOUNTS_FOR_STATEMENT",
    RETRIEVE_BENEFICIARY_ACCOUNTS_FOR_MILLESIME_TRADE: "RETRIEVE_BENEFICIARY_ACCOUNTS_FOR_MILLESIME_TRADE",
    RETRIEVE_BENEFICIARY_CHECKBOOKS_FOR_STATEMENT: "RETRIEVE_BENEFICIARY_CHECKBOOKS_FOR_STATEMENT",
    RETRIEVE_BENEFICIARY_AREA_PARAMETER: "RETRIEVE_BENEFICIARY_AREA_PARAMETER",
    RETRIEVE_BENEFICIARY_CHECKBOOKS_INTERVENANT_PAYMENT: "RETRIEVE_BENEFICIARY_CHECKBOOKS_INTERVENANT_PAYMENT",
    RETRIEVE_BENEFICIARY_ORDER_CLIENTS: "RETRIEVE_BENEFICIARY_ORDER_CLIENTS",
    RETRIEVE_BENEFICIARY_PAID_INTERVENANTS: "RETRIEVE_BENEFICIARY_PAID_INTERVENANTS",
    RETRIEVE_MAX_CREDIT_CARD_SLICE: "RETRIEVE_MAX_CREDIT_CARD_SLICE",
    RETRIEVE_ORDER: "RETRIEVE_ORDER",
    RETRIEVE_ORDER_REASONS: "RETRIEVE_ORDER_REASONS",
    RETRIEVE_ORDER_TYPES: "RETRIEVE_ORDER_TYPES",
    RETRIEVE_PARAM_ESPACE_COMPTE: "RETRIEVE_PARAM_ESPACE_COMPTE",
    RETRIEVE_PROFILE_INFORMATION_VALUES: "RETRIEVE_PROFILE_INFORMATION_VALUES",
    RETRIEVE_PRODUCT_TYPE_CHOICE: "RETRIEVE_PRODUCT_TYPE_CHOICE",
    RETRIEVE_STYLE: "RETRIEVE_STYLE",
    RETRIEVE_TEXTS: "RETRIEVE_TEXTS",
    RETRIEVE_TITLE_STATE_TYPES: "RETRIEVE_TITLE_STATE_TYPES",
    RETRIEVE_TRADE_PARAMETERS_BY_CESU_ACCOUNT: "RETRIEVE_TRADE_PARAMETERS_BY_CESU_ACCOUNT",
    SAVE_NEW_REPORTS_FILE: "SAVE_NEW_REPORTS_FILE",
    SAVE_NEW_REPORTS_FOLDER: "SAVE_NEW_REPORTS_FOLDER",
    SAVE_ONLINE_ORDER: "SAVE_ONLINE_ORDER",
    SEARCH_BENEFICIARY: "SEARCH_BENEFICIARY",
    SEARCH_MANAGEMENT_REQUEST_BENEFICIARY: "SEARCH_MANAGEMENT_REQUEST_BENEFICIARY",
    SEARCH_TOWN: "SEARCH_TOWN",
    SIRET_VALIDATION: "SIRET_VALIDATION",
    UPDATE_INTERVENANT_PROFILE_MAIN_INFORMATIONS: "UPDATE_INTERVENANT_PROFILE_MAIN_INFORMATIONS",
    UPDATE_BENEFICIARY_PROFILE_MAIN_INFORMATIONS: "UPDATE_BENEFICIARY_PROFILE_MAIN_INFORMATIONS",
    UPDATE_MANAGEMENT_REQUEST: "UPDATE_MANAGEMENT_REQUEST",
    UPDATE_PROFILE_INFORMATION_VALUES: "UPDATE_PROFILE_INFORMATION_VALUES",
    UPDATE_PRODUCT_TYPE_CHOICE: "UPDATE_PRODUCT_TYPE_CHOICE",
    UPLOAD_MANAGEMENT_REQUEST_FILE: "UPLOAD_MANAGEMENT_REQUEST_FILE",
    URSSAF_CESU_CHECK_EMPLOYEE_ACCOUNT: "URSSAF_CESU_CHECK_EMPLOYEE_ACCOUNT",
    URSSAF_CESU_CHECK_EMPLOYER_ACCOUNT: "URSSAF_CESU_CHECK_EMPLOYER_ACCOUNT",
    URSSAF_CESU_MAKE_DECLARATION: "URSSAF_CESU_MAKE_DECLARATION",
    URSSAF_CESU_DOWNLOAD_DECLARATION_RECEIPT: "URSSAF_CESU_DOWNLOAD_DECLARATION_RECEIPT",
    URSSAF_CESU_GET_INFO_FROM_LAST_DECLARATION: "URSSAF_CESU_GET_INFO_FROM_LAST_DECLARATION",
    URSSAF_CESU_GET_LAST_CESU_CODE_AND_MANDATE_STATE: "URSSAF_CESU_GET_LAST_CESU_CODE_AND_MANDATE_STATE",
    URSSAF_CESU_RETRIEVE_DECLARED_EMPLOYEE_CESU_NUMBERS: "URSSAF_CESU_RETRIEVE_DECLARED_EMPLOYEE_CESU_NUMBERS",
    URSSAF_CESU_RETRIEVE_DECLARED_EMPLOYER_CESU_NUMBERS: "URSSAF_CESU_RETRIEVE_DECLARED_EMPLOYER_CESU_NUMBERS",
    URSSAF_CESU_UPDATE_MANDATE_STATE: "URSSAF_CESU_UPDATE_MANDATE_STATE",
    URSSAF_PAJE_CHECK_AND_ADD_CHILDREN: "URSSAF_PAJE_CHECK_AND_ADD_CHILDREN",
    URSSAF_PAJE_GET_LAST_DECLARATION: "URSSAF_PAJE_GET_LAST_DECLARATION",
    URSSAF_PAJE_GET_LAST_PAJE_CODE_AND_MANDATE_STATE: "URSSAF_PAJE_GET_LAST_PAJE_CODE_AND_MANDATE_STATE",
    URSSAF_PAJE_MAKE_ASSOCIATION: "URSSAF_PAJE_MAKE_ASSOCIATION",
    URSSAF_PAJE_MAKE_DECLARATION: "URSSAF_PAJE_MAKE_DECLARATION",
    URSSAF_PAJE_MAKE_PRE_DECLARATION: "URSSAF_PAJE_MAKE_PRE_DECLARATION",
    URSSAF_PAJE_RETRIEVE_CHILDREN: "URSSAF_PAJE_RETRIEVE_CHILDREN",
    URSSAF_PAJE_UPDATE_MANDATE_STATE: "URSSAF_PAJE_UPDATE_MANDATE_STATE",
    URSSAF_PAJE_DECLARATIONS_HISTORY: "URSSAF_PAJE_DECLARATIONS_HISTORY",
    URSSAF_PAJE_DOWNLOAD_DECLARATION_RECEIPT: "URSSAF_PAJE_DOWNLOAD_DECLARATION_RECEIPT",
    USER_AUTHORITY: "USER_AUTHORITY",
    USER_INFO: "USER_INFO",
    VALIDATE_TRADE_ORDER: "VALIDATE_TRADE_ORDER",
    VALIDATE_TRADE_ACCOUNT_ORDER: "VALIDATE_TRADE_ACCOUNT_ORDER",
    VERIFY_MARKETING_OPERATION: "VERIFY_MARKETING_OPERATION",
}

/**
 * Object describing all request reducers.
 */
export const RequestInfo = {
    [RequestKeys.ADD_MANAGEMENT_REQUEST_FOLLOW_UP]: {
        name: "addManagementRequestFollowUp",
    },
    [RequestKeys.BENEFICIARIES_SEARCH]: {
        name: "beneficiariesSearchRequest",
        actionTypeRequest: RETRIEVE_BENEFICIARIES_REQUEST,
        actionTypeSuccess: RETRIEVE_BENEFICIARIES_SUCCESS,
        actionTypeFailure: RETRIEVE_BENEFICIARIES_FAILURE,
    },
    [RequestKeys.BENEFICIARY_FIND_BY_ID]: {
        name: "beneficiaryFindByIdRequest",
    },
    [RequestKeys.CALCULATE_ONLINE_ORDER_COSTS]: {
        name: "calculateOnlineOrderCostsRequest",
        actionTypeRequest: CALCULATE_ONLINE_ORDER_COSTS_REQUEST,
        actionTypeSuccess: CALCULATE_ONLINE_ORDER_COSTS_SUCCESS,
        actionTypeFailure: CALCULATE_ONLINE_ORDER_COSTS_FAILURE,
    },
    [RequestKeys.CALCULATE_FACIAL_VALUES]: {
        name: "calculateFacialValuesRequest",
    },
    [RequestKeys.CALCULATE_BENEFICIARY_PREORDER_FEES]: {
        name: "calculateBeneficiaryPreOrderFees",
    },
    [RequestKeys.CALCULATE_ACCOUNT_TRADING_FEES]: {
        name: "calculateAccountTradingFeesRequest",
    },
    [RequestKeys.CALCULATE_TRADING_FEES]: {
        name: "calculateTradingFeesRequest",
    },
    [RequestKeys.CALCULATE_RELOADING_FEES]: {
        name: "calculateReloadingFeesRequest",
    },
    [RequestKeys.CALCUL_PAYMENT_COMPLEMENT_FEES]: {
        name: "calculPaymentComplementFeesRequest",
    },
    [RequestKeys.CHANGE_ACTUAL_PASSWORD]: {
        name: "changeActualPasswordRequest",
    },
    [RequestKeys.CHANGE_BENEFICIARIES_ACTIVATIONS]: {
        name: "changeBeneficiariesActivationsRequest",
        actionTypeRequest: CHANGE_BENEFICIARIES_ACTIVATIONS_REQUEST,
        actionTypeSuccess: CHANGE_BENEFICIARIES_ACTIVATIONS_SUCCESS,
        actionTypeFailure: CHANGE_BENEFICIARIES_ACTIVATIONS_FAILURE,
    },
    [RequestKeys.CHANGE_PASSWORD]: {
        name: "changePasswordRequest",
        actionTypeRequest: CHANGE_PASSWORD_REQUEST,
        actionTypeSuccess: CHANGE_PASSWORD_SUCCESS,
        actionTypeFailure: CHANGE_PASSWORD_FAILURE,
    },
    [RequestKeys.CHECK_CRCESU_WEB_SERVICE_AVAILABILITY]: {
        name: "checkCRCESUWebServiceAvailability",
    },
    [RequestKeys.CHECK_SESSION_CONTROL_INFORMATIONS_FOR_BENEFICIARY]: {
        name: "checkSessionControlInformations",
    },
    [RequestKeys.CHECK_SECRET_CODE_TITLE]: {
        name: "checkSecretCodeTitleRequest",
    },
    [RequestKeys.CHECK_VALID_TITLES]: {
        name: "checkValidTitlesRequest",
    },
    [RequestKeys.CREATE_MANAGEMENT_REQUEST]: {
        name: "createManagementRequest",
    },
    [RequestKeys.CREATE_INTERLOCUTOR]: {
        name: "createInterlocutorRequest",
    },
    [RequestKeys.CREATE_INTERLOCUTOR_BENEFICIARY]: {
        name: "createInterlocutorBeneficiariesRequest",
    },
    [RequestKeys.UPDATE_INTERLOCUTOR_BENEFICIARY]: {
        name: "updateInterlocutorBeneficiariesRequest",
    },
    [RequestKeys.DELETE_INTERLOCUTOR]: {
        name: "deleteInterlocutorRequest",
    },
    [RequestKeys.DELETE_INTERLOCUTOR_BENEFICIARY]: {
        name: "deleteInterlocutorBeneficiaryRequest",
    },
    [RequestKeys.CANCEL_BENEFICIARY_ORDER]: {
        name: "cancelBeneficiaryOrderRequest",
    },
    [RequestKeys.CHECK_CONTROL_INFORMATIONS_FOR_BENEFICIARY_REGISTRATION]: {
        name: "checkControlInformationsForBeneficiaryRequest",
    },
    [RequestKeys.CHECK_IF_ALREADY_REGISTERED]: {
        name: "checkIfAlreadyRegisteredRequest",
    },
    [RequestKeys.CREATE_ORDER_DEPOSIT]: {
        name: "createOrderDepositRequest",
        actionTypeRequest: CREATE_ORDER_DEPOSIT_REQUEST,
        actionTypeSuccess: CREATE_ORDER_DEPOSIT_SUCCESS,
        actionTypeFailure: CREATE_ORDER_DEPOSIT_FAILURE,
    },
    [RequestKeys.CREATE_BARCODE_HANDOVER]: {
        name: "createBarCodeHandOverRequest",
    },
    [RequestKeys.CREATE_SIMPLE_HANDOVER]: {
        name: "createSimpleHandOverRequest",
    },
    [RequestKeys.CREATE_FILE_HANDOVER]: {
        name: "createFileHandOverRequest",
    },
    [RequestKeys.CREATE_SESSION_GROUP]: {
        name: "createSessionGroupRequest",
        actionTypeRequest: CREATE_SESSION_GROUP_REQUEST,
        actionTypeSuccess: CREATE_SESSION_GROUP_SUCCESS,
        actionTypeFailure: CREATE_SESSION_GROUP_FAILURE,
    },
    [RequestKeys.CREATE_OR_UPDATE_PREORDER]: {
        name: "createOrUpdatePreOrderRequest",
    },
    [RequestKeys.CONVERT_ACCOUNT_TO_CHECKBOOK]: {
        name: "convertAccountToCheckbookRequest",
    },
    [RequestKeys.CONVERT_CHECKBOOK_TO_ACCOUNT]: {
        name: "convertCheckbookToAccountRequest",
    },
    [RequestKeys.EXCEL_DOWNLOAD]: {
        name: "excelDownloadRequest",
        actionTypeRequest: EXCEL_DOWNLOAD_REQUEST,
        actionTypeSuccess: EXCEL_DOWNLOAD_SUCCESS,
        actionTypeFailure: EXCEL_DOWNLOAD_FAILURE,
    },
    [RequestKeys.FILER_DOWNLOAD]: {
        name: "filerDownloadRequest",
        actionTypeRequest: FILER_DOWNLOAD_REQUEST,
        actionTypeSuccess: FILER_DOWNLOAD_SUCCESS,
        actionTypeFailure: FILER_DOWNLOAD_FAILURE,
        actionTypeReset: CLEAR_FILER_DOWNLOAD_REQUEST,
    },
    [RequestKeys.FINANCEUR_GENERAL_DATA]: {
        name: "finaneurGeneralDataRequest",
    },
    [RequestKeys.GENERATE_ACCOUNT_STATEMENT_PDF]: {
        name: "generateAccountStatementPdfRequest",
    },
    [RequestKeys.GENERATE_BENEFICIARY_BYPASS_LOGIN_TOKEN]: {
        name: "generateBeneficiaryBypassLoginToken",
    },
    [RequestKeys.GENERATE_BILL_TRANSFER]: {
        name: "generateBillTransferRequest",
    },
    [RequestKeys.GENERATE_HANDOVER_PDF]: {
        name: "generateHandOverPdfRequest",
    },
    [RequestKeys.GET_ACCOUNT_TRADE_ORDER_SCELLIUS_DATA]: {
        name: "getAccountTradeOrderScelliusDataRequest",
    },
    [RequestKeys.GET_ALL_KINDS_WAY_ADDRESS]: {
        name: "getAllKindsWayAddressRequest",
    },
    [RequestKeys.GET_ALL_TYPES_WAY_ADDRESS]: {
        name: "getAllTypesWayAddressRequest",
    },
    [RequestKeys.GET_API_HEALTH]: {
        name: "getAPIHealthRequest",
    },
    [RequestKeys.GET_ENABLED_FEATURES]: {
        name: "getEnabledFeaturesRequest",
    },
    [RequestKeys.GET_BENEFICIARY_CONTRACTS]: {
        name: "getBeneficiaryContractsRequest",
    },
    [RequestKeys.GET_BENEFICIARY_CONVERTIBLE_ACCOUNTS]: {
        name: "getBeneficiaryConvertibleAccounts",
    },
    [RequestKeys.GET_BENEFICIARY_CONVERTIBLE_CHECKBOOKS]: {
        name: "getBeneficiaryConvertibleCheckbooks",
    },
    [RequestKeys.GET_BENEFICIARY_CONVERTIBLE_CHECKS]: {
        name: "getBeneficiaryConvertibleChecks",
    },
    [RequestKeys.GET_BENEFICIARY_PRE_ORDER_SCELLIUS_DATA]: {
        name: "getBeneficiaryPreOrderScelliusDataRequest",
    },
    [RequestKeys.GET_BENEFICIARY_INTERVENANT_ACCOUNT_PAYMENT_SCELLIUS_DATA]: {
        name: "getBeneficiaryIntervenantAccountPaymentScelliusData",
    },
    [RequestKeys.GET_BENEFICIARY_STATS]: {
        name: "getBeneficiaryStatsRequest",
    },
    [RequestKeys.GET_BENEFICIARY_ORDER_INFORMATIONS_FROM_SESSION]: {
        name: "getBeneficiaryOrderInformationsFromSessionRequest",
    },
    [RequestKeys.GET_BENEFICIARY_ORDER_OVERVIEW_FROM_SESSION]: {
        name: "getBeneficiaryOrderOverviewFromSessionRequest",
    },
    [RequestKeys.GET_BENEFICIARY_ORDER_CEILING]: {
        name: "getBeneficiaryOrderCeilingRequest",
    },
    [RequestKeys.GET_BENEFICIARY_ORDER_PARTS]: {
        name: "getBeneficiaryOrderPartsRequest",
    },
    [RequestKeys.GET_BANKING_ESTABLISHMENT_WITH_IBAN]: {
        name: "getBankDomiciliationWithIBANAndBICRequest",
    },
    [RequestKeys.GET_CLIENT_INFORMATIONS]: {
        name: "getClientInformationsRequest",
    },
    [RequestKeys.GET_DOMISERVE_RIB]: {
        name: "getDomiserveRibRequest",
    },
    [RequestKeys.GET_DUPLICATION_INFORMATION]: {
        name: "getDuplicationInformationRequest",
        actionTypeRequest: GET_DUPLICATION_INFORMATION_REQUEST,
        actionTypeSuccess: GET_DUPLICATION_INFORMATION_SUCCESS,
        actionTypeFailure: GET_DUPLICATION_INFORMATION_FAILURE,
    },
    [RequestKeys.GET_ESPACE_BENEFICIARY_URL]: {
        name: "getEspaceBeneficiaryUrlRequest",
    },
    [RequestKeys.GET_ESPACE_BENEFICIARY_LOGIN_URL]: {
        name: "getEspaceBeneficiaryLoginUrlRequest",
    },
    [RequestKeys.GET_ESPACE_BENEFICIARY_REGISTRATION_URL]: {
        name: "getEspaceBeneficiaryRegistrationUrlRequest",
    },
    [RequestKeys.GET_INTERVENANT_VALIDATION_PRE_REGISTRATION]: {
        name: "getIntervenantValidationPreRegistrationRequest",
        actionTypeRequest: GET_INTERVENANT_VALIDATION_PRE_REGISTRATION_REQUEST,
        actionTypeSuccess: GET_INTERVENANT_VALIDATION_PRE_REGISTRATION_SUCCESS,
        actionTypeFailure: GET_INTERVENANT_VALIDATION_PRE_REGISTRATION_FAILURE,
    },
    [RequestKeys.GET_INTERVENANT_PROFILE]: {
        name: "getIntervenantProfileRequest",
    },
    [RequestKeys.GET_INTERVENANT_PROFILE_MAIN_INFORMATIONS]: {
        name: "getIntervenantProfileMainInformationsRequest",
    },
    [RequestKeys.GET_INTERVENANT_PROFILE_REFUND_INFORMATIONS]: {
        name: "getIntervenantProfileRefundInformationsRequest",
    },
    [RequestKeys.GET_INTERVENANT_ACCOUNTS_STATS]: {
        name: "getIntervenantTitlesAccountsRequest",
    },
    [RequestKeys.GET_INTERVENANT_TITLES_STATS]: {
        name: "getIntervenantTitlesStatsRequest",
    },
    [RequestKeys.GET_MILLESIME_IN_TRADE]: {
        name: "getMillesimeInTradeRequest",
        actionTypeRequest: GET_MILLESIME_IN_TRADE_REQUEST,
        actionTypeSuccess: GET_MILLESIME_IN_TRADE_SUCCESS,
        actionTypeFailure: GET_MILLESIME_IN_TRADE_FAILURE,
    },
    [RequestKeys.GET_ONLINE_ORDER_RESULTS]: {
        name: "getOnlineOrderResultsRequest",
        actionTypeRequest: GET_ONLINE_ORDER_RESULTS_REQUEST,
        actionTypeSuccess: GET_ONLINE_ORDER_RESULTS_SUCCESS,
        actionTypeFailure: GET_ONLINE_ORDER_RESULTS_FAILURE,
    },
    [RequestKeys.GET_PARAM_ESPACE_DOMISERVE]: {
        name: "getParamEspaceDomiserveRequest",
    },
    [RequestKeys.GET_PREORDER_SESSIONS_MATRICULE]: {
        name: "getPreorderSessionsMatricule",
        actionTypeRequest: GET_PREORDER_SESSIONS_MATRICULE_REQUEST,
        actionTypeSuccess: GET_PREORDER_SESSIONS_MATRICULE_SUCCESS,
        actionTypeFailure: GET_PREORDER_SESSIONS_MATRICULE_FAILURE,
    },
    [RequestKeys.GET_PREORDER_SESSION_ORDERS]: {
        name: "getPreorderSessionsOrders",
        actionTypeRequest: GET_PREORDER_SESSION_ORDERS_REQUEST,
        actionTypeSuccess: GET_PREORDER_SESSION_ORDERS_SUCCESS,
        actionTypeFailure: GET_PREORDER_SESSION_ORDERS_FAILURE,
    },
    [RequestKeys.GET_PREORDER_SESSION_STATISTICS]: {
        name: "getPreorderSessionsStatistics",
        actionTypeRequest: GET_PREORDER_SESSION_STATISTICS_REQUEST,
        actionTypeSuccess: GET_PREORDER_SESSION_STATISTICS_SUCCESS,
        actionTypeFailure: GET_PREORDER_SESSION_STATISTICS_FAILURE,
    },
    [RequestKeys.GET_REGISTRATION_PARAMETERS]: {
        name: "getRegistrationTypeRequest",
    },
    [RequestKeys.GET_REPORTS_TREE]: {
        name: "getReportsTreeRequest",
        actionTypeRequest: GET_REPORTS_TREE_REQUEST,
        actionTypeSuccess: GET_REPORTS_TREE_SUCCESS,
        actionTypeFailure: GET_REPORTS_TREE_FAILURE,
    },
    [RequestKeys.GET_RULES_INVOICE_CALCULATIONS]: {
        name: "invoiceCalculationsRulesRequest",
        actionTypeRequest: GET_RULES_INVOICE_CALCULATIONS_REQUEST,
        actionTypeSuccess: GET_RULES_INVOICE_CALCULATIONS_SUCCESS,
        actionTypeFailure: GET_RULES_INVOICE_CALCULATIONS_FAILURE,
    },
    [RequestKeys.GET_SCELLIUS_DATA]: {
        name: "scelliusDataRequest",
        actionTypeRequest: GET_SCELLIUS_DATA_REQUEST,
        actionTypeSuccess: GET_SCELLIUS_DATA_SUCCESS,
        actionTypeFailure: GET_SCELLIUS_DATA_FAILURE,
    },
    [RequestKeys.GET_TRADE_ORDER_SCELLIUS_DATA]: {
        name: "tradeOrderScelliusDataRequest",
    },
    [RequestKeys.GET_TVA_INVOICE_CALCULATIONS]: {
        name: "invoiceCalculationsTVAsRequest",
        actionTypeRequest: GET_TVA_INVOICE_CALCULATIONS_REQUEST,
        actionTypeSuccess: GET_TVA_INVOICE_CALCULATIONS_SUCCESS,
        actionTypeFailure: GET_TVA_INVOICE_CALCULATIONS_FAILURE,
    },
    [RequestKeys.GET_PAYMENT_REQUEST_NUMBER_BY_TRANSACTION_REFERENCE]: {
        name: "getPaymentRequestNumberByTransactionReference",
    },
    [RequestKeys.GET_PREORDER_SESSION_DETAILS]: {
        name: "getPreOrderSessionDetails",
    },
    [RequestKeys.GET_SESSIONS_OF_CONTRACT]: {
        name: "getSessionsOfContract",
    },
    [RequestKeys.GET_SESSION_COMPLEMENTARY_INFO_FOR_BENEFICIARY]: {
        name: "getSessionComplementaryInfoForBeneficiary",
    },
    [RequestKeys.HANDOVER_CHECKBOOK]: {
        name: "handOverCheckbook",
        actionTypeRequest: HANDOVER_CHECKBOOK_REQUEST,
        actionTypeSuccess: HANDOVER_CHECKBOOK_SUCCESS,
        actionTypeFailure: HANDOVER_CHECKBOOK_FAILURE,
    },
    [RequestKeys.HAS_ACCOUNTS_OR_CHECKS_CONVERTIBLES]: {
        name: "hasAccountOrChecksConvertibles",
    },
    [RequestKeys.LEGAL_FORMS]: {
        name: "legalFormsRequest",
        actionTypeRequest: GET_LEGAL_FORMS_REQUEST,
        actionTypeSuccess: GET_LEGAL_FORMS_SUCCESS,
        actionTypeFailure: GET_LEGAL_FORMS_FAILURE,
    },
    [RequestKeys.MAKE_BENEFICIARY_REGISTRATION]: {
        name: "makeBeneficiaryRegistrationRequest",
    },
    [RequestKeys.MAKE_INTERVENANT_ACCOUNT_PAYMENT_REQUEST]: {
        name: "makeIntervenantAccountPaymentRequest",
    },
    [RequestKeys.MAKE_INTERVENANT_CHECK_PAYMENT_REQUEST]: {
        name: "makeIntervenantCheckPaymentRequest",
    },
    [RequestKeys.ORDER_DETAILS]: {
        name: "orderDetailsRequest",
    },
    [RequestKeys.PREORDER_SESSION_CLOSE_REQUEST]: {
        name: "preOrderSessionCloseRequest",
        actionTypeRequest: PREORDER_SESSION_CLOSE_REQUEST,
        actionTypeSuccess: PREORDER_SESSION_CLOSE_SUCCESS,
        actionTypeFailure: PREORDER_SESSION_CLOSE_FAILURE,
    },
    [RequestKeys.PREORDER_SESSION_CREATE_ORDER_REQUEST]: {
        name: "preOrderSessionCreateOrderRequest",
        actionTypeRequest: PREORDER_SESSION_CREATE_ORDER_REQUEST,
        actionTypeSuccess: PREORDER_SESSION_CREATE_ORDER_SUCCESS,
        actionTypeFailure: PREORDER_SESSION_CREATE_ORDER_FAILURE,
    },
    [RequestKeys.PREORDER_SESSION_CREATE_UPDATE_REQUEST]: {
        name: "preOrderSessionCreateUpdateRequest",
        actionTypeRequest: PREORDER_SESSION_CREATE_UPDATE_REQUEST,
        actionTypeSuccess: PREORDER_SESSION_CREATE_UPDATE_SUCCESS,
        actionTypeFailure: PREORDER_SESSION_CREATE_UPDATE_FAILURE,
    },
    [RequestKeys.PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_REQUEST]: {
        name: "preOrderSessionValidateAllWaitingLinesRequest",
        actionTypeRequest: PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_REQUEST,
        actionTypeSuccess: PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_SUCCESS,
        actionTypeFailure: PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_FAILURE,
    },
    [RequestKeys.PREORDER_SESSION_UPDATE_LINES_REQUEST]: {
        name: "preOrderSessionUpdateLinesRequest",
        actionTypeRequest: PREORDER_SESSION_UPDATE_LINES_REQUEST,
        actionTypeSuccess: PREORDER_SESSION_UPDATE_LINES_SUCCESS,
        actionTypeFailure: PREORDER_SESSION_UPDATE_LINES_FAILURE,
    },
    [RequestKeys.PREORDER_SESSION_INFORMATION_REQUEST]: {
        name: "preOrderSessionInformationRequest",
        actionTypeRequest: PREORDER_SESSION_INFORMATION_REQUEST,
        actionTypeSuccess: PREORDER_SESSION_INFORMATION_SUCCESS,
        actionTypeFailure: PREORDER_SESSION_INFORMATION_FAILURE,
    },
    [RequestKeys.RECOVER_LOGIN_PASSWORD]: {
        name: "recoverLoginPasswordRequest",
        actionTypeRequest: RECOVER_LOGIN_PASSWORD_REQUEST,
        actionTypeSuccess: RECOVER_LOGIN_PASSWORD_SUCCESS,
        actionTypeFailure: RECOVER_LOGIN_PASSWORD_FAILURE,
    },
    [RequestKeys.RECOVER_LOGIN_BENEFICIARY_AREA]: {
        name: "recoverLoginBeneficiaryArea",
    },
    [RequestKeys.REGISTER_NEW_INTERVENANT]: {
        name: "registerNewIntervenantRequest",
    },
    [RequestKeys.REOPEN_MANAGEMENT_REQUEST]: {
        name: "reopenManagementRequest",
    },
    [RequestKeys.RETRIEVE_BANK]: {
        name: "retrieveBankRequest",
    },
    [RequestKeys.RETRIEVE_BANK_ACCOUNT]: {
        name: "retrieveBankAccountRequest",
    },
    [RequestKeys.RETRIEVE_BENEFICIARY_ACCOUNTS_FOR_STATEMENT]: {
        name: "retrieveBeneficiaryAccountsForStatementRequest",
    },
    [RequestKeys.RETRIEVE_BENEFICIARY_ACCOUNTS_FOR_MILLESIME_TRADE]: {
        name: "retrieveBeneficiaryAccountsForMillesimeTradeRequest",
    },
    [RequestKeys.RETRIEVE_BENEFICIARY_CHECKBOOKS_FOR_STATEMENT]: {
        name: "retrieveBeneficiaryCheckbooksForStatementRequest",
    },
    [RequestKeys.RETRIEVE_BENEFICIARY_AREA_PARAMETER]: {
        name: "retrieveBeneficiaryAreaParameter",
    },
    [RequestKeys.RETRIEVE_BENEFICIARY_CHECKBOOKS_INTERVENANT_PAYMENT]: {
        name: "retrieveBeneficiaryCheckbooksForIntervenantPayment",
    },
    [RequestKeys.RETRIEVE_BENEFICIARY_ORDER_CLIENTS]: {
        name: "retrieveBeneficiaryOrderClientsRequest",
    },
    [RequestKeys.RETRIEVE_BENEFICIARY_PAID_INTERVENANTS]: {
        name: "retrieveBeneficiaryPaidIntervenantRequest",
    },
    [RequestKeys.RETRIEVE_MAX_CREDIT_CARD_SLICE]: {
        name: "retrieveMaxCreditCardSliceRequest",
    },
    [RequestKeys.RETRIEVE_TRADE_PARAMETERS_BY_CESU_ACCOUNT]: {
        name: "retrieveTradeParametersByCesuAccountRequest",
    },
    [RequestKeys.RETRIEVE_PROFILE_INFORMATION_VALUES]: {
        name: "profileInformationRequest",
        actionTypeRequest: RETRIEVE_PROFILE_INFORMATION_VALUES_REQUEST,
        actionTypeSuccess: RETRIEVE_PROFILE_INFORMATION_VALUES_SUCCESS,
        actionTypeFailure: RETRIEVE_PROFILE_INFORMATION_VALUES_FAILURE,
    },
    [RequestKeys.RETRIEVE_PARAM_ESPACE_COMPTE]: {
        name: "retrieveBeneficiaryAreaAccountParameterRequest",
    },
    [RequestKeys.RETRIEVE_ORDER]: {
        name: "retrieveOrderRequest",
        actionTypeRequest: RETRIEVE_ORDER_REQUEST,
        actionTypeSuccess: RETRIEVE_ORDER_SUCCESS,
        actionTypeFailure: RETRIEVE_ORDER_FAILURE,
    },
    [RequestKeys.RETRIEVE_ORDER_TYPES]: {
        name: "retrieveOrderTypesRequest",
    },
    [RequestKeys.RETRIEVE_ORDER_REASONS]: {
        name: "retrieveOrderReasonsRequest",
    },
    [RequestKeys.RETRIEVE_STYLE]: {
        name: "styleRequest",
        actionTypeRequest: RETRIEVE_STYLE_REQUEST,
        actionTypeSuccess: RETRIEVE_STYLE_SUCCESS,
        actionTypeFailure: RETRIEVE_STYLE_FAILURE,
    },
    [RequestKeys.RETRIEVE_TEXTS]: {
        name: "textsRequest",
        actionTypeRequest: RETRIEVE_TEXTS_REQUEST,
        actionTypeSuccess: RETRIEVE_TEXTS_SUCCESS,
        actionTypeFailure: RETRIEVE_TEXTS_FAILURE,
    },
    [RequestKeys.RETRIEVE_TITLE_STATE_TYPES]: {
        name: "retrieveTitleStateTypesRequest",
    },
    [RequestKeys.RETRIEVE_PRODUCT_TYPE_CHOICE]: {
        name: "retrieveProductTypeChoiceRequest",
    },
    [RequestKeys.SAVE_NEW_REPORTS_FILE]: {
        name: "saveNewReportsFile",
        actionTypeRequest: SAVE_NEW_REPORTS_FILE_REQUEST,
        actionTypeSuccess: SAVE_NEW_REPORTS_FILE_SUCCESS,
        actionTypeFailure: SAVE_NEW_REPORTS_FILE_FAILURE,
    },
    [RequestKeys.SAVE_NEW_REPORTS_FOLDER]: {
        name: "saveNewReportsFolder",
        actionTypeRequest: SAVE_NEW_REPORTS_FOLDER_REQUEST,
        actionTypeSuccess: SAVE_NEW_REPORTS_FOLDER_SUCCESS,
        actionTypeFailure: SAVE_NEW_REPORTS_FOLDER_FAILURE,
    },
    [RequestKeys.SAVE_ONLINE_ORDER]: {
        name: "onlineOrderRequest",
        actionTypeRequest: SAVE_ONLINE_ORDER_REQUEST,
        actionTypeSuccess: SAVE_ONLINE_ORDER_SUCCESS,
        actionTypeFailure: SAVE_ONLINE_ORDER_FAILURE,
    },
    [RequestKeys.SEARCH_BENEFICIARY]: {
        name: "searchBeneficiaryRequest",
    },
    [RequestKeys.SEARCH_MANAGEMENT_REQUEST_BENEFICIARY]: {
        name: "searchManagementRequestBeneficiaryRequest",
    },
    [RequestKeys.SEARCH_TOWN]: {
        name: "searchTownRequest",
        actionTypeRequest: SEARCH_TOWN_REQUEST,
        actionTypeSuccess: SEARCH_TOWN_SUCCESS,
        actionTypeFailure: SEARCH_TOWN_FAILURE,
    },
    [RequestKeys.SIRET_VALIDATION]: {
        name: "siretValidationRequest",
        actionTypeRequest: SIRET_VALIDATION_REQUEST,
        actionTypeSuccess: SIRET_VALIDATION_SUCCESS,
        actionTypeFailure: SIRET_VALIDATION_FAILURE,
        actionTypeReset: EMPTY_SIRET_VALIDATION,
    },
    [RequestKeys.UPDATE_BENEFICIARY_PROFILE_MAIN_INFORMATIONS]: {
        name: "updateBeneficiaryProfilMainInformationsRequest",
    },
    [RequestKeys.UPDATE_INTERVENANT_PROFILE_MAIN_INFORMATIONS]: {
        name: "updateIntervenantProfileMainInformationsRequest",
    },
    [RequestKeys.UPDATE_MANAGEMENT_REQUEST]: {
        name: "updateManagementRequest",
        actionTypeRequest: UPDATE_MANAGEMENT_REQUEST_REQUEST,
        actionTypeSuccess: UPDATE_MANAGEMENT_REQUEST_SUCCESS,
        actionTypeFailure: UPDATE_MANAGEMENT_REQUEST_FAILURE,
    },
    [RequestKeys.UPDATE_PROFILE_INFORMATION_VALUES]: {
        name: "updateProfileInformationValuesRequest",
        actionTypeRequest: UPDATE_PROFILE_INFORMATION_VALUES_REQUEST,
        actionTypeSuccess: UPDATE_PROFILE_INFORMATION_VALUES_SUCCESS,
        actionTypeFailure: UPDATE_PROFILE_INFORMATION_VALUES_FAILURE,
    },
    [RequestKeys.UPLOAD_MANAGEMENT_REQUEST_FILE]: {
        name: "uploadManagementRequestFile",
        actionTypeRequest: UPLOAD_MANAGEMENT_REQUEST_FILE_REQUEST,
        actionTypeSuccess: UPLOAD_MANAGEMENT_REQUEST_FILE_SUCCESS,
        actionTypeFailure: UPLOAD_MANAGEMENT_REQUEST_FILE_FAILURE,
    },
    [RequestKeys.URSSAF_CESU_CHECK_EMPLOYEE_ACCOUNT]: {
        name: "urssafCesuCheckEmployeeAccountRequest",
    },
    [RequestKeys.URSSAF_CESU_CHECK_EMPLOYER_ACCOUNT]: {
        name: "urssafCesuCheckEmployerAccountRequest",
    },
    [RequestKeys.URSSAF_CESU_MAKE_DECLARATION]: {
        name: "urssafCesuMakeDeclarationRequest",
    },
    [RequestKeys.URSSAF_CESU_DOWNLOAD_DECLARATION_RECEIPT]: {
        name: "urssafCesuDownloadDeclarationReceiptRequest",
    },
    [RequestKeys.URSSAF_CESU_GET_INFO_FROM_LAST_DECLARATION]: {
        name: "urssafCesuGetInfoFromLastDeclarationRequest",
    },
    [RequestKeys.URSSAF_CESU_GET_LAST_CESU_CODE_AND_MANDATE_STATE]: {
        name: "urssafCesuGetLastCesuCodeAndMandateStateRequest",
    },
    [RequestKeys.URSSAF_CESU_RETRIEVE_DECLARED_EMPLOYEE_CESU_NUMBERS]: {
        name: "urssafCesuRetrieveDeclaredEmployeeCesuNumbersRequest",
    },
    [RequestKeys.URSSAF_CESU_RETRIEVE_DECLARED_EMPLOYER_CESU_NUMBERS]: {
        name: "urssafCesuRetrieveDeclaredEmployerCesuNumbersRequest",
    },
    [RequestKeys.URSSAF_CESU_UPDATE_MANDATE_STATE]: {
        name: "urssafCesuCesuUpdateMandateStateRequest",
    },
    [RequestKeys.URSSAF_PAJE_CHECK_AND_ADD_CHILDREN]: {
        name: "urssafPajeCheckAndAddChildrenRequest",
    },
    [RequestKeys.URSSAF_PAJE_GET_LAST_DECLARATION]: {
        name: "urssafPajeGetGetLastDeclarationRequest",
    },
    [RequestKeys.URSSAF_PAJE_GET_LAST_PAJE_CODE_AND_MANDATE_STATE]: {
        name: "urssafPajeGetLastPajeCodeAndMandateStateRequest",
    },
    [RequestKeys.URSSAF_PAJE_MAKE_ASSOCIATION]: {
        name: "urssafPajeMakeAssociationRequest",
    },
    [RequestKeys.URSSAF_PAJE_MAKE_DECLARATION]: {
        name: "urssafPajeMakeDeclarationRequest",
    },
    [RequestKeys.URSSAF_PAJE_MAKE_PRE_DECLARATION]: {
        name: "urssafPajeMakePreDeclarationRequest",
    },
    [RequestKeys.URSSAF_PAJE_RETRIEVE_CHILDREN]: {
        name: "urssafPajeRetrieveChildreRequest",
    },
    [RequestKeys.URSSAF_PAJE_UPDATE_MANDATE_STATE]: {
        name: "urssafPajePajeUpdateMandateStateRequest",
    },
    [RequestKeys.URSSAF_PAJE_DECLARATIONS_HISTORY]: {
        name: "urssafPajeDeclarationHistoryRequest",
    },
    [RequestKeys.URSSAF_PAJE_DOWNLOAD_DECLARATION_RECEIPT]: {
        name: "urssafajeDownloadDeclarationReceiptRequest",
    },
    [RequestKeys.USER_AUTHORITY]: {
        name: "userAuthorityRequest",
        actionTypeRequest: CONNECT_USER_REQUEST,
        actionTypeSuccess: CONNECT_USER_SUCCESS,
        actionTypeFailure: CONNECT_USER_FAILURE,
        actionTypeReset: DISCONNECT_USER,
    },
    [RequestKeys.USER_INFO]: {
        name: "userInformationRequest",
        actionTypeRequest: GET_USER_INFO_REQUEST,
        actionTypeSuccess: GET_USER_INFO_SUCCESS,
        actionTypeFailure: GET_USER_INFO_FAILURE,
        actionTypeReset: DISCONNECT_USER,
    },
    [RequestKeys.UPDATE_PRODUCT_TYPE_CHOICE]: {
        name: "updateProductTypeChoiceRequest",
    },
    [RequestKeys.VALIDATE_TRADE_ORDER]: {
        name: "validateTradeOrderRequest",
    },
    [RequestKeys.VALIDATE_TRADE_ACCOUNT_ORDER]: {
        name: "validateTradeAccountOrderRequest",
    },
    [RequestKeys.VALIDATE_CONTROL_FILE]: {
        name: "validateControlFileRequest",
        actionTypeRequest: VALIDATE_CONTROL_FILE_REQUEST,
        actionTypeSuccess: VALIDATE_CONTROL_FILE_SUCCESS,
        actionTypeFailure: VALIDATE_CONTROL_FILE_FAILURE,
    },
    [RequestKeys.VERIFY_MARKETING_OPERATION]: {
        name: "verifyMarketingOperationsRequest",
        actionTypeRequest: VERIFY_MARKETING_OPERATION_REQUEST,
        actionTypeSuccess: VERIFY_MARKETING_OPERATION_SUCCESS,
        actionTypeFailure: VERIFY_MARKETING_OPERATION_FAILURE,
    },
}
