/**
 * Indicate that we want to save a dynamic configuration value.
 * @type {string}
 */
export const SAVE_DYNAMIC_CONFIGURATION_VALUE = "SAVE_DYNAMIC_CONFIGURATION_VALUE";
/**
 * Indicate that we want to clear all the dynamic configuration.
 * @type {string}
 */
export const CLEAR_DYNAMIC_CONFIGURATION = "CLEAR_DYNAMIC_CONFIGURATION";
