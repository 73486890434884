/**
 * Indicate that we start a retrieve order lines request
 */
export const RETRIEVE_ORDER_LINES_REQUEST = "RETRIEVE_ORDER_LINES_REQUEST";
/**
 * Indicate that the retrieve order lines request was successful
 */
export const RETRIEVE_ORDER_LINES_SUCCESS = "RETRIEVE_ORDER_LINES_SUCCESS";
/**
 * Indicate that the retrieve order lines has failed
 */
export const RETRIEVE_ORDER_LINES_FAILURE = "RETRIEVE_ORDER_LINES_FAILURE";
