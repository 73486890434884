import {
    EDIT_LINE_TABLE_DATA,
    FILL_TABLE_DATA,
    SELECT_ALL_TABLE_DATA,
    TOGGLE_ACTIVATE_LINE_TABLE_DATA,
    TOGGLE_SELECT_LINE_TABLE_DATA,
    DESELECT_ALL_TABLE_DATA,
    EMPTY_TABLE,
    TOGGLE_ACTIVATE_MANY_LINES_TABLE_DATA,
    ACTIVATE_MANY_LINES_TABLE_DATA,
    DESACTIVATE_MANY_LINES_TABLE_DATA,
    CLEAR_TABLE,
    ADD_TABLE_DATA,
    REMOVE_TABLE_DATA,
} from "../../../actions/temporaries/table/tableActionType"
import {findIndex, omit, remove} from "lodash"

/**
 * Table reducer.
 * Stock in store all details about all tables of the application.
 * Tables are identified by their names.
 */
const table = (state = {}, action = {}) => {
    switch (action.type) {
        case FILL_TABLE_DATA:
            const data = []
            if (action.ids) {
                action.ids.forEach(
                    (id, index) =>
                        (data[index] = {
                            id,
                            isSelected: false,
                            isActivated: false,
                        }),
                )
            }
            return {
                ...state,
                [action.tableName]: {
                    editedLineId: null,
                    data,
                },
            }

        case ADD_TABLE_DATA:
            const tableData = state[action.tableName] ? [...state[action.tableName].data] : []
            const length = tableData.length
            action.ids.forEach(
                (id, index) =>
                    (tableData[index + length] = {
                        id,
                        isSelected: false,
                        isActivated: false,
                    }),
            )
            return {
                ...state,
                [action.tableName]: {
                    editedLineId:
                        state[action.tableName] && state[action.tableName].editedLineId
                            ? state[action.tableName].editedLineId
                            : null,
                    data: tableData,
                },
            }

        case REMOVE_TABLE_DATA:
            let notRemovedData = state[action.tableName].data
            remove(notRemovedData, d => {
                return action.ids.includes(d.id)
            })
            return {
                ...state,
                [action.tableName]: {
                    editedLineId:
                        state[action.tableName] && state[action.tableName].editedLineId
                            ? state[action.tableName].editedLineId
                            : null,
                    data: notRemovedData,
                },
            }

        case EMPTY_TABLE:
            return state[action.tableName]
                ? {
                      ...state,
                      [action.tableName]: {
                          editedLineId: null,
                          data: [],
                      },
                  }
                : state

        case CLEAR_TABLE:
            return omit(state, action.tableName)

        case SELECT_ALL_TABLE_DATA:
            return state[action.tableName]
                ? {
                      ...state,
                      [action.tableName]: {
                          ...state[action.tableName],
                          data: state[action.tableName].data.map(item => {
                              return {
                                  ...item,
                                  isSelected: true,
                              }
                          }),
                      },
                  }
                : state

        case DESELECT_ALL_TABLE_DATA:
            return state[action.tableName]
                ? {
                      ...state,
                      [action.tableName]: {
                          ...state[action.tableName],
                          data: state[action.tableName].data.map(item => {
                              return {
                                  ...item,
                                  isSelected: false,
                              }
                          }),
                      },
                  }
                : state

        case TOGGLE_SELECT_LINE_TABLE_DATA:
            return state[action.tableName]
                ? {
                      ...state,
                      [action.tableName]: {
                          ...state[action.tableName],
                          data: state[action.tableName].data.map(item => {
                              if (item.id === action.lineId) {
                                  return {
                                      ...item,
                                      isSelected: !item.isSelected,
                                  }
                              }
                              return item
                          }),
                      },
                  }
                : state

        case TOGGLE_ACTIVATE_LINE_TABLE_DATA:
            return state[action.tableName]
                ? {
                      ...state,
                      [action.tableName]: {
                          ...state[action.tableName],
                          data: state[action.tableName].data.map(item => {
                              if (item.id === action.lineId) {
                                  return {
                                      ...item,
                                      isActivated: !item.isActivated,
                                  }
                              }
                              return item
                          }),
                      },
                  }
                : state

        case TOGGLE_ACTIVATE_MANY_LINES_TABLE_DATA:
            return state[action.tableName]
                ? {
                      ...state,
                      [action.tableName]: {
                          ...state[action.tableName],
                          // Toggle isActivated for ids in Array to items currently in state
                          data: state[action.tableName].data.map(item => {
                              if (findIndex(action.linesIds, id => id === item.id) >= 0) {
                                  return {
                                      ...item,
                                      isActivated: !item.isActivated,
                                  }
                              }
                              return item
                          }),
                      },
                  }
                : state

        case ACTIVATE_MANY_LINES_TABLE_DATA:
            return state[action.tableName]
                ? {
                      ...state,
                      [action.tableName]: {
                          ...state[action.tableName],
                          // Change isActivated value positively for ids in Array to items currently in state
                          data: state[action.tableName].data.map(item => {
                              if (findIndex(action.linesIds, id => id === item.id) >= 0) {
                                  return {
                                      ...item,
                                      isActivated: true,
                                  }
                              }
                              return item
                          }),
                      },
                  }
                : state

        case DESACTIVATE_MANY_LINES_TABLE_DATA:
            return state[action.tableName]
                ? {
                      ...state,
                      [action.tableName]: {
                          ...state[action.tableName],
                          // Change isActivated value negatively for ids in Array to items currently in state
                          data: state[action.tableName].data.map(item => {
                              if (findIndex(action.linesIds, id => id === item.id) >= 0) {
                                  return {
                                      ...item,
                                      isActivated: false,
                                  }
                              }
                              return item
                          }),
                      },
                  }
                : state

        case EDIT_LINE_TABLE_DATA:
            return state[action.tableName]
                ? {
                      ...state,
                      [action.tableName]: {
                          ...state[action.tableName],
                          editedLineId: action.lineId,
                      },
                  }
                : state

        default:
            return state
    }
}

export default table

/**
 * Return all ids for a table.
 * @param tableName the name of the table
 */
export const getIds = (state, tableName) => (state[tableName] ? state[tableName].data.map(item => item.id) : [])
/**
 * Return the ids of the selected lines in a table.
 * @param tableName the name of the table
 */
export const getSelectedLines = (state, tableName) =>
    state[tableName] ? state[tableName].data.filter(item => item.isSelected).map(item => item.id) : []
/**
 * Return the ids of the activated lines in a table.
 * @param tableName the name of the table
 */
export const getActivatedLines = (state, tableName) =>
    state[tableName] ? state[tableName].data.filter(item => item.isActivated).map(item => item.id) : []

/**
 * Return if line ID is activated in table
 * @param tableName the name of the table
 * @param id the line id to test
 */
export const isLineActivated = (state, tableName, id) =>
    state[tableName] ? state[tableName].data.filter(item => item.id === id).map(item => item.isActivated)[0] : null

/**
 * Get the id of the line in edition.
 * @param tableName the name of the table
 */
export const getLineInEdition = (state, tableName) => (state[tableName] ? state[tableName].editedLineId : null)
