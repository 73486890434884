import PropTypes from "prop-types"
import React from "react"
import {Button as SemanticButton} from "semantic-ui-react"
import "./button.scss"

/**
 * A button based on https://react.semantic-ui.com/elements/button.
 */
const Button = ({children, type, actionType, className, dataTestId, ...props}) => {
    const dataTestAttribute = {"data-testid": dataTestId}

    return (
        <SemanticButton
            className={
                (type === "danger"
                    ? "button-component-danger "
                    : type === "grey"
                    ? "button-component-grey "
                    : type === "warn"
                    ? "button-component-warn "
                    : type === "success"
                    ? "button-component-success "
                    : type === "secondary"
                    ? "button-component-secondary "
                    : "") +
                "button-component " +
                (className ? className : "")
            }
            {...props}
            {...dataTestAttribute}
            type={actionType}
        >
            {children}
        </SemanticButton>
    )
}

Button.propTypes = {
    actionType: PropTypes.oneOf(["button", "reset", "submit"]),
    children: PropTypes.any,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(["success", "danger", "grey", "warn", "primary", "secondary"]),
}

Button.defaultProps = {
    className: null,
    children: null,
    dataTestId: undefined,
    onClick: null,
    disabled: false,
    loading: false,
    actionType: undefined,
    type: "success",
}
export default Button
