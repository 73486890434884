/**
 * Indicate that we launched a request to retrieve the bills.
 * @type {string}
 */
export const RETRIEVE_BILLS_REQUEST = "RETRIEVE_BILLS_REQUEST";

/**
 * Indicate that we retrieved successfully the bills.
 * @type {string}
 */
export const RETRIEVE_BILLS_SUCCESS = "RETRIEVE_BILLS_SUCCESS";

/**
 * Indicate that we didn't receive the bills.
 * @type {string}
 */
export const RETRIEVE_BILLS_FAILURE = "RETRIEVE_BILLS_FAILURE";
