import {CHECK_RESPONSIVENESS} from "./menuActionTypes";

/**
 * Action to check if the menu should be responsive
 */
export const setResponsive = isResponsive => {
    return {
        type: CHECK_RESPONSIVENESS,
        isResponsive,
    };
};
