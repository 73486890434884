import {combineReducers} from "redux"
import {
    SEARCH_TOWN_FAILURE,
    SEARCH_TOWN_REQUEST,
    SEARCH_TOWN_SUCCESS,
} from "../../../../actions/temporaries/search/searchActionTypes"
import {omit} from "lodash"

/**
 * Town reducer.
 */
const town = () => {
    const isFetching = (state = [], action = {}) => {
        switch (action.type) {
            case SEARCH_TOWN_REQUEST:
                return {...state, [action.name]: true}
            case SEARCH_TOWN_SUCCESS:
                return {...state, [action.name]: false}
            case SEARCH_TOWN_FAILURE:
                return omit(state, action.name)
            default:
                return state
        }
    }

    const results = (state = [], action = {}) => {
        switch (action.type) {
            case SEARCH_TOWN_SUCCESS:
                return {...state, [action.name]: action.response.resultats}
            case SEARCH_TOWN_FAILURE:
                return omit(state, action.name)
            default:
                return state
        }
    }

    const query = (state = null, action = {}) => {
        switch (action.type) {
            case SEARCH_TOWN_SUCCESS:
                return {...state, [action.name]: action.response.requete}
            case SEARCH_TOWN_FAILURE:
                return omit(state, action.name)
            default:
                return state
        }
    }

    return combineReducers({
        isFetching,
        results,
        query,
    })
}

export default town

/**
 * Return the results of the search found in the state.
 */
export const getResults = (state, name) => state.results[name]

/**
 * Return the query of the search found in the state.
 */
export const getQuery = (state, name) => state.query[name]

/**
 * Retourne le "isFetching" de la requête trouvé dans le state.
 */
export const isFetching = (state, name) => state.isFetching[name]
