import React, {Component} from "react"
import PropTypes from "prop-types"
import MenuDropdownPush from "./menuDropdownPush"

/**
 * Dropdown item list container.
 * Handle open/close state.
 */
class MenuDropdownPushContainer extends Component {
    constructor() {
        super()

        this.state = {
            isOpened: false,
        }
    }

    toggleOpen = () => {
        this.setState({
            isOpened: !this.state.isOpened,
        })
    }

    render() {
        return (
            <div className="menu-dropdown-push-container">
                <MenuDropdownPush {...this.props} isOpened={this.state.isOpened} toggleOpen={this.toggleOpen} />
            </div>
        )
    }
}

MenuDropdownPushContainer.propTypes = {
    // The header's text.
    text: PropTypes.string.isRequired,
    // The menu content.
    choices: PropTypes.arrayOf(
        PropTypes.oneOfType([
            // A dropdown item.
            PropTypes.shape({
                type: PropTypes.oneOf(["ITEM"]),
                text: PropTypes.string.isRequired,
                onClick: PropTypes.func.isRequired,
                active: PropTypes.bool,
            }),
            // A dropdown text.
            PropTypes.shape({
                type: PropTypes.oneOf(["DROPDOWN"]),
                text: PropTypes.string.isRequired,
                choices: PropTypes.arrayOf(
                    PropTypes.shape({
                        type: PropTypes.oneOf(["ITEM"]),
                        text: PropTypes.string.isRequired,
                        onClick: PropTypes.func.isRequired,
                        active: PropTypes.bool,
                    }),
                ),
            }),
        ]),
    ).isRequired,
}

export default MenuDropdownPushContainer
