import * as localStorage from "./localStorage"
import {checkIfEmptyStorageNeeded, getLastVersion} from "./version"

export const checkVersion = (VersionKeys, Versions) => {
    const actualVersion = localStorage.loadVersion()
    const lastVersion = getLastVersion(Versions)

    if (checkIfEmptyStorageNeeded(actualVersion, Versions, VersionKeys)) {
        localStorage.clearStorage()

        if (process.env.NODE_ENV !== "production") {
            console.info("Clear storage : from version " + actualVersion + " to " + lastVersion)
        }
    }

    localStorage.saveVersion(lastVersion)
}
