/**
 * The pages of the application.
 */
export const Page = {
    BYPASS_LOGIN: "BYPASS_LOGIN",
    CONFIRMATION: "CONFIRMATION",
    DELIVERY: "DELIVERY",
    LOGIN: "LOGIN",
    HOME: "HOME",
    NOT_FOUND: "NOT_FOUND",
    PAYMENT: "PAYMENT",
    TECHNICAL_ERROR: "TECHNICAL_ERROR",
    TITLES: "TITLES",
    UNAUTHORIZED: "UNAUTHORIZED",
}
