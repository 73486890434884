/**
 * Indicate that we launched a request to retrieve the management requests.
 */
export const RETRIEVE_MANAGEMENT_REQUESTS_REQUEST = "RETRIEVE_MANAGEMENT_REQUESTS_REQUEST"
/**
 * Indicate that we retrieved successfully the management requests.
 */
export const RETRIEVE_MANAGEMENT_REQUESTS_SUCCESS = "RETRIEVE_MANAGEMENT_REQUESTS_SUCCESS"
/**
 * Indicate that we didn't receive the management requests.
 */
export const RETRIEVE_MANAGEMENT_REQUESTS_FAILURE = "RETRIEVE_MANAGEMENT_REQUESTS_FAILURE"

/**
 * Indicate that we launched a request to update the management requests.
 */
export const UPDATE_MANAGEMENT_REQUEST_REQUEST = "UPDATE_MANAGEMENT_REQUEST_REQUEST"
/**
 * Indicate that we successfully updated the management requests.
 */
export const UPDATE_MANAGEMENT_REQUEST_SUCCESS = "UPDATE_MANAGEMENT_REQUEST_SUCCESS"
/**
 * Indicate that we didn't update the management requests.
 */
export const UPDATE_MANAGEMENT_REQUEST_FAILURE = "UPDATE_MANAGEMENT_REQUEST_FAILURE"

/**
 * Indicate that we launched a request to retrieve the management request follow ups.
 */
export const RETRIEVE_MANAGEMENT_REQUEST_FOLLOW_UPS_REQUEST = "RETRIEVE_MANAGEMENT_REQUEST_FOLLOW_UPS_REQUEST"
/**
 * Indicate that we retrieved successfully the management request follow ups.
 */
export const RETRIEVE_MANAGEMENT_REQUEST_FOLLOW_UPS_SUCCESS = "RETRIEVE_MANAGEMENT_REQUEST_FOLLOW_UPS_SUCCESS"
/**
 * Indicate that we didn't receive the management request follow ups.
 */
export const RETRIEVE_MANAGEMENT_REQUEST_FOLLOW_UPS_FAILURE = "RETRIEVE_MANAGEMENT_REQUEST_FOLLOW_UPS_FAILURE"

/**
 * Indicate that we launched a request to retrieve the management request files.
 */
export const RETRIEVE_MANAGEMENT_REQUEST_FILES_REQUEST = "RETRIEVE_MANAGEMENT_REQUEST_FILES_REQUEST"
/**
 * Indicate that we retrieved successfully the management request files.
 */
export const RETRIEVE_MANAGEMENT_REQUEST_FILES_SUCCESS = "RETRIEVE_MANAGEMENT_REQUEST_FILES_SUCCESS"
/**
 * Indicate that we didn't receive the management request files.
 */
export const RETRIEVE_MANAGEMENT_REQUEST_FILES_FAILURE = "RETRIEVE_MANAGEMENT_REQUEST_FILES_FAILURE"

/**
 * Indicate that we launched a request to upload a management request file.
 */
export const UPLOAD_MANAGEMENT_REQUEST_FILE_REQUEST = "UPLOAD_MANAGEMENT_REQUEST_FILE_REQUEST"
/**
 * Indicate that we uploaded successfully a management request file.
 */
export const UPLOAD_MANAGEMENT_REQUEST_FILE_SUCCESS = "UPLOAD_MANAGEMENT_REQUEST_FILE_SUCCESS"
/**
 * Indicate that we didn't upload a management request file.
 */
export const UPLOAD_MANAGEMENT_REQUEST_FILE_FAILURE = "UPLOAD_MANAGEMENT_REQUEST_FILE_FAILURE"

/**
 * Indicate that we launched a request to get all creators interlocutors by contractId
 */
export const RETRIEVE_MANAGEMENT_REQUEST_CREATORS_INTERLOCUTORS_REQUEST =
    "RETRIEVE_MANAGEMENT_REQUEST_CREATORS_INTERLOCUTORS_REQUEST"

/**
 * Indicate that the request to get all creators interlocutors by contractId was a success.
 */
export const RETRIEVE_MANAGEMENT_REQUEST_CREATORS_INTERLOCUTORS_SUCCESS =
    "RETRIEVE_MANAGEMENT_REQUEST_CREATORS_INTERLOCUTORS_SUCCESS"

/**
 * Indicate that the request to get all creators interlocutors by contractId failed.
 */
export const RETRIEVE_MANAGEMENT_REQUEST_CREATORS_INTERLOCUTORS_FAILURE =
    "RETRIEVE_MANAGEMENT_REQUEST_CREATORS_INTERLOCUTORS_FAILURE"

/**
 * Indicate that we launched a request to get all in charge interlocutors by contractId
 */
export const RETRIEVE_MANAGEMENT_REQUEST_IN_CHARGE_INTERLOCUTORS_REQUEST =
    "RETRIEVE_MANAGEMENT_REQUEST_IN_CHARGE_INTERLOCUTORS_REQUEST"

/**
 * Indicate that the request to get all in charge interlocutors by contractId was a success.
 */
export const RETRIEVE_MANAGEMENT_REQUEST_IN_CHARGE_INTERLOCUTORS_SUCCESS =
    "RETRIEVE_MANAGEMENT_REQUEST_IN_CHARGE_INTERLOCUTORS_SUCCESS"

/**
 * Indicate that the request to get all in charge interlocutors by contractId failed.
 */
export const RETRIEVE_MANAGEMENT_REQUEST_IN_CHARGE_INTERLOCUTORS_FAILURE =
    "RETRIEVE_MANAGEMENT_REQUEST_IN_CHARGE_INTERLOCUTORS_FAILURE"
