import React, {Component} from "react"
import PropTypes from "prop-types"
import MobileMenuItemDropdown from "./mobileMenuItemDropdown"

/**
 * Dropdown item list container for sub menu in mobile menu.
 * Handle open/close state.
 */
class MobileMenuItemDropdownContainer extends Component {
    constructor() {
        super()

        this.state = {
            isOpened: false,
        }
    }

    toggleOpen = () => {
        this.setState({
            isOpened: !this.state.isOpened,
        })
    }

    render() {
        return (
            <div className="menu-dropdown-push-container">
                <MobileMenuItemDropdown {...this.props} isOpened={this.state.isOpened} toggleOpen={this.toggleOpen} />
            </div>
        )
    }
}

MobileMenuItemDropdownContainer.propTypes = {
    // The header's text.
    text: PropTypes.string.isRequired,
    // The menu content.
    choices: PropTypes.arrayOf(
        PropTypes.oneOfType([
            // A dropdown item.
            PropTypes.shape({
                type: PropTypes.oneOf(["ITEM"]),
                text: PropTypes.string.isRequired,
                onClick: PropTypes.func.isRequired,
                active: PropTypes.bool,
            }),
        ]),
    ).isRequired,
}

export default MobileMenuItemDropdownContainer
