/**
 * The trade types
 */
export const TradeTypes = {
    ACCOUNT: {
        text: "Échange en Compte CESU",
        key: "ACCOUNT",
    },
    TITLE: {
        text: "Échange en Titre CESU",
        key: "TITLE",
    },
}
