import React from "react"
import PropTypes from "prop-types"
import "./mobileMenuItemDropdown.scss"
import MenuItemContainer from "../MenuItem/menuItemContainer"
import FadeTransition from "../FadeTransition/fadeTransition"
import {displayMenu} from "../../services/displayMenu"
import {Icon} from "semantic-ui-react"

/**
 * Dropdown list component for a mobile menu.
 * Open and close on click.
 * Menu content push the background.
 */
const MobileMenuItemDropdown = ({isConnected, text, choices, isOpened, toggleOpen, userPermissions}, {sheet}) => {
    const isActive = choices.reduce((acc, current) => {
        return acc || current.active
    }, false)

    return (
        <div className="mobile-menu-item-dropdown">
            <MenuItemContainer
                active={isOpened || isActive}
                className="mobile-menu-item-dropdown-item"
                onClick={toggleOpen}
            >
                {/* Menu header. Contains the header's text and the little arrow. */}
                <div className="mobile-menu-item-dropdown-item-content">
                    {text}

                    {/* Arrow down when closed, up when opened. */}
                    {!isOpened && <Icon className="mobile-menu-item-dropdown-icon" name="caret down" />}
                    {isOpened && <Icon className="mobile-menu-item-dropdown-icon reverse" name="caret down" />}
                </div>
            </MenuItemContainer>

            {/* Open with a fade transition. */}
            {isOpened && (
                <FadeTransition>
                    <div className={sheet.classes.borderSecondaryColor + " mobile-menu-item-dropdown-panel"}>
                        {/* Loop through all content. */}
                        {choices.map(
                            (choice, index) =>
                                displayMenu(
                                    isConnected,
                                    choice.permissions,
                                    choice.userConnected,
                                    userPermissions,
                                    choice.isFeatureEnabled,
                                ) && (
                                    <MenuItemContainer
                                        active={choice.active}
                                        className="mobile-menu-item-dropdown-choice"
                                        key={index}
                                        onClick={choice.onClick}
                                        userPermissions={userPermissions}
                                    >
                                        {choice.text}
                                    </MenuItemContainer>
                                ),
                        )}
                    </div>
                </FadeTransition>
            )}
        </div>
    )
}

MobileMenuItemDropdown.contextTypes = {
    sheet: PropTypes.object,
}

MobileMenuItemDropdown.propTypes = {
    // The header's text.
    text: PropTypes.string.isRequired,
    isConnected: PropTypes.bool.isRequired,
    // The menu content.
    choices: PropTypes.arrayOf(
        PropTypes.oneOfType([
            // A dropdown item.
            PropTypes.shape({
                type: PropTypes.oneOf(["ITEM"]),
                text: PropTypes.string.isRequired,
                onClick: PropTypes.func.isRequired,
                active: PropTypes.bool,
            }),
        ]),
    ).isRequired,

    // Open state.
    isOpened: PropTypes.bool.isRequired,
    toggleOpen: PropTypes.func.isRequired,
}

export default MobileMenuItemDropdown
