import React from "react"
import PropTypes from "prop-types"
import "./menuDropdown.scss"
import MenuItemContainer from "../MenuItem/menuItemContainer"
import FadeTransition from "../FadeTransition/fadeTransition"
import MenuItemDropdownContainer from "../MenuItemDropdown/menuItemDropdownContainer"
import {displayMenu, isFeatureEnabled} from "../../services/displayMenu"
import {Icon} from "semantic-ui-react"

/**
 * Dropdown list component for a menu.
 * Stay open on hover.
 * Menu content appears over the background.
 */
const MenuDropdown = (
    {
        isConnected,
        text,
        choices,
        isOpened,
        onMouseEnter,
        onMouseLeave,
        alignment,
        onChoiceClick,
        userPermissions,
        displayNotificationFunction,
    },
    {sheet},
) => {
    const isActive = choices.reduce((acc, current) => {
        return (
            acc ||
            (current.type === "ITEM"
                ? current.active
                : current.choices.reduce((acc, current2) => {
                      return acc || current2.active
                  }, false))
        )
    }, false)

    return (
        <div className="menu-dropdown-component" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {/* Menu header. Contains the header's text and the little arrow. */}
            <MenuItemContainer
                active={isOpened || isActive}
                className="menu-dropdown-item"
                displayNotificationFunction={displayNotificationFunction}
                features={choices.map(c => c.feature)}
            >
                {text}
                <Icon className="menu-dropdown-icon" name="caret down" />
            </MenuItemContainer>

            {/* Open with a fade transition. */}
            {isOpened && (
                <FadeTransition>
                    <div
                        className={
                            sheet.classes.borderSecondaryColor +
                            " menu-dropdown-component-panel" +
                            (alignment === "LEFT" ? " panel-left" : " panel-right")
                        }
                    >
                        {/* Loop through all content. */}
                        {choices.map((choice, index) => {
                            switch (choice.type) {
                                // Menu item.
                                case "ITEM":
                                    return (
                                        displayMenu(
                                            isConnected,
                                            choice.permissions,
                                            choice.userConnected,
                                            userPermissions,
                                            choice.isFeatureEnabled,
                                        ) && (
                                            <MenuItemContainer
                                                active={choice.active}
                                                className="menu-dropdown-component-choice menu-dropdown-component-choice-item"
                                                displayNotificationFunction={displayNotificationFunction}
                                                features={choice.feature}
                                                key={index}
                                                onClick={() => onChoiceClick(choice.onClick)}
                                            >
                                                {choice.text}
                                            </MenuItemContainer>
                                        )
                                    )

                                // Menu dropdown list.
                                case "DROPDOWN":
                                    return (
                                        displayMenu(
                                            isConnected,
                                            choice.permissions,
                                            choice.userConnected,
                                            userPermissions,
                                            isFeatureEnabled(choice.choices),
                                        ) && (
                                            <div
                                                className="menu-dropdown-component-choice menu-dropdown-component-choice-item menu-dropdown-component-choice-dropdown"
                                                key={index}
                                            >
                                                <MenuItemDropdownContainer
                                                    choices={choice.choices.map(c => ({
                                                        ...c,
                                                        onClick: () => onChoiceClick(c.onClick),
                                                    }))}
                                                    displayNotificationFunction={displayNotificationFunction}
                                                    isConnected={isConnected}
                                                    text={choice.text}
                                                    userPermissions={userPermissions}
                                                />
                                            </div>
                                        )
                                    )

                                default:
                                    return <div key={index}>UNKOWN_TYPE</div>
                            }
                        })}
                    </div>
                </FadeTransition>
            )}
        </div>
    )
}

MenuDropdown.contextTypes = {
    sheet: PropTypes.object,
}

MenuDropdown.propTypes = {
    // Text for the dropdown list header.
    text: PropTypes.string.isRequired,
    isConnected: PropTypes.bool.isRequired,

    // Panel alignment.
    alignment: PropTypes.oneOf(["LEFT", "RIGHT"]),

    // Content of the list.
    choices: PropTypes.arrayOf(
        PropTypes.oneOfType([
            // A dropdown item.
            PropTypes.shape({
                type: PropTypes.oneOf(["ITEM"]),
                text: PropTypes.string.isRequired,
                onClick: PropTypes.func.isRequired,
                active: PropTypes.bool,
                permissions: PropTypes.arrayOf(PropTypes.string),
            }),
            // A dropdown text.
            PropTypes.shape({
                type: PropTypes.oneOf(["DROPDOWN"]),
                text: PropTypes.string.isRequired,
                choices: PropTypes.arrayOf(
                    PropTypes.shape({
                        type: PropTypes.oneOf(["ITEM"]),
                        text: PropTypes.string.isRequired,
                        onClick: PropTypes.func.isRequired,
                        active: PropTypes.bool,
                        permissions: PropTypes.arrayOf(PropTypes.string),
                    }),
                ),
            }),
        ]),
    ).isRequired,

    // Opened state.
    isOpened: PropTypes.bool.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,

    onChoiceClick: PropTypes.func.isRequired,

    userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    displayNotificationFunction: PropTypes.func,
}

export default MenuDropdown
