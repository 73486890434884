import {intersection} from "lodash"

/**
 * Display the menu if :
 * - The menu is visible for connected user and with permissions or without permissions if permissions is undefined or empty
 * - The menu is visible only for not connected users and the user is not connected
 * @param isConnected       true if the user is connected, false otherwise
 * @param permissions       permissions needed to view the menu
 * @param userConnected     true if the menu is displayed for connected user only
 * @param userPermissions   connected user permissions
 * @param isFeatureEnabled  additional configuration to enable a feature, default true
 * @return                  true if the menu should be visible, false otherwise
 */
export const displayMenu = (isConnected, permissions, userConnected, userPermissions, isFeatureEnabled = true) => {
    return (
        (userConnected &&
            isFeatureEnabled &&
            isConnected &&
            ((!!permissions && intersection(userPermissions, permissions).length > 0) ||
                !permissions ||
                permissions.length === 0)) ||
        (!userConnected && !isConnected && isFeatureEnabled)
    )
}

/**
 * Recursive function used when we call displayMenu for a DROPDOWN
 * @param {Object} choices
 * See menu.js
 */
export const isFeatureEnabled = choices => {
    return choices.some(c => {
        let featureIsEnabled
        switch (c.type) {
            case "ITEM":
                featureIsEnabled = c.isFeatureEnabled || c.isFeatureEnabled === undefined
                break
            case "DROPDOWN":
                featureIsEnabled = isFeatureEnabled(c.choices)
                break
            default:
                featureIsEnabled = false
        }
        return featureIsEnabled
    })
}
