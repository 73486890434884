import {omit, keys} from "lodash"
import {
    ADD_TITLE_IN_TITLE_LIST,
    REMOVE_TITLE_FROM_TITLE_LIST,
    EDIT_TITLE_FIELD,
    EDIT_TITLES_OTHER_INFORMATIONS,
    RESET_TITLES,
} from "../../../actions/localStorage/titles/titlesActionTypes"

const titles = (state = {titleList: {}, otherInformations: {}}, action = {}) => {
    switch (action.type) {
        case ADD_TITLE_IN_TITLE_LIST:
            return {
                ...state,
                titleList: {
                    ...state["titleList"],
                    [action.id]: {
                        titleNumber: action.titleNumber,
                        facialValue: action.facialValue,
                        id: action.id,
                        secretCode: action.secretCode,
                        cesuCode: action.cesuCode,
                        beneficiaryName: action.beneficiaryName,
                        financeur: action.financeur,
                        millesime: action.millesime,
                        contractNumber: action.contractNumber,
                    },
                },
            }
        case EDIT_TITLE_FIELD: {
            return {
                ...state,
                titleList: {
                    ...state["titleList"],
                    [action.id]: {
                        ...state.titleList[action.id],
                        [action.fieldName]: action.value,
                    },
                },
            }
        }
        case EDIT_TITLES_OTHER_INFORMATIONS: {
            return {
                ...state,
                otherInformations: {
                    ...state["otherInformations"],
                    [action.fieldName]: action.value,
                },
            }
        }
        case REMOVE_TITLE_FROM_TITLE_LIST: {
            const newTitleList = omit(state.titleList, [action.id])
            // If we remove the last title, also set otherInformations.referenceContract to null to
            // avoid blocking the user for switching contract
            return {
                ...state,
                titleList: newTitleList,
                otherInformations:
                    keys(newTitleList).length === 0
                        ? {
                              ...state.otherInformations,
                              referenceContractNumber: null,
                          }
                        : state.otherInformations,
            }
        }
        case RESET_TITLES: {
            return {
                titleList: {},
                otherInformations: {},
            }
        }
        default:
            return state
    }
}

export default titles

/**
 * Get title by id
 */
export const getTitle = (state, id) => state.titleList[id]

/**
 * Get all titles
 */
export const getTitles = state => state.titleList

/**
 * Get other informations
 */
export const getOtherInformations = state => state.otherInformations
