import React from "react"
import PropTypes from "prop-types"
import {Grid, Icon} from "semantic-ui-react"
import "./menuTop.scss"

/**
 * Top menu.
 * Contains link to the profile and link to disconnect the current user.
 */
const MenuTop = (
    {
        contractId,
        displayVisualisationChoice,
        isMenuResponsive,
        onProfileClick,
        onDisconnectClick,
        onVisualisationChoiceClick,
        isConnected,
        socialReason,
        username,
    },
    {sheet},
) => (
    <div
        className={
            socialReason || displayVisualisationChoice
                ? "menu-top-component menu-top-component-with-content-on-left"
                : "menu-top-component"
        }
    >
        {!isMenuResponsive && (
            <Grid centered className={sheet.classes.backgroundPrimaryColor + " menu-top-inner"} columns="equal">
                {isConnected && (
                    <div
                        className={
                            socialReason || displayVisualisationChoice
                                ? "menu-top-content menu-top-content-with-content-on-left"
                                : "menu-top-content"
                        }
                    >
                        {socialReason && <div className="menu-top-content-left">{socialReason}</div>}

                        {displayVisualisationChoice && (
                            <div className="menu-top-content-left menu-top-link" onClick={onVisualisationChoiceClick}>
                                <Icon className="menu-top-content-left-icon" name="home" />
                                Choix de visualisation
                            </div>
                        )}

                        {/* Part to go to the profile of the current user. */}
                        <div className="menu-top-content-right">
                            <div className="menu-top-content-right-element">
                                <div
                                    className={
                                        (onProfileClick ? "" : "no-underline ") +
                                        "menu-top-link menu-top-content-right-user"
                                    }
                                    onClick={onProfileClick}
                                >
                                    <Icon className="menu-top-content-right-icon" name="user" />
                                    {username}
                                </div>

                                {/* Part to disconnect the current user. */}
                                <div className="menu-top-link" onClick={() => onDisconnectClick(contractId)}>
                                    <Icon className="menu-top-content-right-icon" name="times circle" />
                                    Se déconnecter
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Grid>
        )}
    </div>
)

MenuTop.contextTypes = {
    sheet: PropTypes.object,
}

MenuTop.propTypes = {
    contractId: PropTypes.string,
    displayVisualisationChoice: PropTypes.bool,
    isConnected: PropTypes.bool.isRequired,
    isMenuResponsive: PropTypes.bool,
    onProfileClick: PropTypes.func,
    onDisconnectClick: PropTypes.func.isRequired,
    onVisualisationChoiceClick: PropTypes.func,
    socialReason: PropTypes.string,
    username: PropTypes.string,
}

export default MenuTop
