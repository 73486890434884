import PropTypes from "prop-types"
import React from "react"
import RoundLoader from "../RoundLoader/roundLoader"

/**
 * Draw a loader when there is no data on customization, draw children when we have data
 * on customization.
 */
const StyleLoader = ({isCustomizedStyle, isCustomizedTexts, children}) => (
    <div className="style-loader">
        {(isCustomizedStyle === null || isCustomizedTexts === null) && <RoundLoader>Chargement</RoundLoader>}
        {isCustomizedStyle !== null && isCustomizedTexts !== null && children}
    </div>
)

StyleLoader.defaultProps = {
    children: null,
    isCustomizedStyle: null,
    isCustomizedTexts: null,
}

StyleLoader.propTypes = {
    children: PropTypes.any,
    isCustomizedStyle: PropTypes.bool,
    isCustomizedTexts: PropTypes.bool,
}

export default StyleLoader
