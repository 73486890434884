import {isFunction} from "lodash"
import PropTypes from "prop-types"
import React from "react"
import {Grid} from "semantic-ui-react"
import "./footer.scss"

const actualYear = new Date().getFullYear()

/**
 * Footer component.
 * Contains details about Domiserve and link to access to documents about the website.
 * TODO : A supprimer quand la nouvelle charte graphique sera intégrée sur tous les espaces
 */
const Footer = (
    {customActualYear, hideCookies, hideLegalNotice, onCookiesClick, onContactClick, onLegalClick, onRgpdClick},
    {sheet},
) => (
    <div className="footer-component">
        <Grid centered className={sheet.classes.backgroundPrimaryColor + " footer-component-inner"} columns="equal">
            <Grid.Column only="computer" />
            <Grid.Column className="font-bold footer-component-texts" width={14}>
                <div className={sheet.classes.textSecondaryColor + " footer-component-left-text"}>
                    Copyright &#9400; 2017 - {customActualYear ? customActualYear : actualYear} Domiserve
                </div>
                <div className="footer-component-links">
                    {/* Special class because it will disappear on mobiles. */}
                    <span className="footer-component-separator">|</span>
                    <span className="footer-component-link" onClick={onContactClick}>
                        Contact
                    </span>
                    {!hideLegalNotice && (
                        <>
                            |
                            <span className="footer-component-link" onClick={onLegalClick}>
                                Mentions légales
                            </span>
                        </>
                    )}
                    {isFunction(onRgpdClick) && (
                        <>
                            |
                            <span className="footer-component-link" onClick={onRgpdClick}>
                                Données personnelles
                            </span>
                        </>
                    )}
                    {!hideCookies && (
                        <>
                            |
                            <span className="footer-component-link" onClick={onCookiesClick}>
                                Paramétrage des cookies
                            </span>
                        </>
                    )}
                </div>
            </Grid.Column>
            <Grid.Column only="computer" />
        </Grid>
    </div>
)

Footer.contextTypes = {
    sheet: PropTypes.object,
}

Footer.defaultProps = {
    customActualYear: 0,
    hideCookies: false,
    hideLegalNotice: false,
    onCookiesClick: () => "",
    onRgpdClick: null,
}

Footer.propTypes = {
    customActualYear: PropTypes.number,
    hideCookies: PropTypes.bool,
    hideLegalNotice: PropTypes.bool,
    onContactClick: PropTypes.func.isRequired,
    onCookiesClick: PropTypes.func,
    onLegalClick: PropTypes.func.isRequired,
    onRgpdClick: PropTypes.func,
}

export default Footer
