/**
 * Get the errorMessage from an Axios error
 */
export const getErrorMessage = error => {
    if (error.response && error.response.data && error.response.data.errorMessage) {
        return error.response.data.errorMessage
    } else if (error.message) {
        return "Erreur " + error.message
    } else {
        return "Erreur"
    }
}
