import {createInstance, MatomoProvider} from "@datapunt/matomo-tracker-react"
import PropTypes from "prop-types"
import React from "react"
import {shallowEqual, useSelector} from "react-redux"

export const MatomoTracker = ({children, getCookiesChoices}) => {
    const siteId = process.env.REACT_APP_MATOMO_SITE_ID
    const url = process.env.REACT_APP_MATOMO_URL

    const cookiesChoices = useSelector(state => getCookiesChoices(state), shallowEqual)

    const instance =
        cookiesChoices.enabled && siteId && url
            ? createInstance({
                  urlBase: url,
                  siteId: siteId,
                  heartBeat: {
                      active: true,
                      seconds: 10,
                  },
                  linkTracking: true,
                  configurations: {
                      disableCookies: true,
                      setSecureCookie: true,
                      setRequestMethod: "POST",
                  },
              })
            : null

    return cookiesChoices.enabled && siteId && url ? (
        <MatomoProvider value={instance}>{children}</MatomoProvider>
    ) : (
        children
    )
}

MatomoTracker.propTypes = {
    children: PropTypes.element.isRequired,
    getCookiesChoices: PropTypes.func.isRequired,
}
