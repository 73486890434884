/**
 * Indicate that we launch a request to get intervenant informations for Pre Registration
 * @type {string}
 */
export const GET_INTERVENANT_VALIDATION_PRE_REGISTRATION_REQUEST = 'GET_INTERVENANT_VALIDATION_PRE_REGISTRATION_REQUEST'

/**
 * Indicate that the request to get intervenant informations for Pre Registration was successful
 * @type {string}
 */
export const GET_INTERVENANT_VALIDATION_PRE_REGISTRATION_SUCCESS = 'GET_INTERVENANT_VALIDATION_PRE_REGISTRATION_SUCCESS'

/**
 * Indicate that the request to get intervenant informations for Pre Registration failed
 * @type {string}
 */
export const GET_INTERVENANT_VALIDATION_PRE_REGISTRATION_FAILURE = 'GET_INTERVENANT_VALIDATION_PRE_REGISTRATION_FAILURE'

/**
 * Indicate that we want to save intervenant pre registration informations for the registration process
 * @type {string}
 */
export const SAVE_INTERVENANT_PRE_REGISTRATION_INFORMATIONS = 'SAVE_INTERVENANT_PRE_REGISTRATION_INFORMATIONS'

/**
 * Indicate that we want to save intervenant type pre registration for the registration process
 * @type {string}
 */
export const SAVE_INTERVENANT_TYPE_PRE_REGISTRATION = 'SAVE_INTERVENANT_TYPE_PRE_REGISTRATION'

/**
 * Clear all intervenant registration informations in local storage after registration process
 * @type {string}
 */
export const CLEAR_INTERVENANT_REGISTRATION_INFORMATIONS = 'CLEAR_INTERVENANT_REGISTRATION_INFORMATIONS'
