/**
 * Indicate that we launched a request to save an online order.
 */
export const SAVE_ONLINE_ORDER_REQUEST = "SAVE_ONLINE_ORDER_REQUEST";
/**
 * Indicate that we successfully saved an online order.
 */
export const SAVE_ONLINE_ORDER_SUCCESS = "SAVE_ONLINE_ORDER_SUCCESS";
/**
 * Indicate that we failed to save an online order.
 */
export const SAVE_ONLINE_ORDER_FAILURE = "SAVE_ONLINE_ORDER_FAILURE";
/**
 * Indicate that we launched a request to calculate online order costs.
 */
export const CALCULATE_ONLINE_ORDER_COSTS_REQUEST = "CALCULATE_ONLINE_ORDER_COSTS_REQUEST";
/**
 * Indicate that we successfully calculate online order costs.
 */
export const CALCULATE_ONLINE_ORDER_COSTS_SUCCESS = "CALCULATE_ONLINE_ORDER_COSTS_SUCCESS";
/**
 * Indicate that we failed to calculate online order costs.
 */
export const CALCULATE_ONLINE_ORDER_COSTS_FAILURE = "CALCULATE_ONLINE_ORDER_COSTS_FAILURE";
/**
 * Indicate that we launched a request to save an online order.
 */
export const GET_ONLINE_ORDER_RESULTS_REQUEST = "GET_ONLINE_ORDER_RESULTS_REQUEST";
/**
 * Indicate that we successfully saved an online order.
 */
export const GET_ONLINE_ORDER_RESULTS_SUCCESS = "GET_ONLINE_ORDER_RESULTS_SUCCESS";
/**
 * Indicate that we failed to save an online order.
 */
export const GET_ONLINE_ORDER_RESULTS_FAILURE = "GET_ONLINE_ORDER_RESULTS_FAILURE";
/**
 * Indicate that we want to save a mail in the online order results.
 */
export const SAVE_MAIL_IN_ONLINE_ORDER_RESULTS = "SAVE_MAIL_IN_ONLINE_ORDER_RESULTS";

/**
 * Indicate that we start a get duplication information request
 */
export const GET_DUPLICATION_INFORMATION_REQUEST = "GET_DUPLICATION_INFORMATION_REQUEST";

/**
 * Indicate that we successfully get information duplication
 */
export const GET_DUPLICATION_INFORMATION_SUCCESS = "GET_DUPLICATION_INFORMATION_SUCCESS";

/**
 * Indicate that we fail to get information duplication
 */
export const GET_DUPLICATION_INFORMATION_FAILURE = "GET_DUPLICATION_INFORMATION_FAILURE";
