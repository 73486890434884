import {
    INIT_PAGE,
    UPDATE_MAX_PAGES,
    GO_TO_PAGE,
    GO_TO_NEXT_PAGE,
    GO_TO_PREVIOUS_PAGE,
    UPDATE_PAGINATION,
} from "../../../actions/temporaries/pagination/paginationActionTypes"

const pagination = (state = {}, action = {}) => {
    const maxPages = action.maxPages
        ? action.maxPages
        : state[action.componentName] && state[action.componentName].maxPages
        ? state[action.componentName].maxPages
        : 1
    switch (action.type) {
        case INIT_PAGE:
            return {
                ...state,
                [action.componentName]: {
                    maxPages: 1,
                    activePage: 1,
                    totalResults: 0,
                },
            }
        case UPDATE_PAGINATION:
            return {
                ...state,
                [action.componentName]: {
                    maxPages: action.pagination.maxPages,
                    totalResults: action.pagination.totalResults,
                    activePage: action.pagination.number,
                },
            }
        case UPDATE_MAX_PAGES:
            if (!state[action.componentName]) {
                return state
            }
            return {
                ...state,
                [action.componentName]: {
                    ...state[action.componentName],
                    maxPages: action.maxPages,
                },
            }
        case GO_TO_PAGE:
            if (!state[action.componentName]) {
                return state
            }
            return {
                ...state,
                [action.componentName]: {
                    ...state[action.componentName],
                    activePage: action.page > maxPages ? maxPages : action.page,
                },
            }
        case GO_TO_NEXT_PAGE:
            if (!state[action.componentName]) {
                return state
            }
            const nextPage = state[action.componentName].activePage + 1
            return {
                ...state,
                [action.componentName]: {
                    ...state[action.componentName],
                    activePage: nextPage <= maxPages ? nextPage : maxPages,
                },
            }
        case GO_TO_PREVIOUS_PAGE:
            if (!state[action.componentName]) {
                return state
            }
            const previousPage = state[action.componentName].activePage - 1
            return {
                ...state,
                [action.componentName]: {
                    ...state[action.componentName],
                    activePage: previousPage >= 1 ? previousPage : 1,
                },
            }
        default:
            return state
    }
}

export default pagination

/**
 * Return the activePage for this table
 */
export const getActivePage = (state, componentName) => (state[componentName] ? state[componentName].activePage : 1)

/**
 * Return the maxPages
 */
export const getMaxPage = (state, componentName) => (state[componentName] ? state[componentName].maxPages : 1)

/**
 * Return the totalResults
 */
export const getTotalResults = (state, componentName) => (state[componentName] ? state[componentName].totalResults : 0)
