/**
 * Indicate that we launched a request to retrieve the intervenants.
 * @type {string}
 */
export const RETRIEVE_INTERVENANTS_REQUEST = "RETRIEVE_INTERVENANTS_REQUEST"
/**
 * Indicate that we retrieved successfully the intervenants.
 * @type {string}
 */
export const RETRIEVE_INTERVENANTS_SUCCESS = "RETRIEVE_INTERVENANTS_SUCCESS"

/**
 * Indicate that we didn't receive the intervenants.
 * @type {string}
 */
export const RETRIEVE_INTERVENANTS_FAILURE = "RETRIEVE_INTERVENANTS_FAILURE"
