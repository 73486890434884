/**
 * Indicate that we launched a request to retrieve the products.
 */
export const RETRIEVE_PRODUCTS_REQUEST = "RETRIEVE_PRODUCTS_REQUEST";
/**
 * Indicate that we retrieved successfully the products.
 */
export const RETRIEVE_PRODUCTS_SUCCESS = "RETRIEVE_PRODUCTS_SUCCESS";
/**
* Indicate that we didn't receive the products.
 */
export const RETRIEVE_PRODUCTS_FAILURE = "RETRIEVE_PRODUCTS_FAILURE";
