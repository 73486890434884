import {combineReducers} from "redux"
import {reducer as form} from "redux-form"
import entities from "../shared-components/store/entities/entitiesReducer"
import ids from "../shared-components/store/ids/idsReducer"
import {requestsReducerFactory} from "../shared-components/store/requests/requestsReducer"
import localStorage from "./localStorage/localStorageReducer"
import sessionStorage from "./sessionStorage/sessionStorageReducer"
import temporaries from "./temporaries/temporariesReducer"
import {DISCONNECT_USER} from "../shared-components/actions/sessionStorage/user/userActionTypes"
import notifications from "../shared-components/store/notifications/notificationsReducer"
import {EntityInfo} from "../shared-components/constants/entities"
import {RequestInfo} from "../shared-components/constants/requests"
import {values} from "lodash"

const requestReducers = {}

values(EntityInfo).forEach(k => (requestReducers[k.name] = requestsReducerFactory(k)))
values(RequestInfo).forEach(k => (requestReducers[k.name] = requestsReducerFactory(k)))

const requests = combineReducers(requestReducers)

/**
 * Combine reducers to get the store structure.
 */
const appReducers = (state = {}, action = {}) => {
    return combineReducers({
        ids,
        entities,
        localStorage,
        sessionStorage,
        requests,
        temporaries,
        form,
        notifications,
    })(state, action)
}

// We need a parent reducer to re-initalize it
// See post : https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992
const reducers = (state = {}, action = {}) => {
    // Clear the whole state after user disconnection
    // Except the isResponsive information
    if (action.type === DISCONNECT_USER) {
        state = {
            temporaries: {
                menu: {
                    isResponsive: state.temporaries.menu.isResponsive,
                },
            },
            localStorage: {
                cookies: state.localStorage.cookies,
            },
        }
    }

    return appReducers(state, action)
}

export default reducers
