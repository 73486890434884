export const VALIDATE_CONTROL_FILE_REQUEST = "VALIDATE_CONTROL_FILE_REQUEST";
export const VALIDATE_CONTROL_FILE_SUCCESS = "VALIDATE_CONTROL_FILE_SUCCESS";
export const VALIDATE_CONTROL_FILE_FAILURE = "VALIDATE_CONTROL_FILE_FAILURE";

export const GET_PREORDER_SESSIONS_REQUEST = "GET_PREORDER_SESSIONS_REQUEST";
export const GET_PREORDER_SESSIONS_SUCCESS = "GET_PREORDER_SESSIONS_SUCCESS";
export const GET_PREORDER_SESSIONS_FAILURE = "GET_PREORDER_SESSIONS_FAILURE";

export const GET_PREORDER_SESSIONS_MATRICULE_REQUEST = "GET_PREORDER_SESSIONS_MATRICULE_REQUEST";
export const GET_PREORDER_SESSIONS_MATRICULE_SUCCESS = "GET_PREORDER_SESSIONS_MATRICULE_SUCCESS";
export const GET_PREORDER_SESSIONS_MATRICULE_FAILURE = "GET_PREORDER_SESSIONS_MATRICULE_FAILURE";

export const GET_PREORDER_SESSION_STATISTICS_REQUEST = "GET_PREORDER_SESSION_STATISTICS_REQUEST";
export const GET_PREORDER_SESSION_STATISTICS_SUCCESS = "GET_PREORDER_SESSION_STATISTICS_SUCCESS";
export const GET_PREORDER_SESSION_STATISTICS_FAILURE = "GET_PREORDER_SESSION_STATISTICS_FAILURE";

export const GET_PREORDER_SESSION_ORDERS_REQUEST = "GET_PREORDER_SESSION_ORDERS_REQUEST";
export const GET_PREORDER_SESSION_ORDERS_SUCCESS = "GET_PREORDER_SESSION_ORDERS_SUCCESS";
export const GET_PREORDER_SESSION_ORDERS_FAILURE = "GET_PREORDER_SESSION_ORDERS_FAILURE";

export const GET_PREORDER_SESSION_LINES_REQUEST = "GET_PREORDER_SESSION_LINES_REQUEST";
export const GET_PREORDER_SESSION_LINES_SUCCESS = "GET_PREORDER_SESSION_LINES_SUCCESS";
export const GET_PREORDER_SESSION_LINES_FAILURE = "GET_PREORDER_SESSION_LINES_FAILURE";

export const SAVE_PREORDER_SESSION_LINES_SEARCH_PARAMS = "SAVE_PREORDER_SESSION_LINES_SEARCH_PARAMS";
export const CLEAR_PREORDER_SESSION_LINES_SEARCH_PARAMS = "CLEAR_PREORDER_SESSION_LINES_SEARCH_PARAMS";
export const CLEAR_PREORDER_SESSION_LINES_PAGINATION = "CLEAR_PREORDER_SESSION_LINES_PAGINATION";

export const PREORDER_SESSION_CLOSE_REQUEST = "PREORDER_SESSION_CLOSE_REQUEST";
export const PREORDER_SESSION_CLOSE_SUCCESS = "PREORDER_SESSION_CLOSE_SUCCESS";
export const PREORDER_SESSION_CLOSE_FAILURE = "PREORDER_SESSION_CLOSE_FAILURE";

export const PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_REQUEST =
    "PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_REQUEST";
export const PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_SUCCESS =
    "PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_SUCCESS";
export const PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_FAILURE =
    "PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_FAILURE";

export const PREORDER_SESSION_UPDATE_LINES_REQUEST = "PREORDER_SESSION_UPDATE_LINES_REQUEST";
export const PREORDER_SESSION_UPDATE_LINES_SUCCESS = "PREORDER_SESSION_UPDATE_LINES_SUCCESS";
export const PREORDER_SESSION_UPDATE_LINES_FAILURE = "PREORDER_SESSION_UPDATE_LINES_FAILURE";

export const PREORDER_SESSION_CREATE_ORDER_REQUEST = "PREORDER_SESSION_CREATE_ORDER_REQUEST";
export const PREORDER_SESSION_CREATE_ORDER_SUCCESS = "PREORDER_SESSION_CREATE_ORDER_SUCCESS";
export const PREORDER_SESSION_CREATE_ORDER_FAILURE = "PREORDER_SESSION_CREATE_ORDER_FAILURE";

export const PREORDER_SESSION_CREATE_UPDATE_REQUEST = "PREORDER_SESSION_CREATE_UPDATE_REQUEST";
export const PREORDER_SESSION_CREATE_UPDATE_SUCCESS = "PREORDER_SESSION_CREATE_UPDATE_SUCCESS";
export const PREORDER_SESSION_CREATE_UPDATE_FAILURE = "PREORDER_SESSION_CREATE_UPDATE_FAILURE";

export const PREORDER_SESSION_INFORMATION_REQUEST = "PREORDER_SESSION_INFORMATION_REQUEST";
export const PREORDER_SESSION_INFORMATION_SUCCESS = "PREORDER_SESSION_INFORMATION_SUCCESS";
export const PREORDER_SESSION_INFORMATION_FAILURE = "PREORDER_SESSION_INFORMATION_FAILURE";
