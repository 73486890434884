/**
 * Indicate that we launched a request to get scellius data.
 */
export const GET_SCELLIUS_DATA_REQUEST = "GET_SCELLIUS_DATA_REQUEST";
/**
 * Indicate that we received scellius data.
 */
export const GET_SCELLIUS_DATA_SUCCESS = "GET_SCELLIUS_DATA_SUCCESS";
/**
 * Indicate that we failed to receive scellius data.
 */
export const GET_SCELLIUS_DATA_FAILURE = "GET_SCELLIUS_DATA_FAILURE";
