import {
    RETRIEVE_STYLE_FAILURE, RETRIEVE_STYLE_SUCCESS
} from "../../../actions/localStorage/style/styleActionTypes";

/**
 * Style reducer.
 * Stock in store the new style when it is retrieved. Pass areCustomized boolean to true or
 * false in function of what back-end returned.
 */
const style = (state = {}, action = {}) => {
    switch (action.type) {
        case RETRIEVE_STYLE_SUCCESS:
            return {
                ...state,
                [action.response.numeroContrat]: {
                    isCustomized: action.response.customized,
                    styles: action.response.values,
                },
            };
        case RETRIEVE_STYLE_FAILURE:
            return !state[action.numeroContrat]
            || (state[action.numeroContrat] && state[action.numeroContrat].isCustomized === null) ?
                {
                    ...state,
                    [action.numeroContrat]: {
                        isCustomized: false,
                        styles: {},
                    },
                } : state;
        default:
            return state;
    }
};

export default style;

/**
 * Retrieve the style for a contract ID.
 */
export const getStyle = (state, contractId) =>
    state[contractId] ? state[contractId].styles : {};

/**
 * Indicate is the style is customized for a contract ID or not.
 */
export const isCustomized = (state, contractId) =>
    state[contractId] ? state[contractId].isCustomized : null;
