/**
 * Indicate that we launched a request to retrieve beneficiary declaration history
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_URSSAF_PAJE_DECLARATION_HISTORY_REQUEST =
    "RETRIEVE_BENEFICIARY_URSSAF_PAJE_DECLARATION_HISTORY_REQUEST"

/**
 * Indicate that the request to retrieve beneficiary declaration history  was a success.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_URSSAF_PAJE_DECLARATION_HISTORY_SUCCESS =
    "RETRIEVE_BENEFICIARY_URSSAF_PAJE_DECLARATION_HISTORY_SUCCESS"

/**
 * Indicate that the request to retrieve beneficiary declaration history failed.
 * @type {string}
 */
export const RETRIEVE_BENEFICIARY_URSSAF_PAJE_DECLARATION_HISTORY_FAILURE =
    "RETRIEVE_BENEFICIARY_URSSAF_PAJE_DECLARATION_HISTORY_FAILURE"
