import {CHANGE_ACTUAL_PAGE} from "../../../actions/temporaries/page/pageActionTypes"

/**
 * Page reducer.
 * Stock in store the actual page.
 */
const page = (state = {actualPage: "HOME", previousPage: undefined}, action = {}) => {
    switch (action.type) {
        case CHANGE_ACTUAL_PAGE:
            if (state.actualPage === action.actualPage) return state
            return {
                actualPage: action.actualPage,
                previousPage: state.actualPage
            }

        default:
            return state
    }
}

export default page

/**
 * Return the actual page.
 */
export const getActualPage = state => state.actualPage

/**
 * Return the previous page.
 */
export const getPreviousPage = state => state.actualPage
