/**
 * Indicate that we launched a request to retrieve the global style.
 * @type {string}
 */
export const RETRIEVE_STYLE_REQUEST = 'RETRIEVE_STYLE_REQUEST';

/**
 * Indicate that we retrieved successfully the global style.
 * @type {string}
 */
export const RETRIEVE_STYLE_SUCCESS = 'RETRIEVE_STYLE_SUCCESS';

/**
 * Indicate that we didn't receive the global style.
 * @type {string}
 */
export const RETRIEVE_STYLE_FAILURE = 'RETRIEVE_STYLE_FAILURE';
