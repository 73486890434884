/**
 * Indicate that we launched a request to verify a marketing operation.
 */
export const VERIFY_MARKETING_OPERATION_REQUEST = "VERIFY_MARKETING_OPERATION_REQUEST";
/**
 * Indicate that we verified successfully the marketing operation.
 */
export const VERIFY_MARKETING_OPERATION_SUCCESS = "VERIFY_MARKETING_OPERATION_SUCCESS";
/**
 * Indicate that we couldn't verify the marketing operation.
 */
export const VERIFY_MARKETING_OPERATION_FAILURE = "VERIFY_MARKETING_OPERATION_FAILURE";
