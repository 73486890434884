import React, {Component} from "react"
import PropTypes from "prop-types"
import MenuItem from "./menuItem"

/**
 * Container for an item of the menu.
 * Handle hovering.
 */
class MenuItemContainer extends Component {
    constructor() {
        super()

        this.state = {
            isHovering: false,
        }
    }

    changeHover = isHovering => {
        this.setState({
            isHovering,
        })
    }

    onClick = () => {
        if (this.props.onClick) {
            this.props.onClick()
            this.changeHover(false)
        }
    }

    displayNotification() {
        if (!!this.props.displayNotificationFunction && !!this.props.features) {
            return this.props.displayNotificationFunction(this.props.features)
        }
        return false
    }

    render() {
        return (
            <div
                className={this.props.className ? "menu-item-container " + this.props.className : "menu-item-container"}
            >
                <MenuItem
                    {...this.props}
                    displayNotification={this.displayNotification()}
                    isHovering={this.state.isHovering}
                    onClick={this.onClick}
                    onMouseEnter={() => this.changeHover(true)}
                    onMouseLeave={() => this.changeHover(false)}
                />
            </div>
        )
    }
}

MenuItemContainer.propTypes = {
    onClick: PropTypes.func,
    // Is this item active ?
    active: PropTypes.bool,
    displayNotificationFunction: PropTypes.func,
}

export default MenuItemContainer
