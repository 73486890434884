/**
 * Indicate that we start a create order deposit request
 */
export const CREATE_ORDER_DEPOSIT_REQUEST = "CREATE_ORDER_DEPOSIT_REQUEST";
/**
 * Indicate that the create order deposit request was successful
 */
export const CREATE_ORDER_DEPOSIT_SUCCESS = "CREATE_ORDER_DEPOSIT_SUCCESS";
/**
 * Indicate that the create order deposit has failed
 */
export const CREATE_ORDER_DEPOSIT_FAILURE = "CREATE_ORDER_DEPOSIT_FAILURE";
/**
 * Indicate that we start a retrieve order deposits request
 */
export const RETRIEVE_ORDER_DEPOSITS_REQUEST = "RETRIEVE_ORDER_DEPOSITS_REQUEST";
/**
 * Indicate that the retrieve order deposits request was successful
 */
export const RETRIEVE_ORDER_DEPOSITS_SUCCESS = "RETRIEVE_ORDER_DEPOSITS_SUCCESS";
/**
 * Indicate that the retrieve order deposits has failed
 */
export const RETRIEVE_ORDER_DEPOSITS_FAILURE = "RETRIEVE_ORDER_DEPOSITS_FAILURE";

/**
 * Indicate to clear the order deposit request state
 */
export const CLEAR_ORDER_DEPOSIT_REQUEST = "CLEAR_ORDER_DEPOSIT_REQUEST";
