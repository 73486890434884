/**
 * Global stylesheet.
 * It is structured by section. One section can be used in components in className property.
 * For that, it is needed to get the sheet via the context into the component which needs it.
 * Then, sections will be available into classes props.
 */
const sheet = {
    // COLORS.
    // Text color to primary color.
    textPrimaryColor: {
        color: theme => theme.primaryColor,
        transition: "color 0.2s ease-in",
    },
    // Background color to primary color.
    backgroundPrimaryColor: {
        backgroundColor: theme => theme.primaryColor,
        transition: "background-color 0.2s ease-in",
    },
    // Text color to secondary color.
    textSecondaryColor: {
        color: theme => theme.secondaryColor,
        transition: "color 0.2s ease-in",
    },
    // Background color to secondary color.
    backgroundSecondaryColor: {
        backgroundColor: theme => theme.secondaryColor,
        transition: "background-color 0.2s ease-in",
    },
    // Border color to secondary color.
    borderSecondaryColor: {
        borderColor: theme => theme.secondaryColor,
    },
    // Text color to tertiary color.
    textTertiaryColor: {
        color: theme => theme.tertiaryColor,
    },
    // Background color to tertiary color.
    backgroundTertiaryColor: {
        backgroundColor: theme => theme.tertiaryColor,
    },
    // Text color to success color.
    textSuccessColor: {
        color: theme => theme.successColor,
    },
    // Background color to success color.
    backgroundSuccessColor: {
        backgroundColor: theme => theme.successColor,
    },
    // Text color to warning color.
    textWarningColor: {
        color: theme => theme.warningColor,
    },
    // Background color to warning color.
    backgroundWarningColor: {
        backgroundColor: theme => theme.warningColor,
    },
    // Text color to danger color.
    textDangerColor: {
        color: theme => theme.dangerColor,
    },
    // Background color to danger color.
    backgroundDangerColor: {
        backgroundColor: theme => theme.dangerColor,
    },

    // Background color to button primary color.
    buttonPrimaryColor: {
        backgroundColor: theme => theme.primaryColor,
    },

    // Background color to button secondary color.
    buttonSecondaryColor: {
        backgroundColor: theme => theme.secondaryColor,
    },

    // Border Top Colored segment with Primary color
    borderTopColoredSegment: {
        borderTop: theme => [["2px", "solid", theme.primaryColor], "!important"],
    },
}

export default sheet
