/**
 * Indicate that we start a get transfers request
 */
export const GET_TRANSFERS_OF_INTERVENANT_REQUEST = "GET_TRANSFERS_OF_INTERVENANT_REQUEST"
/**
 * Indicate that the get of transfers request was successful
 */
export const GET_TRANSFERS_OF_INTERVENANT_SUCCESS = "GET_TRANSFERS_OF_INTERVENANT_SUCCESS"
/**
 * Indicate that the get of transfers request has failed
 */
export const GET_TRANSFERS_OF_INTERVENANT_FAILURE = "GET_TRANSFERS_OF_INTERVENANT_FAILURE"

/**
 * Indicate that we start a get transfer details request
 */
export const GET_TRANSFER_DETAILS_OF_INTERVENANT_REQUEST = "GET_TRANSFER_DETAILS_OF_INTERVENANT_REQUEST"
/**
 * Indicate that the get transfer details request was successful
 */
export const GET_TRANSFER_DETAILS_OF_INTERVENANT_SUCCESS = "GET_TRANSFER_DETAILS_OF_INTERVENANT_SUCCESS"
/**
 * Indicate that the get of transfer details has failed
 */
export const GET_TRANSFER_DETAILS_OF_INTERVENANT_FAILURE = "GET_TRANSFER_DETAILS_OF_INTERVENANT_FAILURE"
