/**
 * Durée minimum de saisie du formulaire d'inscription d'un intervenant
 * Utilisé pour vérifier que l'utilisateur n'est pas un robot
 */
export const INTERVENANT_INSCRIPTION_MINIMUM_DELAY = 3000

/**
 * Durée minimum de saisie du siret avant inscription sur l'espace financeur
 * Utilisé pour vérifier que l'utilisateur n'est pas un robot
 */
export const SIRET_VALIDATION_MINIMUM_DELAY = 1000

/**
 * Durée minimum de saisie d'un formulaire de login
 * Utilisé pour vérifier que l'utilisateur n'est pas un robot
 */
export const LOGIN_MINIMUM_DELAY = 1500
