import {SAVE_COOKIES_CHOICES} from "./cookiesActionTypes"

/**
 * Thunk to save a dynamic configuration key/value pair.
 */
export const saveCookiesChoices = values => dispatch => {
    dispatch({
        type: SAVE_COOKIES_CHOICES,
        values,
    })
}
