/**
 * Tell the application we start a download request from the filer
 */
export const FILER_DOWNLOAD_REQUEST = "FILER_DOWNLOAD_REQUEST"
/**
 * Tell the application we successfully download a file from the filer
 */
export const FILER_DOWNLOAD_SUCCESS = "FILER_DOWNLOAD_SUCCESS"
/**
 * Tell the application we failed to download a file from the filer
 */
export const FILER_DOWNLOAD_FAILURE = "FILER_DOWNLOAD_FAILURE"
/**
 * Tell the application to clear the filer download request state
 */
export const CLEAR_FILER_DOWNLOAD_REQUEST = "CLEAR_FILER_DOWNLOAD_REQUEST"
