import React from "react"
import PropTypes from "prop-types"
import "./hamburgerButton.scss"
import {Icon} from "semantic-ui-react"

/**
 * Hamburger button.
 * Launch an action on click.
 */
const HamburgerButton = ({onClick, displayNotification}, {sheet}) => (
    // Button's background color is the primary color.
    <div className={sheet.classes.backgroundPrimaryColor + " hamburger-button-component"} onClick={onClick}>
        <Icon.Group>
            <Icon className="hamburger-button-component-icon" name="bars" />
            {displayNotification && <Icon className={sheet.classes.textDangerColor} corner="bottom left" name="bell" />}
        </Icon.Group>
    </div>
)

HamburgerButton.contextTypes = {
    sheet: PropTypes.object,
}

HamburgerButton.propTypes = {
    /**
     * Called when clicking on the button.
     */
    onClick: PropTypes.func.isRequired,
}

export default HamburgerButton
