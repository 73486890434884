import {
    RETRIEVE_TEXTS_FAILURE, RETRIEVE_TEXTS_SUCCESS
} from "../../../actions/localStorage/texts/textsActionTypes";

/**
 * Style reducer.
 * Stock in store the new texts when they are retrieved. Pass areCustomized boolean to true or
 * false in function of what back-end returned.
 */
const texts = (state = {}, action = {}) => {
    switch (action.type) {
        case RETRIEVE_TEXTS_SUCCESS:
            return {
                ...state,
                [action.response.numeroContrat]: {
                    areCustomized: action.response.customized,
                    texts: action.response.values,
                },
            };
        case RETRIEVE_TEXTS_FAILURE:
            return !state[action.numeroContrat]
            || (state[action.numeroContrat] && state[action.numeroContrat].areCustomized === null) ?
                {
                    ...state,
                    [action.numeroContrat]: {
                        areCustomized: false,
                        texts: {},
                    },
                } : state;
        default:
            return state;
    }
};

export default texts;

/**
 * Retrieve the text for a contract ID.
 */
export const getTexts = (state, contractId) =>
    state[contractId] ? state[contractId].texts : {};

/**
 * Indicate if the texts are customized for a contract ID.
 */
export const areCustomized = (state, contractId) =>
    state[contractId] ? state[contractId].areCustomized : null;
