/* eslint-disable */
import PropTypes from "prop-types"
import React, {Component} from "react"
import {ThemeProvider} from "react-jss"
import {connect} from "react-redux"
import {defaultTexts, updateGlobalTexts} from "../../../styles/globalText"
import {retrieveStyle} from "../../actions/localStorage/style/styleActions"
import {retrieveTexts} from "../../actions/localStorage/texts/textsAction"
import defaultStyle from "../../constants/defaultStyle"
import {DYN_CONF_KEY_CONTRACT_ID} from "../../constants/dynamicConfigurationKeys"
import SheetProvider from "../SheetProvider/sheetProvider"
import StyleLoader from "./styleLoader"

/**
 * Try to retrieve the customized style from the back-end. If it retrieved it, update the
 * global stylesheet.
 */
class StyleLoaderContainer extends Component {
    constructor() {
        super()

        this.state = {
            theme: defaultStyle,
        }
    }

    static propTypes = {
        customStyle: PropTypes.object,

        /**
         * Pass an object of texts to override defaults if needed
         */
        overrideDefaultTexts: PropTypes.object,
        /**
         * Should we override the default texts
         * Default : false
         */
        shouldOverrideDefaultTexts: PropTypes.bool,
    }

    static defaultProps = {
        shouldOverrideDefaultTexts: false,
        customStyle: defaultStyle,
    }

    componentDidMount() {
        // Update one time to take care of local storage values.
        this.setState({theme: {...this.state.theme, ...this.props.customStyle}})

        if (this.props.contractId) {
            this.setState({theme: {...this.state.theme, ...this.props.style}})
            updateGlobalTexts(this.props.styledTexts)

            // Ask for style and texts if there is a contract ID.
            this.props.retrieveStyle(this.props.espaceType, this.props.contractId)
            this.props.retrieveTexts(this.props.espaceType, this.props.contractId)
        }
    }

    /**
     * When the contractId changed, it resets the texts and styles and reapply contract texts and styles
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const changedContract = nextProps.contractId && this.props.contractId !== nextProps.contractId

        // If the previous contract had customized style but not the next, reset the style
        if ((!nextProps.isCustomizedStyle && this.props.isCustomizedStyle) || changedContract) {
            this.setState({
                theme: defaultStyle,
            })
        }
        // Same for the texts
        if ((!nextProps.isCustomizedTexts && this.props.isCustomizedTexts) || changedContract) {
            updateGlobalTexts(defaultTexts)
        }
        // Also reset to default texts if shouldOverride become false
        if ((!nextProps.shouldOverrideDefaultTexts && this.props.shouldOverrideDefaultTexts) || changedContract) {
            updateGlobalTexts(defaultTexts)
        }

        // Apply customized styles
        if (nextProps.isCustomizedStyle) {
            this.setState({
                theme: {...this.state.theme, ...nextProps.style},
            })
        }

        // Default texts overriding
        if (nextProps.shouldOverrideDefaultTexts) {
            // Apply customized texts
            updateGlobalTexts(nextProps.overrideDefaultTexts)
        }

        // Apply customized texts
        if (nextProps.isCustomizedTexts) {
            updateGlobalTexts(nextProps.styledTexts)
        }

        // If the contractId has changed
        if (changedContract) {
            // Ask for style and texts if there is a contract ID.
            this.props.retrieveStyle(this.props.espaceType, nextProps.contractId)
            this.props.retrieveTexts(this.props.espaceType, nextProps.contractId)
        }
    }

    render() {
        return (
            <StyleLoader
                isCustomizedStyle={this.props.isCustomizedStyle}
                isCustomizedTexts={this.props.isCustomizedTexts}
            >
                <ThemeProvider theme={this.state.theme}>
                    <SheetProvider>{this.props.children}</SheetProvider>
                </ThemeProvider>
            </StyleLoader>
        )
    }
}

StyleLoaderContainer.propTypes = {
    espaceType: PropTypes.string.isRequired,
    style: PropTypes.object.isRequired,
    isCustomizedStyle: PropTypes.bool,

    styledTexts: PropTypes.object.isRequired,
    isCustomizedTexts: PropTypes.bool,

    retrieveStyle: PropTypes.func.isRequired,
    contractId: PropTypes.string,
}

const mapStateToProps = (state, ownProps) => {
    const contractId = ownProps.getDynamicConfigurationValue(state, DYN_CONF_KEY_CONTRACT_ID)

    return {
        style: contractId ? ownProps.getStyle(state, contractId) : {},
        isCustomizedStyle: contractId ? ownProps.isCustomizedStyle(state, contractId) : false,

        styledTexts: contractId ? ownProps.getTexts(state, contractId) : {},
        isCustomizedTexts: contractId ? ownProps.isCustomizedTexts(state, contractId) : false,

        contractId,

        shouldOverrideDefaultTexts: ownProps.shouldOverrideDefaultTexts
            ? ownProps.shouldOverrideDefaultTexts(state)
            : false,
    }
}

StyleLoaderContainer = connect(mapStateToProps, {retrieveStyle, retrieveTexts})(StyleLoaderContainer)

export default StyleLoaderContainer
