import axios from "axios"
import {REQUEST, REQUEST_SUCCESS, REQUEST_FAILURE} from "../store/requests/requestsReducer"
import {getErrorMessage} from "../services/api"

// TODO to reducer boilerplate
// Remove RequestInfo
// Split RequestsKeys
// Create API object
// Better endpoints management

/**
 * A generic request
 * @param {String} requestKeyName the key to identify the request
 */
const genericRequest = requestKeyName => ({
    type: REQUEST,
    requestKeyName,
})

/**
 * A generic request success
 * @param {Object} payload
 * @param {String} requestKeyName the key to identify the request
 */
const genericRequestSuccess = (payload, requestKeyName) => ({
    type: REQUEST_SUCCESS,
    requestKeyName,
    payload,
})

/**
 * A generic request failure
 * @param {String} requestKeyName the key to identify the request
 * @param {String} message the response error message
 */
const genericRequestFailure = (requestKeyName, message) => ({
    type: REQUEST_FAILURE,
    requestKeyName,
    message,
})

/**
 * A generic function to handle API response error
 * @param {*} dispatch the redux dispatch
 */
const genericThunkError = (dispatch, requestKeyName) => error => {
    const message = getErrorMessage(error)
    dispatch(genericRequestFailure(requestKeyName, message))
    return Promise.reject(message)
}

/**
 * A generic function to create thunk
 * This function aims to reducer boilerplate code
 * @param {Object} body the request body
 * @param {String} httpMethod the request method
 * @param {Function} dispatch the redux store dispatch function
 * @param {String} url the targeted URL
 * @param {Object} params the request parameters
 * @param {String} requestKeyName the key to identify the request
 * @param {Function} payloadHandler a function to handle the response and return a payload
 */
export const genericThunk = (body, httpMethod, dispatch, url, params, requestKeyName, payloadHandler, responseType) => {
    dispatch(genericRequest(requestKeyName))

    return axios({
        method: httpMethod,
        url,
        data: body,
        params,
        responseType,
    })
        .then(response => {
            const payload = payloadHandler ? payloadHandler(response) : undefined
            dispatch(genericRequestSuccess(payload, requestKeyName))
            return Promise.resolve(payload)
        })
        .catch(genericThunkError(dispatch, requestKeyName))
}
