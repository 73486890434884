import React, {Component} from "react"
import PropTypes from "prop-types"
import MobileMenu from "./mobileMenu"

/**
 * Mobile menu container.
 * Handle state of the menu.
 */
class MobileMenuContainer extends Component {
    constructor() {
        super()

        this.state = {
            isResponsiveMenuOpened: false,
        }

        this.toggleResponsiveMenu = this.toggleResponsiveMenu.bind(this)
        this.onChoiceClick = this.onChoiceClick.bind(this)
        this.onImageClick = this.onImageClick.bind(this)
    }

    /**
     * Open/close the menu.
     */
    toggleResponsiveMenu() {
        this.setState({
            isResponsiveMenuOpened: !this.state.isResponsiveMenuOpened,
        })
    }

    onChoiceClick(onClick) {
        if (onClick) {
            onClick()
            this.setState({
                isResponsiveMenuOpened: false,
            })
        }
    }

    onImageClick() {
        this.props.redirectToHome()
        this.setState({
            isResponsiveMenuOpened: false,
        })
    }

    displayNotification() {
        const features = this.props.content.map(c => {
            if (c.type === "ITEM") {
                return c.feature
            } else {
                return c.choices.map(c2 => {
                    if (c2.type === "ITEM") {
                        return c2.feature
                    } else {
                        return c2.choices.map(c3 => c3.feature)
                    }
                })
            }
        })
        if (!!this.props.displayNotificationFunction && !!features) {
            return this.props.displayNotificationFunction(features)
        }
        return false
    }

    render() {
        // Redefine content.
        const newContent = this.props.content.map(c => {
            if (c.type === "ITEM") {
                return {
                    ...c,
                    onClick: () => this.onChoiceClick(c.onClick),
                }
            } else {
                return {
                    ...c,
                    choices: c.choices.map(c2 => {
                        if (c2.type === "ITEM") {
                            return {
                                ...c2,
                                onClick: () => this.onChoiceClick(c2.onClick),
                            }
                        } else {
                            return {
                                ...c2,
                                choices: c2.choices.map(c3 => {
                                    return {
                                        ...c3,
                                        onClick: () => this.onChoiceClick(c3.onClick),
                                    }
                                }),
                            }
                        }
                    }),
                }
            }
        })

        return (
            <div className="mobile-menu-container">
                <MobileMenu
                    {...this.props}
                    content={newContent}
                    displayNotification={this.displayNotification()}
                    isResponsiveMenuOpened={this.state.isResponsiveMenuOpened}
                    onChoiceClick={this.onChoiceClick}
                    onImageClick={this.onImageClick}
                    toggleResponsiveMenu={this.toggleResponsiveMenu}
                />
            </div>
        )
    }
}

MobileMenuContainer.propTypes = {
    // The content of the menu.
    content: PropTypes.arrayOf(
        PropTypes.oneOfType([
            // An item.
            PropTypes.shape({
                type: PropTypes.oneOf(["ITEM"]),
                text: PropTypes.string.isRequired,
                onClick: PropTypes.func.isRequired,
                active: PropTypes.bool,
            }),
            // A dropdown list.
            PropTypes.shape({
                type: PropTypes.oneOf(["DROPDOWN"]),
                text: PropTypes.string.isRequired,
                // Panel alignment.
                alignment: PropTypes.oneOf(["LEFT", "RIGHT"]),
                choices: PropTypes.arrayOf(
                    PropTypes.oneOfType([
                        // A dropdown item.
                        PropTypes.shape({
                            type: PropTypes.oneOf(["ITEM"]),
                            text: PropTypes.string.isRequired,
                            onClick: PropTypes.func.isRequired,
                            active: PropTypes.bool,
                        }),
                        // A dropdown text.
                        PropTypes.shape({
                            type: PropTypes.oneOf(["DROPDOWN"]),
                            text: PropTypes.string.isRequired,
                            choices: PropTypes.arrayOf(
                                PropTypes.shape({
                                    type: PropTypes.oneOf(["ITEM"]),
                                    text: PropTypes.string.isRequired,
                                    onClick: PropTypes.func.isRequired,
                                    active: PropTypes.bool,
                                }),
                            ),
                        }),
                    ]),
                ).isRequired,
            }),
        ]),
    ).isRequired,

    // The source of the logo.
    logo: PropTypes.string.isRequired,
    onConnectClick: PropTypes.func.isRequired,
    onDisconnectClick: PropTypes.func.isRequired,
    onProfileClick: PropTypes.func,
    redirectToHome: PropTypes.func.isRequired,

    userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default MobileMenuContainer
