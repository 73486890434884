import {TradeFeeTypes} from "../shared-components/constants/tradeFeeType"
import {SupportTypes} from "../shared-components/constants/supportTypes"
import {TradeTypes} from "../shared-components/constants/tradeTypes"
import {sharedGlobalTexts} from "../shared-components/styles/globalTexts"

export const defaultTexts = {
    // Insert shared global texts
    ...sharedGlobalTexts,

    // Images
    logo: "",
    homeHeaderImage: "",
    homeFooterImage: "",

    // HOME PAGE
    homeTitle: "Prolongez la durée de vie de vos CESU Domiserve !",
    homeInPeriodParagraph1: "Bienvenue sur le service Domi-Echange ! ",
    homeInPeriodParagraph2: "Quel type de CESU souhaitez-vous échanger ?",
    homeOutsidePeriodParagraph1:
        "Le service Domi-Echange est accessible uniquement durant le mois de Février de chaque année.",
    homeOutsidePeriodParagraph2: "Rendez-vous lors de la prochaine période d'échange.",

    // LOGIN PAGE
    loginParagraph: "Connectez-vous à votre espace d'échange de titres CESU Domiserve",
    loginCESU: "Nous vous rappelons que le Code CESU est constitué de 8 chiffres.",
    loginCESUHeel: "Il figure sur le talon de chaque titre CESU Domiserve.",
    loginCodeCESUPopup: "Constitué de 8 chiffres",

    // PAYMENT PAGE
    paymentFees: tradeFees => {
        if (tradeFees && tradeFees.feeType === TradeFeeTypes.FORFEIT) {
            return "Les frais pour l'échange de votre chéquier s'élèvent à " + tradeFees.totalFees + " € TTC."
        } else if (tradeFees && tradeFees.feeType === TradeFeeTypes.PROPORTIONAL) {
            return (
                "Les frais pour l'échange de votre chéquier s'élèvent à un montant égal à " +
                tradeFees.feeRate +
                " % de la valeur total des CESU échangés soit " +
                tradeFees.totalFees +
                " € TTC."
            )
        } else {
            return "Aucun frais à payer"
        }
    },
    paymentTradingFeesError: "Erreur lors du calcul des frais.",

    // TITLES PAGE
    titlesAdditionParagraph1:
        "Veuillez saisir le premier et le dernier numéro de titre CESU que vous souhaitez échanger.",
    titlesAdditionParagraph2:
        "Si vous n'avez qu'un seul CESU à échanger, indiquez seulement son numéro dans la première case.",
    titlesAdditionParagraph3: "Enfin cliquez sur 'Ajouter un titre CESU' pour procéder à la validation de vos CESU.",
    titlesTradeTypeParagraph1:
        'A l\'occasion de l\'échange, vous pouvez choisir de transformer vos CESU "papier" (chéquier) en CESU "électronique" (compte), pour profiter des avantages de ce mode de paiement simple, rapide et fiable. Vous bénéficiez par ailleurs de frais d\'échanges réduits.',
    titlesTradeTypeParagraph2:
        "Le CESU « électronique », appelé compte CESU Domiserve, est un compte en ligne sécurisé, disponible 24h/24, 7j/7. Il vous permettra de régler votre intervenant à domicile, au centime d'euro près, par virement bancaire. Enfin, il est facilement rechargeable par carte bancaire si le solde de votre compte est inférieur au règlement envisagé.",

    titlesSecretCodeError: "Veuillez renseigner toutes les cases à gratter",
    titlesNoTitlesError: "Veuillez ajouter au moins un titre",

    // CONFIRMATION PAGE
    confirmationIntroduction: (tradeType, oldMillesime, millesime, totalAmount, email, supportType) => {
        let text = ""
        if (tradeType === TradeTypes.TITLE.key) {
            text =
                "Votre demande d'échange de chéquier CESU " +
                oldMillesime +
                " en chéquier CESU " +
                millesime +
                " d'une valeur totale de " +
                totalAmount +
                (supportType === SupportTypes.BENEFICIARY
                    ? " € est maintenant terminée. Un email de confirmation avec votre facture, vient de vous être adressé à l'adresse "
                    : " € est maintenant terminée. Un email de confirmation vient de vous être adressé à l'adresse ") +
                email +
                "."
        }
        if (tradeType === TradeTypes.ACCOUNT.key) {
            text =
                "Votre demande d'échange de chéquier CESU " +
                oldMillesime +
                " en compte CESU " +
                millesime +
                " d'une valeur totale de " +
                totalAmount +
                (supportType === SupportTypes.BENEFICIARY
                    ? " € est maintenant terminée. Un email de confirmation avec votre facture, vient de vous être envoyé à l'adresse "
                    : " € est maintenant terminée. Un email de confirmation vient de vous être adressé à l'adresse ") +
                email +
                "."
        }
        return text
    },
    confirmationParagraph: (supportType, tradeType, millesime) => {
        let text = ""
        if (supportType === SupportTypes.BENEFICIARY && tradeType === TradeTypes.TITLE.key) {
            text =
                "Vous recevrez votre nouveau chéquier CESU par courrier à l'adresse indiquée dans un délai de 10 jours."
        }
        if (supportType === SupportTypes.BENEFICIARY && tradeType === TradeTypes.ACCOUNT.key) {
            text =
                "Vous recevrez un mail confirmant l'ouverture de votre compte CESU " +
                millesime +
                " dans un délai de 3 jours ouvrés."
        }
        if (supportType === SupportTypes.CUSTOMER && tradeType === TradeTypes.TITLE.key) {
            text =
                "Vous recevrez votre nouveau chéquier CESU courant Mars, par courrier, après validation de votre financeur."
        }
        if (supportType === SupportTypes.CUSTOMER && tradeType === TradeTypes.ACCOUNT.key) {
            text =
                "Vous recevrez un mail confirmant l'ouverture de votre compte CESU " +
                millesime +
                " après validation de votre demande par votre financeur."
        }
        return text
    },
    confirmationConditions:
        "Conformément aux conditions générales d'échange, nous vous rappelons que vous devez procéder à la destruction des CESU dès la réalisation de l'opération d'échange.",
    confirmationGreetings: "Nous vous remercions de votre confiance.",
    confirmationFooter1: "L'équipe relation client Domiserve.",
    confirmationFooter2: "Service Echange",
    confirmationFooter3: "01 78 16 13 32",

    // NOT FOUND PAGE
    // Texts
    notFoundText: "Page introuvable.",

    // UNAUTHORIZED PAGE
    // Texts
    unauthorizedText: "Oups, vous vous êtes perdu ?",

    // ERROR PAGE
    technicalErrorText: "Il semblerait qu'une erreur technique se soit produite.",
}

let texts = {...defaultTexts}

export function updateGlobalTexts(newTexts) {
    texts = Object.assign(texts, newTexts)
}

export default texts
