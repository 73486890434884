const tokenRegexp = /^(Bearer )([A-Za-z0-9-_=]+)\.([A-Za-z0-9-_=]+)\.?([A-Za-z0-9-_.+/=]*)$/

const decodeToken = token => {
    const match = token.match(tokenRegexp)
    return JSON.parse(atob(match[3]))
}

export const getPermissionsFromToken = token => {
    const decodedToken = decodeToken(token)
    return !!decodedToken.permissions ? decodedToken.permissions : []
}
