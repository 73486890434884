/**
 * Get the reports tree of a contract.
 * @type {string}
 */
export const GET_REPORTS_TREE_REQUEST = "GET_REPORTS_TREE_REQUEST";
export const GET_REPORTS_TREE_SUCCESS = "GET_REPORTS_TREE_SUCCESS";
export const GET_REPORTS_TREE_FAILURE = "GET_REPORTS_TREE_FAILURE";

/**
 * Save a new file in a reports tree.
 * @type {string}
 */
export const SAVE_NEW_REPORTS_FILE_REQUEST = "SAVE_NEW_REPORTS_FILE_REQUEST";
export const SAVE_NEW_REPORTS_FILE_SUCCESS = "SAVE_NEW_REPORTS_FILE_SUCCESS";
export const SAVE_NEW_REPORTS_FILE_FAILURE = "SAVE_NEW_REPORTS_FILE_FAILURE";

/**
 * Save a new folder in a reports tree.
 * @type {string}
 */
export const SAVE_NEW_REPORTS_FOLDER_REQUEST = "SAVE_NEW_REPORTS_FOLDER_REQUEST";
export const SAVE_NEW_REPORTS_FOLDER_SUCCESS = "SAVE_NEW_REPORTS_FOLDER_SUCCESS";
export const SAVE_NEW_REPORTS_FOLDER_FAILURE = "SAVE_NEW_REPORTS_FOLDER_FAILURE";

/**
 * Delete a file from a reports tree.
 * @type {string}
 */
export const DELETE_REPORTS_FILE_REQUEST = "DELETE_REPORTS_FILE_REQUEST";
export const DELETE_REPORTS_FILE_SUCCESS = "DELETE_REPORTS_FILE_SUCCESS";
export const DELETE_REPORTS_FILE_FAILURE = "DELETE_REPORTS_FILE_FAILURE";

/**
 * Delete a folder from a reports tree.
 * @type {string}
 */
export const DELETE_REPORTS_FOLDER_REQUEST = "DELETE_REPORTS_FOLDER_REQUEST";
export const DELETE_REPORTS_FOLDER_SUCCESS = "DELETE_REPORTS_FOLDER_SUCCESS";
export const DELETE_REPORTS_FOLDER_FAILURE = "DELETE_REPORTS_FOLDER_FAILURE";
