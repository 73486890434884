import {PropTypes} from "prop-types"
import React, {Component} from "react"
import ErrorBoundary from "./errorBoundary"

class ErrorBoundaryContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {error: null, errorInfo: null}
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        })
        // You can also log error messages to an error reporting service here
    }

    /**
     * Redirect to home page
     */
    redirectToHomePage = () => {
        window.location = this.props.routeToRedirect
    }

    render() {
        // If we catched an error, display ErrorBoundary
        if (this.state.errorInfo) {
            return <ErrorBoundary redirectToHomePage={this.redirectToHomePage} />
        }

        // Normally, just render children
        return this.props.children
    }
}

ErrorBoundaryContainer.propTypes = {
    children: PropTypes.any.isRequired,
    routeToRedirect: PropTypes.string.isRequired,
}

export default ErrorBoundaryContainer
