import React from "react"
import {PagesArchitecture} from "../constants/page/pagesArchitecture"
import {Page} from "../constants/page/pages"
import {AREA_TYPE} from "../constants/areaType"
import history from "../history"
import {TRADE_AREA_URL} from "../shared-components/constants/links"

// ASYNC LAYOUTS
const BreadcrumbLayout = React.lazy(() => import("./breadcrumbLayout").catch(() => window.location.reload()))

export const AsyncBreadcrumbLayout = props => (
    <BreadcrumbLayout isUserConnected={props.isUserConnected} pageName={props.actualPage} />
)

const TradeLayout = React.lazy(() => import("./tradeLayout").catch(() => window.location.reload()))

export const AsyncTradeLayout = () => <TradeLayout />

// ASYNC SCENES
const UnauthorizedScene = React.lazy(() =>
    import("../shared-components/scenes/Unauthorized/unauthorizedContainer").catch(() => window.location.reload()),
)

export const AsyncUnauthorizedScene = () => (
    <UnauthorizedScene
        onHomeClick={() => history.push(PagesArchitecture[Page.HOME].route)}
        unauthorizedPage={Page.UNAUTHORIZED}
    />
)

const NotFoundScene = React.lazy(() =>
    import("../shared-components/scenes/NotFound/notFoundContainer").catch(() => window.location.reload()),
)

export const AsyncNotFoundScene = () => (
    <NotFoundScene notFoundPage={Page.NOT_FOUND} onHomeClick={() => history.push(PagesArchitecture[Page.HOME].route)} />
)

const TechnicalErrorScene = React.lazy(() =>
    import("../shared-components/components/ErrorBoundary/errorBoundary").catch(() => window.location.reload()),
)

export const AsyncTechnicalErrorScene = () => (
    <TechnicalErrorScene
        page={Page.TECHNICAL_ERROR}
        redirectToHomePage={() => history.push(PagesArchitecture[Page.HOME].route)}
    />
)

const LegalNoticeScene = React.lazy(() =>
    import("../shared-components/scenes/LegalNotice/legalNoticeContainer").catch(() => window.location.reload()),
)

export const AsyncLegalNoticeScene = () => (
    <LegalNoticeScene
        areaType={AREA_TYPE}
        legalNoticePage={Page.LEGAL_NOTICE}
        urlDomiserve={TRADE_AREA_URL}
        withCGS={false}
    />
)

const HomeScene = React.lazy(() => import("../scenes/Home/homeContainer").catch(() => window.location.reload()))

export const AsyncHomeScene = () => <HomeScene />

const LoginScene = React.lazy(() => import("../scenes/Login/loginContainer").catch(() => window.location.reload()))

export const AsyncLoginScene = () => <LoginScene />

const PaymentScene = React.lazy(() =>
    import("../scenes/Payment/paymentContainer").catch(() => window.location.reload()),
)

export const AsyncPaymentScene = () => <PaymentScene />

const TitlesScene = React.lazy(() => import("../scenes/Titles/titlesContainer").catch(() => window.location.reload()))

export const AsyncTitlesScene = () => <TitlesScene />

const DeliveryScene = React.lazy(() =>
    import("../scenes/Delivery/deliveryContainer").catch(() => window.location.reload()),
)

export const AsyncDeliveryScene = () => <DeliveryScene />

const ConfirmationScene = React.lazy(() =>
    import("../scenes/Confirmation/confirmationContainer").catch(() => window.location.reload()),
)

export const AsyncConfirmationScene = () => <ConfirmationScene />
