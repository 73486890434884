import {combineReducers} from "redux"
import town, * as fromTown from "./town/townReducer"

/**
 * Search reducer.
 * Contains all results of searches.
 */
const search = combineReducers({
    town: town(),
})

export default search

// TOWN OPERATIONS
/**
 * Return the results of the town search found in the state.
 */
export const getTownResults = (state, name) => fromTown.getResults(state.town, name)
/**
 * Return the query of the town search found in the state.
 */
export const getTownQuery = (state, name) => fromTown.getQuery(state.town, name)
/**
 * Retourne le "isFetching" de la requête trouvé dans le state.
 */
export const isTownFetching = (state, name) => fromTown.isFetching(state.town, name)
