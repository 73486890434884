import dayjs from "dayjs"
import "dayjs/locale/fr"
import customParseFormat from "dayjs/plugin/customParseFormat"
import duration from "dayjs/plugin/duration"
import isBetween from "dayjs/plugin/isBetween"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import utc from "dayjs/plugin/utc"
import weekday from "dayjs/plugin/weekday"

const dayjsConfig = () => {
    // Paramétrage de la locale à "fr"
    dayjs.locale("fr")

    // Ajout des plugins dayjs
    dayjs.extend(customParseFormat)
    dayjs.extend(duration)
    dayjs.extend(isBetween)
    dayjs.extend(isSameOrAfter)
    dayjs.extend(isSameOrBefore)
    dayjs.extend(utc)
    dayjs.extend(weekday)
}

export default dayjsConfig
