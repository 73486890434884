import {RETRIEVE_PRODUCTS_SUCCESS} from "../../../actions/entities/products/productsActionTypes";

export const checkbookProductIds = (state = [], action = {}) => {
    switch (action.type) {
        case RETRIEVE_PRODUCTS_SUCCESS:
            return action.response.checkbookProductIds;
        default:
            return state;
    }
};

export const accountProductIds = (state = [], action = {}) => {
    switch (action.type) {
        case RETRIEVE_PRODUCTS_SUCCESS:
            return action.response.accountProductIds;
        default:
            return state;
    }
};
