/* eslint-disable */
import PropTypes from "prop-types"
import React, {Component} from "react"
import texts from "../../../styles/globalText"

export const TextContext = React.createContext(texts)

class TextsProvider extends Component {
    getChildContext() {
        return {
            texts: this.props.texts,
        }
    }

    render() {
        return this.props.children
    }
}

TextsProvider.childContextTypes = {
    texts: PropTypes.object,
}

export default TextsProvider
