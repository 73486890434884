/**
 * Load the state from the session storage.
 */
export const loadState = () => {
    try {
        const serializedState = sessionStorage.getItem("state");
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

/**
 * Save the specified state in the session storage.
 * @param state the state to persist
 */
export const saveState = state => {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem("state", serializedState);
    } catch (err) {
        console.error("Impossible to save state in session storage : ", err);
    }
};
