/**
 * Tell the application we start an excel download request
 */
export const EXCEL_DOWNLOAD_REQUEST = "EXCEL_DOWNLOAD_REQUEST";
/**
 * Tell the application we successfully download a excel file
 */
export const EXCEL_DOWNLOAD_SUCCESS = "EXCEL_DOWNLOAD_SUCCESS";
/**
 * Tell the application we failed to download a excel file
 */
export const EXCEL_DOWNLOAD_FAILURE = "EXCEL_DOWNLOAD_FAILURE";
