import {combineReducers} from "redux"

// Action types

export const CLEAR_REQUEST = "CLEAR_REQUEST"
export const CLEAR_REQUEST_ERROR_MESSAGE = "CLEAR_REQUEST_ERROR_MESSAGE"
export const REQUEST = "REQUEST"
export const REQUEST_SUCCESS = "REQUEST_SUCCESS"
export const REQUEST_FAILURE = "REQUEST_FAILURE"

export const requestsReducerFactory = key => {
    const isFetching = (state = false, action = {}) => {
        switch (action.type) {
            case key.actionTypeRequest:
                return true
            case key.actionTypeSuccess:
            case key.actionTypeFailure:
            case key.actionTypeReset:
                return false
            case REQUEST:
                if ((action.requestKeyName && action.requestKeyName === key.name) || action.requestKeyName === key)
                    return true
                else return state
            case REQUEST_SUCCESS:
            case REQUEST_FAILURE:
            case CLEAR_REQUEST:
                if ((action.requestKeyName && action.requestKeyName === key.name) || action.requestKeyName === key)
                    return false
                else return state
            default:
                return state
        }
    }

    const isAlreadyRequested = (state = false, action = {}) => {
        switch (action.type) {
            case key.actionTypeRequest:
                return true
            case REQUEST:
                if ((action.requestKeyName && action.requestKeyName === key.name) || action.requestKeyName === key)
                    return true
                else return state
            case CLEAR_REQUEST:
                if ((action.requestKeyName && action.requestKeyName === key.name) || action.requestKeyName === key)
                    return false
                else return state
            default:
                return state
        }
    }

    const errorMessage = (state = null, action = {}) => {
        switch (action.type) {
            case key.actionTypeFailure:
                return action.message
            case key.actionTypeRequest:
            case key.actionTypeSuccess:
            case key.actionTypeReset:
                return null
            case REQUEST:
            case REQUEST_SUCCESS:
            case CLEAR_REQUEST:
            case CLEAR_REQUEST_ERROR_MESSAGE:
                if ((action.requestKeyName && action.requestKeyName === key.name) || action.requestKeyName === key)
                    return null
                else return state
            case REQUEST_FAILURE:
                if ((action.requestKeyName && action.requestKeyName === key.name) || action.requestKeyName === key)
                    return action.message
                else return state
            default:
                return state
        }
    }

    const payload = (state = {}, action = {}) => {
        switch (action.type) {
            case key.actionTypeRequest:
            case key.actionTypeFailure:
            case key.actionTypeReset:
                return {}
            case key.actionTypeSuccess:
                return action.payload ? action.payload : {}
            case REQUEST_SUCCESS:
                if (
                    ((action.requestKeyName && action.requestKeyName === key.name) || action.requestKeyName === key) &&
                    !!action.payload
                )
                    return action.payload
                else return state
            case REQUEST:
            case REQUEST_FAILURE:
            case CLEAR_REQUEST:
                if ((action.requestKeyName && action.requestKeyName === key.name) || action.requestKeyName === key)
                    return {}
                else return state
            default:
                return state
        }
    }

    return combineReducers({
        isAlreadyRequested,
        isFetching,
        errorMessage,
        payload,
    })
}

// Actions

/**
 * Clear a request state
 * @param {String} requestKeyName the RequestInfo[RequestKeys.KEY].name
 */
export const clearRequest = requestKeyName => ({
    type: CLEAR_REQUEST,
    requestKeyName,
})

/**
 * Clear a request error message state
 * @param {String} requestKeyName the RequestInfo[RequestKeys.KEY].name
 */
export const clearRequestErrorMessage = requestKeyName => ({
    type: CLEAR_REQUEST_ERROR_MESSAGE,
    requestKeyName,
})

// Selectors
export const isAlreadyRequested = (requestName, state) =>
    state && state[requestName] ? state[requestName].isAlreadyRequested : false
export const isFetching = (requestName, state) => (state && state[requestName] ? state[requestName].isFetching : false)
export const getErrorMessage = (requestName, state) => state[requestName].errorMessage
export const getPayload = (requestName, state) => state[requestName].payload
