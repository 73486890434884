import {EDIT_DELIVERY_FIELD, RESET_DELIVERY} from "../../../actions/localStorage/delivery/deliveryActionTypes"

const delivery = (state = {}, action = {}) => {
    switch (action.type) {
        case EDIT_DELIVERY_FIELD:
            return {
                ...state,
                [action.fieldName]: action.value,
            }
        case RESET_DELIVERY:
            return {}
        default:
            return state
    }
}

export default delivery

/**
 * Get delivery
 */
export const getDelivery = state => state
