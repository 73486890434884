import React from "react"
import PropTypes from "prop-types"
import "./menuItemDropdown.scss"
import MenuItemContainer from "../MenuItem/menuItemContainer"
import FadeTransition from "../FadeTransition/fadeTransition"
import {displayMenu} from "../../services/displayMenu"
import {Icon} from "semantic-ui-react"

/**
 * Dropdown list component for a sub menu.
 */
const MenuItemDropdown = (
    {isConnected, text, choices, isOpened, onMouseEnter, onMouseLeave, onChoiceClick, userPermissions},
    {sheet},
) => {
    const isActive = choices.reduce((acc, current) => {
        return acc || current.active
    }, false)

    return (
        <div className="menu-item-dropdown-component" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {/* Menu header. Contains the header's text and the little arrow. */}
            <MenuItemContainer active={isOpened || isActive} className="menu-dropdown-item">
                {text}
                <Icon className="menu-item-dropdown-icon" name="caret right" />
            </MenuItemContainer>

            {/* Open with a fade transition. */}
            {isOpened && (
                <FadeTransition>
                    <div className={sheet.classes.borderSecondaryColor + " menu-item-dropdown-component-panel"}>
                        {/* Loop through all content. */}
                        {choices.map((choice, index) => {
                            if (choice.type === "ITEM") {
                                return (
                                    displayMenu(
                                        isConnected,
                                        choice.permissions,
                                        choice.userConnected,
                                        userPermissions,
                                        choice.isFeatureEnabled,
                                    ) && (
                                        <MenuItemContainer
                                            active={choice.active}
                                            className="menu-item-dropdown-component-choice menu-item-dropdown-component-choice-item"
                                            key={index}
                                            onClick={() => onChoiceClick(choice.onClick)}
                                        >
                                            {choice.text}
                                        </MenuItemContainer>
                                    )
                                )
                            } else {
                                return <div key={index}>UNKOWN_TYPE</div>
                            }
                        })}
                    </div>
                </FadeTransition>
            )}
        </div>
    )
}

MenuItemDropdown.contextTypes = {
    sheet: PropTypes.object,
}

MenuItemDropdown.propTypes = {
    // Text for the dropdown list header.
    text: PropTypes.string.isRequired,
    isConnected: PropTypes.bool.isRequired,

    // Content of the list.
    choices: PropTypes.arrayOf(
        PropTypes.oneOfType([
            // A dropdown item.
            PropTypes.shape({
                type: PropTypes.oneOf(["ITEM"]),
                text: PropTypes.string.isRequired,
                onClick: PropTypes.func.isRequired,
                active: PropTypes.bool,
            }),
        ]),
    ).isRequired,

    // Opened state.
    isOpened: PropTypes.bool.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,

    onChoiceClick: PropTypes.func.isRequired,
}

export default MenuItemDropdown
