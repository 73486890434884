/**
 * Tell the store the user want to go to the previous page
 */
export const GO_TO_PREVIOUS_PAGE = "GO_TO_PREVIOUS_PAGE"
/**
 * Tell the store the user want to go to the next page
 */
export const GO_TO_NEXT_PAGE = "GO_TO_NEXT_PAGE"

/**
 * Tell the store the user want to go to a specific page
 */
export const GO_TO_PAGE = "GO_TO_PAGE"

/**
 * Initialize the pagination
 */
export const INIT_PAGE = "INIT_PAGE"

/**
 * Update the number maximum of pages
 */
export const UPDATE_MAX_PAGES = "UPDATE_MAX_PAGES"

/**
 * Update the whole pagination
 */
export const UPDATE_PAGINATION = "UPDATE_PAGINATION"
