import {ADD_NOTIFICATION, REMOVE_NOTIFICATION, CLEAR_NOTIFICATIONS} from "./notificationsActionTypes"

export const addNotification = notification => ({
    type: ADD_NOTIFICATION,
    notification,
})

export const removeNotification = notification => ({
    type: REMOVE_NOTIFICATION,
    notification,
})

export const clearNotifications = {
    type: CLEAR_NOTIFICATIONS,
}
