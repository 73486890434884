import {
    GET_MILLESIME_IN_TRADE_SUCCESS,
    GET_MILLESIME_IN_TRADE_FAILURE,
    GET_MILLESIME_IN_TRADE_REQUEST,
} from "../../../actions/temporaries/millesime/millesimeActionsTypes"

const millesime = (state = {}, action = {}) => {
    switch (action.type) {
        case GET_MILLESIME_IN_TRADE_SUCCESS:
            return action.response
        case GET_MILLESIME_IN_TRADE_REQUEST:
        case GET_MILLESIME_IN_TRADE_FAILURE:
            return {}
        default:
            return state
    }
}

export default millesime

export const getMillesime = state => state
