import React, {Component} from "react"
import PropTypes from "prop-types"
import MenuItemDropdown from "./menuItemDropdown"

/**
 * Dropdown item list container for sub menu.
 */
class MenuItemDropdownContainer extends Component {
    constructor() {
        super()

        this.state = {
            isOpened: false,
        }
    }

    changeHover = isOpened => {
        this.setState({
            isOpened,
        })
    }

    onChoiceClick = onClick => {
        if (onClick) {
            onClick()
            this.changeHover(false)
        }
    }

    render() {
        return (
            <MenuItemDropdown
                {...this.props}
                isOpened={this.state.isOpened}
                onChoiceClick={this.onChoiceClick}
                onMouseEnter={() => this.changeHover(true)}
                onMouseLeave={() => this.changeHover(false)}
            />
        )
    }
}

MenuItemDropdownContainer.propTypes = {
    // Menu header's texts.
    text: PropTypes.string.isRequired,

    // Menu content.
    choices: PropTypes.arrayOf(
        PropTypes.oneOfType([
            // A dropdown item.
            PropTypes.shape({
                type: PropTypes.oneOf(["ITEM"]),
                text: PropTypes.string.isRequired,
                onClick: PropTypes.func.isRequired,
                active: PropTypes.bool,
            }),
        ]),
    ).isRequired,
}

export default MenuItemDropdownContainer
