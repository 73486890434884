import {get} from "axios";
import {
    RETRIEVE_STYLE_FAILURE, RETRIEVE_STYLE_REQUEST,
    RETRIEVE_STYLE_SUCCESS
} from "./styleActionTypes";
import {GET_STYLE_ENDPOINT} from "../../../constants/endpoints";
import {getErrorMessage} from "../../../services/api";

/**
 * Thunk to retrieve a customized style.
 * Dispatch on request and on success or failure.
 * @param espaceType the name of the application
 * @param contractId the id of the contract
 */
export const retrieveStyle = (espaceType, contractId) => (dispatch) => {
    dispatch({
        type: RETRIEVE_STYLE_REQUEST
    });

    // Build query parameters.
    const params = {
        numeroContrat: contractId,
        typeEspace: espaceType
    };

    return get(GET_STYLE_ENDPOINT, {params}).then(
        (response) => {
            dispatch({
                type: RETRIEVE_STYLE_SUCCESS,
                response: response.data,
            })
        },
        (error) => {
            dispatch({
                type: RETRIEVE_STYLE_FAILURE,
                message: getErrorMessage(error),
                numeroContrat: contractId,
            })
        }
    );
};
