/* eslint-disable */
import PropTypes from "prop-types"
import React, {Component} from "react"
import {createUseStyles, useTheme} from "react-jss"
import defaultStyle from "../../constants/defaultStyle"
import sheet from "../../styles/globalSheet"

// Create a hook
const useGlobalStyle = createUseStyles(sheet)

// The new SheetProvider using React-JSS hooks
// It is also responsible for injecting the CSS in the DOM
const SheetProvider = ({children}) => {
    // Retrieve the theme which can be customized

    // We cheat the rules of hooks for retrocompatibility with all our tests
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const theme = process.env.NODE_ENV === "test" ? defaultStyle : useTheme()

    // Apply the theme
    const globalStyle = useGlobalStyle(theme)

    // Return the LegacySheetProvider with the style to put in context
    return <LegacySheetProvider globalStyle={globalStyle}>{children}</LegacySheetProvider>
}

/**
 * A component with inject the sheet object in the Legacy React Context API like react-jss use to do
 * This is done for retro compatibility with all our components using the sheet objet via the Legacy React Context API
 */
class LegacySheetProvider extends Component {
    getChildContext() {
        return {
            sheet: {
                classes: this.props.globalStyle,
            },
        }
    }

    render() {
        return this.props.children
    }
}

LegacySheetProvider.childContextTypes = {
    sheet: PropTypes.object,
}

export default SheetProvider
