/**
 * Load the state from the local storage.
 */
export const loadState = () => {
    try {
        const serializedState = localStorage.getItem("state");
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

/**
 * Save the specified state in the local storage.
 * @param state the state to persist
 */
export const saveState = state => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("state", serializedState);
    } catch (err) {
        console.error("Impossible to save state in local storage : ", err);
    }
};

/**
 * Load the version from the local storage.
 */
export const loadVersion = () => {
    try {
        const serializedState = localStorage.getItem("version");
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

/**
 * Save the specified version in the local storage.
 * @param version the version to persist
 */
export const saveVersion = version => {
    try {
        const serializedVersion = JSON.stringify(version);
        localStorage.setItem("version", serializedVersion);
    } catch (err) {
        console.error("Impossible to save version in local storage : ", err);
    }
};

/**
 * Clear all local storage.
 */
export const clearStorage = () => {
    localStorage.clear();
};
