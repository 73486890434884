import {combineReducers} from "redux"
import {values} from "lodash"
import {EntityInfo} from "../../constants/entities"

const idsReducerFactory = actionType => {
    return (state = [], action = {}) => {
        if (action.type === actionType) {
            return action.response.result
        } else {
            return state
        }
    }
}

// Generic way to create ids reducer
let reducers = {}
// Transform EntityInfo object to table and loop on it
values(EntityInfo).forEach(e => {
    // Create an ids reducer
    reducers[e.name] = idsReducerFactory(e.actionTypeSuccess)
    // Add custom ids reducer if exist
    if (e.idsReducers) {
        e.idsReducers.forEach(idReducer => {
            reducers = {
                ...reducers,
                [idReducer.name]: idReducer.reducer,
            }
        })
    }
})

/**
 * Part of the store to stock IDs.
 */
const ids = combineReducers(reducers)

export default ids

/**
 * Get all entity IDs from the state.
 */
export const getIds = (entityName, state) => state[entityName]
