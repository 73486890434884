import {Page} from "./pages"

/**
 * Architecture of the pages of the application.
 * Structure :
 * - breadcrumbText : the text print in a breadcrumb
 * - route : the route to go to the page
 * - routeWithParamDesc : a second route, with param
 * - routeWithParam : a function take all params for the routeWithParamDesc and building a route
 * - parent : the parent page
 * - parentDisconnect : the parent page if no user is connected
 * - permissions: User permissions needed to access the page
 */
export const PagesArchitecture = {
    [Page.BYPASS_LOGIN]: {
        route: "/bypass-login",
        userConnected: false,
    },
    [Page.CONFIRMATION]: {
        breadcrumbText: "Confirmation",
        route: "/echange/confirmation",
        parent: Page.HOME,
        userConnected: true,
    },
    [Page.DELIVERY]: {
        breadcrumbText: "Livraison",
        route: "/echange/livraison",
        parent: Page.HOME,
        userConnected: true,
    },
    [Page.HOME]: {
        breadcrumbText: "Accueil",
        route: "/",
        userConnected: true,
    },
    [Page.LEGAL_NOTICE]: {
        breadcrumbText: "Mentions Légales",
        parent: Page.HOME,
        parentDisconnect: Page.LOGIN,
        route: "/mentions-legales",
    },
    [Page.LOGIN]: {
        breadcrumbText: "Connexion",
        route: "/login",
    },
    [Page.NOT_FOUND]: {
        breadcrumbText: "Page introuvable",
        route: "/notfound",
    },
    [Page.PAYMENT]: {
        breadcrumbText: "Paiement",
        route: "/echange/paiement",
        parent: Page.HOME,
        userConnected: true,
    },
    [Page.TECHNICAL_ERROR]: {
        route: "/erreur-technique",
    },
    [Page.TITLES]: {
        breadcrumbText: "Echange",
        route: "/echange/titres",
        parent: Page.HOME,
        userConnected: true,
    },
    [Page.UNAUTHORIZED]: {
        breadcrumbText: "Accès interdit",
        route: "/unauthorized",
        parent: Page.HOME,
    },
}
