/**
 * Indicate that we start a get refund request
 */
export const GET_CHECKBOOKS_OF_REFUND_REQUEST = "GET_CHECKBOOKS_OF_REFUND_REQUEST"
/**
 * Indicate that the get of refund request was successful
 */
export const GET_CHECKBOOKS_OF_REFUND_SUCCESS = "GET_CHECKBOOKS_OF_REFUND_SUCCESS"
/**
 * Indicate that the get of refund request has failed
 */
export const GET_CHECKBOOKS_OF_REFUND_FAILURE = "GET_CHECKBOOKS_OF_REFUND_FAILURE"
