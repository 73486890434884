import React, {Component} from "react"
import PropTypes from "prop-types"
import MenuDropdown from "./menuDropdown"

/**
 * Dropdown item list container.
 * Handle hovering.
 */
class MenuDropdownContainer extends Component {
    constructor() {
        super()

        this.state = {
            isOpened: false,
        }
    }

    changeHover = isOpened => {
        this.setState({
            isOpened,
        })
    }

    onChoiceClick = onClick => {
        if (onClick) {
            onClick()
            this.changeHover(false)
        }
    }

    render() {
        return (
            <div className="menu-dropdown-container">
                <MenuDropdown
                    {...this.props}
                    isOpened={this.state.isOpened}
                    onChoiceClick={this.onChoiceClick}
                    onMouseEnter={() => this.changeHover(true)}
                    onMouseLeave={() => this.changeHover(false)}
                />
            </div>
        )
    }
}

MenuDropdownContainer.propTypes = {
    // Menu header's texts.
    text: PropTypes.string.isRequired,

    // Panel alignment.
    alignment: PropTypes.oneOf(["LEFT", "RIGHT"]),

    // Menu content.
    choices: PropTypes.arrayOf(
        PropTypes.oneOfType([
            // A dropdown item.
            PropTypes.shape({
                type: PropTypes.oneOf(["ITEM"]),
                text: PropTypes.string.isRequired,
                onClick: PropTypes.func.isRequired,
                active: PropTypes.bool,
            }),
            // A dropdown text.
            PropTypes.shape({
                type: PropTypes.oneOf(["DROPDOWN"]),
                text: PropTypes.string.isRequired,
                choices: PropTypes.arrayOf(
                    PropTypes.shape({
                        type: PropTypes.oneOf(["ITEM"]),
                        text: PropTypes.string.isRequired,
                        onClick: PropTypes.func.isRequired,
                        active: PropTypes.bool,
                    }),
                ),
            }),
        ]),
    ).isRequired,
}

export default MenuDropdownContainer
