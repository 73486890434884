import {findKey} from "lodash";

/**
 * Check if we need to empty the state by checking version from parameter to actual one.
 * @param actualVersion the version to check for
 * @param Versions the versions structure
 * @param VersionKeys the versions keys
 * @returns {boolean} true if we need to empty the state, false otherwise
 */
export const checkIfEmptyStorageNeeded = (actualVersion, Versions, VersionKeys) => {
    let v = actualVersion && Versions[actualVersion] ? Versions[actualVersion] : Versions[VersionKeys.V0_0_0];
    let emptyNeeded = false;

    while (v.next) {
        v = Versions[v.next];
        emptyNeeded |= v.emptyState;
    }

    return emptyNeeded;
};

/**
 * Get the last version from the version structure.
 */
export const getLastVersion = Versions => findKey(Versions, v => !v.next);
