import React, {Component} from "react"
import {union} from "lodash"
import NormalMenu from "../NormalMenu/normalMenu"
import MobileMenuContainer from "../MobileMenu/mobileMenuContainer"
import PropTypes from "prop-types"
import {getImageFromRemotePath} from "../../services/filer"
import {LOGO} from "../../constants/imageDefault"

import "./menu.scss"

/**
 * Application menu.
 * Contains a structure describing the menu.
 * Its aspect changes under a width threshold to be more responsive.
 */
class Menu extends Component {
    static propTypes = {
        /** Content of the menu. */
        menuContent: PropTypes.arrayOf(PropTypes.object).isRequired,
        /**
         * Function called when clicking on the logo.
         */
        onHomeClick: PropTypes.func.isRequired,
        /**
         * Display an HamburgerButton
         */
        setMenuResponsive: PropTypes.func.isRequired,
        /**
         * Tell is the menu should be display in a responsive way.
         */
        isMenuResponsive: PropTypes.bool.isRequired,
        /**
         * Is user connected ?
         */
        isConnected: PropTypes.bool.isRequired,
        /**
         * Menu title.
         */
        title: PropTypes.string.isRequired,
        /**
         * Array of the connected user permissions.
         */
        userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
        /**
         * The logo.
         */
        staticLogo: PropTypes.string,
        /**
         * affichage de notification sur le menu
         */
        displayNotificationFunction: PropTypes.func,
    }

    constructor() {
        super()
        this.state = {
            menuContent: [],
            width: 0,
        }

        window.addEventListener("resize", this.updateWidth)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWidth)
    }

    updateWidth = () => {
        if (this.state.width !== window.innerWidth) {
            this.props.setMenuResponsive(window.innerWidth <= 900)
        }

        this.setState({
            width: window.innerWidth,
        })
    }

    updateMenuContent(props) {
        const {menuContent} = props

        this.setState(
            {
                menuContent,
            },
            this.computePermissions,
        )
    }

    computePermissions() {
        const computeMenuItemPermission = menuItem => {
            if (menuItem.type === "DROPDOWN" && menuItem.choices) {
                let menuItemPermissions = []
                menuItem.choices.forEach(choice => {
                    menuItemPermissions = union(menuItemPermissions, computeMenuItemPermission(choice).permissions)
                })
                menuItem.permissions = menuItemPermissions
            }

            return menuItem
        }

        this.setState({
            menuContent: this.state.menuContent.map(menuItem => computeMenuItemPermission({...menuItem})),
        })
    }

    componentDidMount() {
        this.updateMenuContent(this.props)
        this.props.setMenuResponsive(window.innerWidth <= 900)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateMenuContent(nextProps)
    }

    render() {
        const {
            areaType,
            isConnected,
            isMenuResponsive,
            onConnectClick,
            onDisconnectClick,
            onHomeClick,
            onProfileClick,
            title,
            userPermissions,
            staticLogo,
            displayNotificationFunction,
        } = this.props

        return (
            <div className="menu-component">
                {/* Large screens. */}
                {!isMenuResponsive && (
                    <NormalMenu
                        content={this.state.menuContent}
                        displayNotificationFunction={displayNotificationFunction}
                        isConnected={isConnected}
                        logo={getImageFromRemotePath(this.context.texts.logo, areaType, LOGO, staticLogo)}
                        redirectToHome={onHomeClick}
                        title={title}
                        userPermissions={userPermissions}
                    />
                )}

                {/* Small screens. */}
                {isMenuResponsive && (
                    <MobileMenuContainer
                        content={this.state.menuContent}
                        displayNotificationFunction={displayNotificationFunction}
                        isConnected={isConnected}
                        logo={getImageFromRemotePath(this.context.texts.logo, areaType, LOGO, staticLogo)}
                        onConnectClick={onConnectClick}
                        onDisconnectClick={onDisconnectClick}
                        onProfileClick={onProfileClick}
                        redirectToHome={onHomeClick}
                        title={title}
                        userPermissions={userPermissions}
                    />
                )}
            </div>
        )
    }
}

Menu.contextTypes = {
    texts: PropTypes.object,
}

// Encapsulate the menu in a container which add listeners on containerWidth and containerHeight
// and inject it into props.
export default Menu
