/**
 * Get the good API URL from the configuration.
 */
export const getApiUrl = () => {
    if (process.env.NODE_ENV === "production") {
        // Production build.
        if (!process.env.REACT_APP_PROD_MODE) {
            // Deployed in QA (recette).
            return process.env.REACT_APP_QA_API_URL;
        } else {
            // Deployed in production.
            return process.env.REACT_APP_PROD_API_URL;
        }
    } else {
        // Development build.
        return process.env.REACT_APP_DEV_API_URL;
    }
};
