import * as React from "react"

/**
 * Inject the global texts as a props in a component.
 */
const injectTexts = (texts, WrappedComponent) => {
    return class extends React.Component {
        constructor(props) {
            super(props)

            this.state = {
                innerTexts: {},
            }
        }

        componentDidMount() {
            this.setState({
                innerTexts: texts,
            })
        }

        render() {
            return <WrappedComponent texts={this.state.innerTexts} {...this.props} />
        }
    }
}

export default injectTexts
