import "babel-polyfill"
import "dayjs/locale/fr"
import React from "react"
import {render} from "react-dom"
import "url-search-params-polyfill"
import {Page} from "./constants/page/pages"
import {PagesArchitecture} from "./constants/page/pagesArchitecture"
import {VersionKeys, Versions} from "./constants/version/version"
import history from "./history"
import "./index.css"
import Root from "./Root"
import dayjsConfig from "./shared-components/dayjs"
import {checkVersion} from "./shared-components/services/checkVersion"
import configureAxios from "./shared-components/services/configureAxios"
import configureStore from "./shared-components/services/configureStore"
import "./shared-components/styles/text-styles.scss"
import reducers from "./store/index"
import {getExpirationDate, getUserPermissions, getUserToken} from "./store/selectors/sessionStorage/userSelectors"

// Paramétrage de dayjs
dayjsConfig()

// Check the version and reset local storage if necessary.
checkVersion(VersionKeys, Versions)

// Launch store configuration and loading from local storage.
const store = configureStore(reducers)

// Configure axios for API calls.
configureAxios(store, history, getExpirationDate, getUserToken, PagesArchitecture, Page, getUserPermissions)

// Normal root.
render(<Root store={store} />, document.getElementById("root"))
