/**
 * Indicate that we launched a request to validate a siret.
 * @type {string}
 */
export const SIRET_VALIDATION_REQUEST = 'SIRET_VALIDATION_REQUEST';

/**
 * Indicate that we successfully validate a siret.
 * @type {string}
 */
export const SIRET_VALIDATION_SUCCESS = 'SIRET_VALIDATION_SUCCESS';

/**
 * Indicate that the siret validation failed.
 * @type {string}
 */
export const SIRET_VALIDATION_FAILURE = 'SIRET_VALIDATION_FAILURE';

/**
 * Indicate that we want to empty all siret validation details.
 * @type {string}
 */
export const EMPTY_SIRET_VALIDATION = 'EMPTY_SIRET_VALIDATION';
