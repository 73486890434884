/**
 * Add a notification
 */
export const ADD_NOTIFICATION = "ADD_NOTIFICATION"
/**
 * Remove a notification
 */
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION"
/**
 * Clear all notifications
 */
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS"
