import * as fromSessionStorage from "../../sessionStorage/sessionStorageReducer"
import * as fromRequests from "../../../shared-components/store/requests/requestsReducer"
import {RequestKeys, RequestInfo} from "../../../shared-components/constants/requests"
import {ProductTypes} from "../../../shared-components/constants/productTypes"

/**
 * Get the user token from the state.
 */
export const getUserToken = state => fromSessionStorage.getUserToken(state.sessionStorage)

/**
 * Get the expiration date.
 */
export const getExpirationDate = state => fromSessionStorage.getExpirationDate(state.sessionStorage)

/**
 * Verify if user is connected.
 */
export const isUserConnected = state => !!getUserToken(state)

/**
 * Get trades parameters attachted to the user contracts
 */
export const getTradesParameters = state => {
    const userDetails = getUserDetails(state)
    if (userDetails.beneficiaryForTrade) {
        return userDetails.beneficiaryForTrade.tradesParameters
    } else {
        return []
    }
}

/**
 * Get user contracts
 */
export const getContracts = state => {
    const userDetails = getUserDetails(state)
    if (userDetails.beneficiaryForTrade) {
        return userDetails.beneficiaryForTrade.contracts
    } else {
        return []
    }
}

/**
 * Verify if the user can trade to titles
 */
export const canTradeToTitles = (state, contractId) => {
    let tradesParameters = getTradesParameters(state)

    if (contractId) {
        let canTradeToTitles = false
        tradesParameters
            .filter(tradeParameters => tradeParameters.contractId === contractId)
            .forEach(tradeParameters => {
                canTradeToTitles =
                    canTradeToTitles ||
                    (tradeParameters.startProductType === ProductTypes.TITLE &&
                        tradeParameters.finalProductType === ProductTypes.TITLE &&
                        tradeParameters.enableTrade)
            })

        return canTradeToTitles
    } else {
        return false
    }
}

/**
 * Verify if the user can trade to titles
 */
export const canTradeToAccounts = (state, contractId) => {
    let tradesParameters = getTradesParameters(state)

    if (contractId) {
        let canTradeToAccounts = false
        tradesParameters
            .filter(tradeParameters => tradeParameters.contractId === contractId)
            .forEach(tradeParameters => {
                canTradeToAccounts =
                    canTradeToAccounts ||
                    (tradeParameters.startProductType === ProductTypes.TITLE &&
                        tradeParameters.finalProductType === ProductTypes.ACCOUNT &&
                        tradeParameters.enableTrade)
            })

        return canTradeToAccounts
    } else {
        return false
    }
}

/**
 * Return the support type of
 */
export const getSupportType = (state, contractId) => {
    const contracts = getContracts(state).filter(contract => contract.id === contractId)
    if (contracts.length !== 1) {
        return undefined
    }

    return contracts[0].supportType
}

/**
 * Tell if the user came from the back office
 */
export const isFromBackOffice = state =>
    getUserBeneficiaryForTrade(state).fromBackOffice ? getUserBeneficiaryForTrade(state).fromBackOffice : false

/**
 * Verify if user token is fetching.
 */
export const isFetchingAuthority = state =>
    fromRequests.isFetching(RequestInfo[RequestKeys.USER_AUTHORITY].name, state.requests)

/**
 * Get the authority error message from the state.
 */
export const getAuthorityErrorMessage = state =>
    fromRequests.getErrorMessage(RequestInfo[RequestKeys.USER_AUTHORITY].name, state.requests)

/**
 * Get user permissions from the store.
 */
export const getUserPermissions = state => fromSessionStorage.getUserPermissions(state.sessionStorage)

/**
 * Get user identity from the store.
 */
export const getUserDetails = state => fromSessionStorage.getUserDetails(state.sessionStorage)

/**
 * Get user client type from the store.
 */
export const getUserClientType = state => getUserDetails(state).clientType

/**
 * Get user intervenant from the store.
 */
export const getUserIntervenant = state => getUserDetails(state).intervenant

/**
 * Get user intervenant id from the store.
 */
export const getUserIntervenantId = state => (getUserIntervenant(state) ? getUserIntervenant(state).id : null)

/**
 * Get user intervenant type from the store.
 */
export const getUserIntervenantType = state => (getUserIntervenant(state) ? getUserIntervenant(state).type : "")
/**
 * Get user intervenant status from the store.
 */
export const getUserIntervenantStatus = state =>
    getUserIntervenant(state) ? getUserIntervenant(state).contactStatus : ""

/**
 * Get user client ID from the store.
 */
export const getUserClientId = state => getUserDetails(state).clientId

/**
 * Get user login from the store.
 */
export const getUserLogin = state => getUserDetails(state).login

/**
 * Get user siret from the store.
 */
export const getUserSiret = state => getUserDetails(state).siret

/**
 * Get user full name
 */
export const getUserFullname = state => {
    const userDetails = getUserDetails(state)
    return userDetails.firstName + " " + userDetails.lastName
}

/**
 * Get user mail
 */
export const getUserMail = state => getUserDetails(state).mail

/**
 * Get user beneficiary for trade
 */
export const getUserBeneficiaryForTrade = state =>
    getUserDetails(state).beneficiaryForTrade ? getUserDetails(state).beneficiaryForTrade : {}

/**
 * Get user phoneNumber
 */
export const getUserPhoneNumber = state =>
    getUserBeneficiaryForTrade(state).phoneNumber
        ? getUserBeneficiaryForTrade(state).phoneNumber.replace(/\s/g, "")
        : ""

/**
 * Verify if user details is fetching ?
 */
export const isFetchingUserDetails = state =>
    fromRequests.isFetching(RequestInfo[RequestKeys.USER_INFO].name, state.requests)

/**
 * Verifiy is recover login password request is fetching
 */
export const isFetchingRecoverLoginPassword = state =>
    fromRequests.isFetching(RequestInfo[RequestKeys.RECOVER_LOGIN_PASSWORD].name, state.requests)
