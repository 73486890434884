import {useMatomo} from "@datapunt/matomo-tracker-react"
import {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {getActualPage} from "../store/selectors/temporaries/pageSelectors"

export const MatomoTrackPageView = () => {
    const actualPage = useSelector(state => getActualPage(state))
    const {trackPageView} = useMatomo()

    const [previousPage, setPreviousPage] = useState(null)

    useEffect(() => {
        if (actualPage !== null && actualPage !== previousPage) {
            setPreviousPage(actualPage)
            trackPageView()
        }
    }, [actualPage])

    return null
}
