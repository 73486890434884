import CSSTransition from "react-transition-group/CSSTransition"
import React, {Component} from "react"
import "./fadeTransition.scss"

/**
 * Inner component for more visibility.
 * Add classes when the component enters and leaves the DOM.
 */
const Fade = ({children, ...props}) => (
    <CSSTransition {...props} classNames="fade-transition" timeout={200}>
        {children}
    </CSSTransition>
)

/**
 * Fade transition.
 * Add a transition over its children to fade-in when they're added to the DOM.
 */
class FadeTransition extends Component {
    constructor() {
        super()
        // The state contains a boolean to launch the transition.
        this.state = {show: false}
    }

    componentDidMount() {
        this.setState({show: true})
    }

    componentWillUnmount() {
        this.setState({show: false})
    }

    render() {
        return (
            <Fade in={this.state.show}>
                <div>{this.props.children}</div>
            </Fade>
        )
    }
}

export default FadeTransition
