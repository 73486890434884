import {CHECK_RESPONSIVENESS} from "../../../actions/temporaries/menu/menuActionTypes";

/**
 * Save selected menu item key.
 * Default value is HOME.
 */
const menu = (state = {isResponsive: true}, action = {}) => {
    switch (action.type) {
        case CHECK_RESPONSIVENESS:
            return {
                ...state,
                isResponsive: action.isResponsive,
            };
        default:
            return state;
    }
};

export default menu;

/**
 * Verify if the menu is responsive
 */
export const isResponsive = state => state.isResponsive;
