/**
 * Indicate that we launch a request to get all legal forms from API
 * @type {string}
 */
export const GET_LEGAL_FORMS_REQUEST = 'GET_LEGAL_FORMS_REQUEST'

/**
 * Indicate that the request to get all legal forms from API was successful
 * @type {string}
 */
export const GET_LEGAL_FORMS_SUCCESS = 'GET_LEGAL_FORMS_SUCCESS'

/**
 * Indicate that the request to get all legal forms from API failed
 * @type {string}
 */
export const GET_LEGAL_FORMS_FAILURE = 'GET_LEGAL_FORMS_FAILURE'
