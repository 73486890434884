import { composeWithDevTools } from "@redux-devtools/extension"
import { throttle } from "lodash"
import { applyMiddleware, createStore } from "redux"
import thunk from "redux-thunk"
import * as localStorage from "../services/localStorage"
import * as sessionStorage from "../services/sessionStorage"

/**
 * Create a store from all reducers, add the thunk and logger middlewares.
 * Fill the store with local storage content if necessary.
 * Configure a function to save the store in the local storage every second if necessary.
 * @returns {Store<S>} the configured store
 */
const configureStore = reducers => {
    const middlewares = [thunk]

    const state = {
        localStorage: localStorage.loadState(),
        sessionStorage: sessionStorage.loadState(),
    }

    const store = createStore(reducers, state, composeWithDevTools(applyMiddleware(...middlewares)))

    store.subscribe(
        throttle(() => {
            localStorage.saveState(store.getState().localStorage);
            sessionStorage.saveState(store.getState().sessionStorage);
        }, 1000),
    )

    return store
}

export default configureStore
