import { SET_BYPASSED_AUTHENTICATION } from "../../../../actions/sessionStorage/user/userActionTypes"

const bypassedAuthentication = (state = false, action = {}) => {
    switch (action.type) {
        case SET_BYPASSED_AUTHENTICATION:
            return true
        default:
            return state
    }
}

export default bypassedAuthentication

/**
 * Get bypassedAuthentication from the store.
 */
export const getBypassedAuthentication = state => state
