import PropTypes from "prop-types"
import React, {Component, Suspense} from "react"
import {connect} from "react-redux"
import {Route, Router, Switch, withRouter} from "react-router-dom"
import {AREA_TYPE} from "../constants/areaType"
import {menuContent} from "../constants/menu/menu"
import {Page} from "../constants/page/pages"
import {PagesArchitecture} from "../constants/page/pagesArchitecture"
import {saveCookiesChoices} from "../shared-components/actions/localStorage/cookies/cookiesActions"
import {disconnectUser} from "../shared-components/actions/sessionStorage/user/userActions"
import {clearCheckbooks} from "../shared-components/actions/temporaries/handOverCheckbooks/handOverCheckbooksActions"
import {setResponsive} from "../shared-components/actions/temporaries/menu/menuAction"
import {logoutUser} from "../shared-components/common/actions/user/userActions"
import CookiesNotificationContainer from "../shared-components/components/CookiesNotification/cookiesNotificationContainer"
import Footer from "../shared-components/components/Footer/footer"
import MenuContainer from "../shared-components/components/Menu/menu"
import MenuTop from "../shared-components/components/MenuTop/menuTop"
import {DOMISERVE_CONTACT_URL} from "../shared-components/constants/links"
import {
    getCookiesChoices,
    getModalStep,
    isCookiesModalOpened,
    isCookiesUserAcceptanceExpired,
    isUserAcceptedCookies,
} from "../store/selectors/localStorage/cookiesSelectors"
import {getUserFullname, getUserPermissions, isUserConnected} from "../store/selectors/sessionStorage/userSelectors"
import {isMenuResponsive} from "../store/selectors/temporaries/menuSelectors"
import {getActualPage} from "../store/selectors/temporaries/pageSelectors"
import {AsyncBreadcrumbLayout, AsyncUnauthorizedScene} from "./asyncScenes"
import "./mainLayout.scss"
import {MatomoTrackPageView} from "./matomoTrackPageView"

class MainLayout extends Component {
    getBreadcrumbLayout = () => (
        <AsyncBreadcrumbLayout actualPage={this.props.actualPage} isUserConnected={this.props.isUserConnected} />
    )

    onDisconnectClick = () => {
        this.props.logoutUser()
        this.props.disconnectUser()
    }

    render() {
        const {
            actualPage,
            history,
            isMenuResponsive,
            isUserConnected,
            onConnectClick,
            onCookiesClick,
            onProfileClick,
            onContactClick,
            onHomeClick,
            onLegalClick,
            setMenuResponsive,
            username,
            userPermissions,
        } = this.props

        return (
            <Router history={history}>
                <div
                    className={
                        "main-layout" +
                        (process.env.NODE_ENV === "production" && !process.env.REACT_APP_PROD_MODE
                            ? " main-layout-bordered"
                            : "")
                    }
                >
                    <MenuTop
                        isConnected={isUserConnected}
                        onDisconnectClick={this.onDisconnectClick}
                        onProfileClick={onProfileClick}
                        username={username}
                    />
                    <MatomoTrackPageView />
                    <div
                        className={
                            (isMenuResponsive ? "main-page-responsive" : "main-page-not-responsive") + " main-page"
                        }
                    >
                        <div className="menu-container">
                            <MenuContainer
                                areaType={AREA_TYPE}
                                isConnected={isUserConnected}
                                isMenuResponsive={isMenuResponsive}
                                menuContent={menuContent(actualPage, history)}
                                onConnectClick={onConnectClick}
                                onDisconnectClick={this.onDisconnectClick}
                                onHomeClick={onHomeClick}
                                onProfileClick={onProfileClick}
                                setMenuResponsive={setMenuResponsive}
                                title="ESPACE ÉCHANGE"
                                userPermissions={userPermissions}
                            />
                        </div>
                        <div className="main-content">
                            <Suspense fallback={<div />}>
                                <Switch>
                                    {/* Unauthorized route. */}
                                    <Route
                                        component={AsyncUnauthorizedScene}
                                        exact
                                        path={PagesArchitecture[Page.UNAUTHORIZED].route}
                                    />

                                    {/* Breadcrumb layout */}
                                    <Route component={this.getBreadcrumbLayout} path="" />
                                </Switch>
                            </Suspense>
                        </div>
                    </div>
                    <CookiesNotificationContainer
                        getCookiesChoices={getCookiesChoices}
                        getModalStep={getModalStep}
                        isCookiesModalOpened={isCookiesModalOpened}
                        isCookiesUserAcceptanceExpired={isCookiesUserAcceptanceExpired}
                        isUserAcceptedCookies={isUserAcceptedCookies}
                    />
                    <Footer
                        onContactClick={onContactClick}
                        onCookiesClick={onCookiesClick}
                        onLegalClick={onLegalClick}
                    />
                </div>
            </Router>
        )
    }
}

MainLayout.propsTypes = {
    actualPage: PropTypes.string.isRequired,
    disconnectUser: PropTypes.func.isRequired,
    isMenuResponsive: PropTypes.bool.isRequired,
    logoutUser: PropTypes.func.isRequired,
    onConnectClick: PropTypes.func.isRequired,
    onContactClick: PropTypes.func.isRequired,
    onCookiesClick: PropTypes.func.isRequired,
    onHomeClick: PropTypes.func.isRequired,
    onLegalClick: PropTypes.func.isRequired,
    onProfileClick: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    actualPage: getActualPage(state),
    isMenuResponsive: isMenuResponsive(state),
    isUserConnected: isUserConnected(state),
    username: getUserFullname(state),
    userPermissions: getUserPermissions(state),
})

const mapDispatchToProps = (dispatch, props) => ({
    // Display cookies modal
    onCookiesClick: () => dispatch(saveCookiesChoices({isModalOpened: true, step: "CONFIGURATION"})),

    // Go to the contact page.
    onContactClick: () => window.open(DOMISERVE_CONTACT_URL),

    // Go to the connection page.
    onConnectClick: () => props.history.push(PagesArchitecture[Page.LOGIN].route),

    // Disconnect the current user.
    disconnectUser: () => {
        dispatch(disconnectUser()).then(() => {
            dispatch(clearCheckbooks())
            props.history.push(PagesArchitecture[Page.LOGIN].route)
        })
    },
    logoutUser: () => dispatch(logoutUser()),

    // Go to the home page.
    onHomeClick: () => {
        props.history.push(PagesArchitecture[Page.HOME].route)
    },
    // Go to the legal mentions page.
    onLegalClick: () => props.history.push(PagesArchitecture[Page.LEGAL_NOTICE].route),

    setMenuResponsive: isResponsive => {
        dispatch(setResponsive(isResponsive))
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayout))
