/**
 * Indicate that we start a get payments request
 */
export const GET_PAYMENTS_OF_INTERVENANT_REQUEST = "GET_PAYMENTS_OF_INTERVENANT_REQUEST"
/**
 * Indicate that the get of payments request was successful
 */
export const GET_PAYMENTS_OF_INTERVENANT_SUCCESS = "GET_PAYMENTS_OF_INTERVENANT_SUCCESS"
/**
 * Indicate that the get of payments request has failed
 */
export const GET_PAYMENTS_OF_INTERVENANT_FAILURE = "GET_PAYMENTS_OF_INTERVENANT_FAILURE"
