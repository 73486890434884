/**
 * Indicate that we launched a request to get the beneficiary titles history.
 * @type {string}
 */
export const GET_BENEFICIARY_TITLES_HISTORY_REQUEST = "GET_BENEFICIARY_TITLES_HISTORY_REQUEST"
/**
 * Indicate that the request to get the beneficiary titles history was a success.
 * @type {string}
 */
export const GET_BENEFICIARY_TITLES_HISTORY_SUCCESS = "GET_BENEFICIARY_TITLES_HISTORY_SUCCESS"
/**
 * Indicate that the request to get the beneficiary titles history failed
 * @type {string}
 */
export const GET_BENEFICIARY_TITLES_HISTORY_FAILURE = "GET_BENEFICIARY_TITLES_HISTORY_FAILURE"

/**
 * Indicate that we launched a request to get the beneficiary accounts history.
 * @type {string}
 */
export const GET_BENEFICIARY_ACCOUNTS_HISTORY_REQUEST = "GET_BENEFICIARY_ACCOUNTS_HISTORY_REQUEST"
/**
 * Indicate that the request to change get the beneficiary accounts history was a success.
 * @type {string}
 */
export const GET_BENEFICIARY_ACCOUNTS_HISTORY_SUCCESS = "GET_BENEFICIARY_ACCOUNTS_HISTORY_SUCCESS"
/**
 * Indicate that the request to get the beneficiary accounts history failed
 * @type {string}
 */
export const GET_BENEFICIARY_ACCOUNTS_HISTORY_FAILURE = "GET_BENEFICIARY_ACCOUNTS_HISTORY_FAILURE"

/**
 * Indicate that we launched a request to get the intervenant titles history.
 * @type {string}
 */
export const GET_INTERVENANT_TITLES_HISTORY_REQUEST = "GET_INTERVENANT_TITLES_HISTORY_REQUEST"
/**
 * Indicate that the request to get the intervenant titles history was a success.
 * @type {string}
 */
export const GET_INTERVENANT_TITLES_HISTORY_SUCCESS = "GET_INTERVENANT_TITLES_HISTORY_SUCCESS"
/**
 * Indicate that the request to get the intervenant titles history failed
 * @type {string}
 */
export const GET_INTERVENANT_TITLES_HISTORY_FAILURE = "GET_INTERVENANT_TITLES_HISTORY_FAILURE"

/**
 * Indicate that we launched a request to get the intervenant accounts history.
 * @type {string}
 */
export const GET_INTERVENANT_ACCOUNTS_HISTORY_REQUEST = "GET_INTERVENANT_ACCOUNTS_HISTORY_REQUEST"
/**
 * Indicate that the request to change get the intervenant accounts history was a success.
 * @type {string}
 */
export const GET_INTERVENANT_ACCOUNTS_HISTORY_SUCCESS = "GET_INTERVENANT_ACCOUNTS_HISTORY_SUCCESS"
/**
 * Indicate that the request to get the intervenant accounts history failed
 * @type {string}
 */
export const GET_INTERVENANT_ACCOUNTS_HISTORY_FAILURE = "GET_INTERVENANT_ACCOUNTS_HISTORY_FAILURE"
