import {combineReducers} from "redux"
import style, * as fromStyle from "../../shared-components/store/localStorage/style/styleReducer"
import texts, * as fromTexts from "../../shared-components/store/localStorage/texts/textsReducer"
import dynamicConfiguration, * as fromDynConf from "../../shared-components/store/localStorage/dynamicConfiguration/dynamicConfigurationReducer"
import titles, * as fromTitles from "./titles/titlesReducer"
import delivery, * as fromDelivery from "./delivery/deliveryReducer"
import confirmation, * as fromConfirmation from "./confirmation/confirmationReducer"
import cookies, * as fromCookies from "../../shared-components/store/localStorage/cookies/cookiesReducer"

/**
 * Part of the store to stock data which will be saved in local storage.
 */
const localStorage = combineReducers({
    cookies,
    delivery,
    dynamicConfiguration,
    style,
    texts,
    titles,
    confirmation,
})

export default localStorage

// DYNAMIC CONFIGURATION OPERATIONS
/**
 * Return the value associated with the key if it is found in the state,
 * undefined otherwise.
 */
export const getDynamicConfigurationValue = (state, key) => fromDynConf.getValue(state.dynamicConfiguration, key)

// STYLE OPERATIONS
/**
 * Get the style from the store.
 */
export const getStyle = (state, contractId) => fromStyle.getStyle(state.style, contractId)
/**
 * Is style customized ?
 */
export const isCustomizedStyle = (state, contractId) => fromStyle.isCustomized(state.style, contractId)

// TEXTS OPERATIONS
/**
 * Get the texts from the store.
 */
export const getTexts = (state, contractId) => fromTexts.getTexts(state.texts, contractId)
/**
 * Are texts customized ?
 */
export const areCustomizedTexts = (state, contractId) => fromTexts.areCustomized(state.texts, contractId)

// TITLES OPERATIONS
/**
 * Get title by id
 */
export const getTitle = (state, id) => (state ? fromTitles.getTitle(state.titles, id) : {})
/**
 * Get all titles
 */
export const getTitles = state => fromTitles.getTitles(state.titles)
/**
 * Get other informations
 */
export const getOtherInformations = state => fromTitles.getOtherInformations(state.titles)

// DELIVERY OPERATION
/**
 * Get delivery
 */
export const getDelivery = state => fromDelivery.getDelivery(state.delivery)

// CONFIRMATION OPERATIONS

/**
 * Get contract id
 */
export const getContractId = state => fromConfirmation.getContractId(state.confirmation)
/**
 * Get user email
 */
export const getEmail = state => fromConfirmation.getEmail(state.confirmation)
/**
 * Get millesime
 */
export const getMillesime = state => fromConfirmation.getMillesime(state.confirmation)
/**
 * Get old millesime
 */
export const getOldMillesime = state => fromConfirmation.getOldMillesime(state.confirmation)
/**
 * Get support type
 */
export const getSupportType = state => fromConfirmation.getSupportType(state.confirmation)
/**
 * Get trade type
 */
export const getTradeType = state => fromConfirmation.getTradeType(state.confirmation)
/**
 * Get total amount
 */
export const getTotalAmount = state => fromConfirmation.getTotalAmount(state.confirmation)
/**
 * Get total fees
 */
export const getTotalFees = state => fromConfirmation.getTotalFees(state.confirmation)

// COOKIES OPERATIONS
/**
 * Get cookies
 */
export const getCookiesChoices = state => fromCookies.getCookiesChoices(state.cookies)
