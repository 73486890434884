import {
    ADD_NOTIFICATION,
    CLEAR_NOTIFICATIONS,
    REMOVE_NOTIFICATION,
} from "../../actions/notifications/notificationsActionTypes"
import {CHANGE_ACTUAL_PAGE} from "../../actions/temporaries/page/pageActionTypes"

const notifications = (state = [], action = {}) => {
    switch (action.type) {
        case ADD_NOTIFICATION:
            // On efface les precedentes notifications d'erreur ayant un message identique
            return [
                ...state.filter(
                    notification => notification.type !== "error" || notification.header !== action.notification.header,
                ),
                action.notification,
            ]
        case REMOVE_NOTIFICATION:
            return state.filter(notification => notification.id !== action.notification.id)
        case CHANGE_ACTUAL_PAGE:
            if (action.clearNotifications) {
                return []
            } else {
                // On efface les notifications d'erreur à chaque changement de page ou notification indiquée à ne pas effacer au changement de page
                return state.filter(notification => notification.type !== "error" || notification.doNotClear)
            }
        case CLEAR_NOTIFICATIONS:
            return []
        default:
            return state
    }
}

export default notifications

/**
 * Return notifications
 */
export const getNotifications = state => state
