import { combineReducers } from "redux"
import {
    DISCONNECT_USER,
    CONNECT_USER_SUCCESS,
    CONNECT_USER_FAILURE,
} from "../../../../actions/sessionStorage/user/userActionTypes"
import { CommonRequestKeys } from "../../../../common/constants/requests"
import { REQUEST_SUCCESS, REQUEST_FAILURE } from "../../../requests/requestsReducer"
import { getPermissionsFromToken } from "../../../../utils/authUtil"

const authority = () => {
    const token = (state = null, action = {}) => {
        switch (action.type) {
            case CONNECT_USER_SUCCESS:
                return action.response.token
            case REQUEST_SUCCESS:
                if (action.requestKeyName === CommonRequestKeys.USER_AUTHORITY) {
                    return action.response.token
                }
                return state
            case REQUEST_FAILURE:
                if (action.requestKeyName === CommonRequestKeys.USER_AUTHORITY) {
                    return null
                }
                return state
            case CONNECT_USER_FAILURE:
            case DISCONNECT_USER:
                return null
            default:
                return state
        }
    }

    const expirationDate = (state = null, action = {}) => {
        switch (action.type) {
            case CONNECT_USER_SUCCESS:
                return action.response.expirationDate
            case REQUEST_SUCCESS:
                if (action.requestKeyName === CommonRequestKeys.USER_AUTHORITY) {
                    return action.response.expirationDate
                }
                return state
            case REQUEST_FAILURE:
                if (action.requestKeyName === CommonRequestKeys.USER_AUTHORITY) {
                    return null
                }
                return state
            case CONNECT_USER_FAILURE:
            case DISCONNECT_USER:
                return null
            default:
                return state
        }
    }

    const permissions = (state = [], action = {}) => {
        switch (action.type) {
            case CONNECT_USER_SUCCESS:
                return getPermissionsFromToken(action.response.token)
            case REQUEST_SUCCESS:
                if (action.requestKeyName === CommonRequestKeys.USER_AUTHORITY) {
                    return getPermissionsFromToken(action.response.token)
                }
                return state
            case REQUEST_FAILURE:
                if (action.requestKeyName === CommonRequestKeys.USER_AUTHORITY) {
                    return []
                }
                return state
            case CONNECT_USER_FAILURE:
            case DISCONNECT_USER:
                return []
            default:
                return state
        }
    }

    return combineReducers({
        token,
        expirationDate,
        permissions,
    })
}

export default authority

/**
 * Get the token from the state.
 */
export const getToken = state => state.token

/**
 * Get the expiration date.
 */
export const getExpirationDate = state => state.expirationDate

/**
 * Get the user permissions from the state.
 */
export const getPermissions = state => state.permissions
