import React from "react"
import PropTypes from "prop-types"
import "./normalMenu.scss"
import MenuItemContainer from "../MenuItem/menuItemContainer"
import MenuDropdownContainer from "../MenuDropdown/menuDropdownContainer"
import {Grid} from "semantic-ui-react"
import {displayMenu, isFeatureEnabled} from "../../services/displayMenu"

/**
 * Normal menu, for large screens.
 */
const NormalMenu = (
    {content, logo, redirectToHome, isConnected, title, userPermissions, displayNotificationFunction},
    {sheet},
) => (
    <div className="normal-menu-component">
        <Grid centered className="normal-menu-component-grid">
            <Grid.Column only="computer" width={1} />
            <Grid.Column width={14}>
                <div className="normal-menu-component-inner">
                    <div className="normal-menu-component-div">
                        <img
                            alt="logo"
                            className="normal-menu-component-div-logo"
                            onClick={redirectToHome}
                            src={logo}
                        />
                    </div>
                    <h1 className={sheet.classes.textSecondaryColor + " normal-menu-component-title"}>{title}</h1>
                    <div className="normal-menu-component-inner-right">
                        {/* Loop through menu content. */}
                        {content.map((c, index) => {
                            switch (c.type) {
                                // Menu item.
                                case "ITEM":
                                    return (
                                        displayMenu(
                                            isConnected,
                                            c.permissions,
                                            c.userConnected,
                                            userPermissions,
                                            c.isFeatureEnabled,
                                        ) && (
                                            <MenuItemContainer
                                                active={c.active}
                                                displayNotificationFunction={displayNotificationFunction}
                                                feature={c.feature}
                                                isConnected={isConnected}
                                                key={index}
                                                onClick={c.onClick}
                                            >
                                                {c.text}
                                            </MenuItemContainer>
                                        )
                                    )

                                // Menu dropdown list.
                                case "DROPDOWN":
                                    return (
                                        displayMenu(
                                            isConnected,
                                            c.permissions,
                                            c.userConnected,
                                            userPermissions,
                                            isFeatureEnabled(c.choices),
                                        ) && (
                                            <MenuDropdownContainer
                                                alignment={c.alignment}
                                                choices={c.choices}
                                                displayNotificationFunction={displayNotificationFunction}
                                                isConnected={isConnected}
                                                key={index}
                                                text={c.text}
                                                userPermissions={userPermissions}
                                            />
                                        )
                                    )

                                default:
                                    return <div>UNKOWN_TYPE</div>
                            }
                        })}
                    </div>
                </div>
            </Grid.Column>
            <Grid.Column only="computer" width={1} />
        </Grid>
    </div>
)

NormalMenu.contextTypes = {
    sheet: PropTypes.object,
}

NormalMenu.propTypes = {
    content: PropTypes.arrayOf(
        PropTypes.oneOfType([
            // An item.
            PropTypes.shape({
                type: PropTypes.oneOf(["ITEM"]),
                text: PropTypes.string.isRequired,
                onClick: PropTypes.func.isRequired,
                active: PropTypes.bool,
                permissions: PropTypes.arrayOf(PropTypes.string),
            }),
            // A dropdown list.
            PropTypes.shape({
                type: PropTypes.oneOf(["DROPDOWN"]),
                text: PropTypes.string.isRequired,
                // Panel alignment.
                alignment: PropTypes.oneOf(["LEFT", "RIGHT"]),
                permissions: PropTypes.arrayOf(PropTypes.string),
                choices: PropTypes.arrayOf(
                    PropTypes.oneOfType([
                        // A dropdown item.
                        PropTypes.shape({
                            type: PropTypes.oneOf(["ITEM"]),
                            text: PropTypes.string.isRequired,
                            onClick: PropTypes.func.isRequired,
                            active: PropTypes.bool,
                            permissions: PropTypes.arrayOf(PropTypes.string),
                        }),
                        // A dropdown text.
                        PropTypes.shape({
                            type: PropTypes.oneOf(["DROPDOWN"]),
                            text: PropTypes.string.isRequired,
                            choices: PropTypes.arrayOf(
                                PropTypes.shape({
                                    type: PropTypes.oneOf(["ITEM"]),
                                    text: PropTypes.string.isRequired,
                                    onClick: PropTypes.func.isRequired,
                                    active: PropTypes.bool,
                                    permissions: PropTypes.arrayOf(PropTypes.string),
                                }),
                            ),
                        }),
                    ]),
                ).isRequired,
            }),
        ]),
    ).isRequired,

    // Source of the logo.
    logo: PropTypes.string.isRequired,
    redirectToHome: PropTypes.func.isRequired,
    isConnected: PropTypes.bool.isRequired,

    // Menu title
    title: PropTypes.string.isRequired,

    userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    displayNotificationFunction: PropTypes.func,
}

export default NormalMenu
