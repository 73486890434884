/**
 * Indicate that we launched a request to retrieve billing statuses.
 * @type {string}
 */
export const RETRIEVE_BILLING_STATUSES_REQUEST = 'RETRIEVE_BILLING_STATUSES_REQUEST';

/**
 * Indicate that we retrieved successfully the billing statuses.
 * @type {string}
 */
export const RETRIEVE_BILLING_STATUSES_SUCCESS = 'RETRIEVE_BILLING_STATUSES_SUCCESS';

/**
 * Indicate that we didn't receive the billing statuses.
 * @type {string}
 */
export const RETRIEVE_BILLING_STATUSES_FAILURE = 'RETRIEVE_BILLING_STATUSES_FAILURE';
