import React from "react"
import PropTypes from "prop-types"
import "./menuDropdownPush.scss"
import MenuItemContainer from "../MenuItem/menuItemContainer"
import FadeTransition from "../FadeTransition/fadeTransition"
import MobileMenuItemDropdownContainer from "../MobileMenuItemDropdown/mobileMenuItemDropdownContainer"
import {displayMenu, isFeatureEnabled} from "../../services/displayMenu"
import {Icon} from "semantic-ui-react"

/**
 * Dropdown list component for a menu.
 * Open and close on click.
 * Menu content push the background.
 */
const MenuDropdownPush = (
    {isConnected, text, choices, isOpened, toggleOpen, userPermissions, displayNotificationFunction},
    {sheet},
) => {
    const isActive = choices.reduce((acc, current) => {
        return acc || current.active
    }, false)

    return (
        <div className="menu-dropdown-push-component">
            <MenuItemContainer
                active={isOpened || isActive}
                className="menu-dropdown-push-item"
                displayNotificationFunction={displayNotificationFunction}
                features={choices.map(c => c.feature)}
                onClick={toggleOpen}
            >
                {/* Menu header. Contains the header's text and the little arrow. */}
                <div className="menu-dropdown-push-item-content">
                    {text}

                    {/* Arrow down when closed, up when opened. */}
                    {!isOpened && <Icon className="menu-dropdown-push-icon" name="caret down" />}
                    {isOpened && <Icon className="menu-dropdown-push-icon reverse" name="caret down" />}
                </div>
            </MenuItemContainer>

            {/* Open with a fade transition. */}
            {isOpened && (
                <FadeTransition>
                    <div className={sheet.classes.borderSecondaryColor + " menu-dropdown-push-component-panel"}>
                        {/* Loop through all content. */}
                        {choices.map((choice, index) => {
                            switch (choice.type) {
                                // Menu item.
                                case "ITEM":
                                    return (
                                        displayMenu(
                                            isConnected,
                                            choice.permissions,
                                            choice.userConnected,
                                            userPermissions,
                                            choice.isFeatureEnabled,
                                        ) && (
                                            <MenuItemContainer
                                                active={choice.active}
                                                className="menu-dropdown-push-component-choice"
                                                displayNotificationFunction={displayNotificationFunction}
                                                features={choice.feature}
                                                key={index}
                                                onClick={choice.onClick}
                                            >
                                                {choice.text}
                                            </MenuItemContainer>
                                        )
                                    )

                                // Menu dropdown list.
                                case "DROPDOWN":
                                    return (
                                        displayMenu(
                                            isConnected,
                                            choice.permissions,
                                            choice.userConnected,
                                            userPermissions,
                                            isFeatureEnabled(choice.choices),
                                        ) && (
                                            <div
                                                className="menu-dropdown-push-component-choice menu-dropdown-push-component-choice-item menu-dropdown-push-component-choice-dropdown"
                                                key={index}
                                            >
                                                <MobileMenuItemDropdownContainer
                                                    choices={choice.choices}
                                                    displayNotificationFunction={displayNotificationFunction}
                                                    text={choice.text}
                                                    userPermissions={userPermissions}
                                                />
                                            </div>
                                        )
                                    )

                                default:
                                    return <div key={index}>UNKOWN_TYPE</div>
                            }
                        })}
                    </div>
                </FadeTransition>
            )}
        </div>
    )
}

MenuDropdownPush.contextTypes = {
    sheet: PropTypes.object,
}

MenuDropdownPush.propTypes = {
    // The header's text.
    text: PropTypes.string.isRequired,
    isConnected: PropTypes.bool.isRequired,
    // The menu content.
    choices: PropTypes.arrayOf(
        PropTypes.oneOfType([
            // A dropdown item.
            PropTypes.shape({
                type: PropTypes.oneOf(["ITEM"]),
                text: PropTypes.string.isRequired,
                onClick: PropTypes.func.isRequired,
                active: PropTypes.bool,
            }),
            // A dropdown text.
            PropTypes.shape({
                type: PropTypes.oneOf(["DROPDOWN"]),
                text: PropTypes.string.isRequired,
                choices: PropTypes.arrayOf(
                    PropTypes.shape({
                        type: PropTypes.oneOf(["ITEM"]),
                        text: PropTypes.string.isRequired,
                        onClick: PropTypes.func.isRequired,
                        active: PropTypes.bool,
                    }),
                ),
            }),
        ]),
    ).isRequired,

    // Open state.
    isOpened: PropTypes.bool.isRequired,
    toggleOpen: PropTypes.func.isRequired,
    displayNotificationFunction: PropTypes.func,
}

export default MenuDropdownPush
