/**
 * Indicate that we launched a request to retrieve the global texts.
 * @type {string}
 */
export const RETRIEVE_TEXTS_REQUEST = 'RETRIEVE_TEXTS_REQUEST';

/**
 * Indicate that we retrieved successfully the global texts.
 * @type {string}
 */
export const RETRIEVE_TEXTS_SUCCESS = 'RETRIEVE_TEXTS_SUCCESS';

/**
 * Indicate that we didn't receive the global texts.
 * @type {string}
 */
export const RETRIEVE_TEXTS_FAILURE = 'RETRIEVE_TEXTS_FAILURE';
