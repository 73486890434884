import {
    CLEAR_DYNAMIC_CONFIGURATION,
    SAVE_DYNAMIC_CONFIGURATION_VALUE,
} from "../../../actions/localStorage/dynamicConfiguration/dynamicConfigurationActionTypes";

/**
 * Save dynamic configuration key/value pairs into the store.
 * Can empty the dynamic configuration values.
 */
const dynamicConfiguration = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_DYNAMIC_CONFIGURATION_VALUE:
            return {
                ...state,
                [action.key]: action.value,
            };
        case CLEAR_DYNAMIC_CONFIGURATION:
            return {};
        default:
            return state;
    }
};

export default dynamicConfiguration;

/**
 * Return the value associated to the key.
 */
export const getValue = (state, key) => state[key];
