import {combineReducers} from "redux"
import {values} from "lodash"
import {EntityInfo} from "../../constants/entities"

export const entitiesReducerFactory = (entitiesName, actionType) => {
    return (state = {}, action = {}) => {
        if (action.type === actionType) {
            return {
                ...state,
                ...action.response.entities[entitiesName],
            }
        } else {
            return state
        }
    }
}

const reducers = {}
values(EntityInfo).forEach(e => (reducers[e.name] = entitiesReducerFactory(e.name, e.actionTypeSuccess)))

/**
 * Part of the store to stock entities.
 */
const entities = combineReducers(reducers)

export default entities

// Get an entity from the store by its id
export const getEntity = (entityName, state, id) => state[entityName][id]
export const getEntities = (entityName, state) => state[entityName]
