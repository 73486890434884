import React from "react"
import PropTypes from "prop-types"
import "./menuItem.scss"
import {Icon} from "semantic-ui-react"

/**
 * Item in a menu.
 */
const MenuItem = (
    {onClick, isHovering, active, onMouseEnter, onMouseLeave, children, displayNotification},
    {sheet},
) => (
    // Text color is secondary color when hovering, tertiary color otherwise.
    // When item is active, color stay to secondary color.
    <div
        className={
            (isHovering || active ? sheet.classes.textSecondaryColor : sheet.classes.textTertiaryColor) +
            " menu-item font-bold"
        }
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
    >
        {displayNotification && <Icon className={sheet.classes.textDangerColor} name="bell" />}
        {children}
    </div>
)

MenuItem.contextTypes = {
    sheet: PropTypes.object,
}

MenuItem.propTypes = {
    onClick: PropTypes.func,
    active: PropTypes.bool,
    displayNotification: PropTypes.bool,
    isHovering: PropTypes.bool.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
}

export default MenuItem
