import {get, post} from "axios"
import dayjs from "dayjs"
import FileSaver from "file-saver"
import {toUpper} from "lodash"
import {INTERVENANTS_ENDPOINTS} from "../../../constants/endpoints"
import {RequestInfo, RequestKeys} from "../../../constants/requests"
import {getErrorMessage} from "../../../services/api"
import {REQUEST, REQUEST_FAILURE, REQUEST_SUCCESS} from "../../../store/requests/requestsReducer"
import {
    CLEAR_CHECKBOOKS,
    HANDOVER_CHECKBOOK_FAILURE,
    HANDOVER_CHECKBOOK_REQUEST,
    HANDOVER_CHECKBOOK_SUCCESS,
    REMOVE_CHECKBOOK,
} from "./handOverCheckbooksActionTypes"

/**
 * Action made by an intervenant
 * @param {*} number checkbook number
 * @param {*} value checkbook value
 * @param {*} secretCode checkbook secretCode
 */
export const handOverCheckbook = (number, value, secretCode) => dispatch => {
    dispatch({
        type: HANDOVER_CHECKBOOK_REQUEST,
    })

    const body = {
        id: number,
        valeur: value,
        codeSecret: secretCode,
    }

    return post(INTERVENANTS_ENDPOINTS.validateCheckbook, body).then(
        response => {
            dispatch({
                type: HANDOVER_CHECKBOOK_SUCCESS,
                checkbook: {
                    id: response.data.id,
                    millesime: response.data.millesime ? Number(response.data.millesime) : response.data.millesime,
                    beneficiaryName: toUpper(response.data.nomBeneficiaire),
                    secretCode: response.data.codeSecret ? Number(response.data.codeSecret) : response.data.codeSecret,
                    facialValue: response.data.valeur ? Number(response.data.valeur) : response.data.valeur,
                    state: response.data.etat,
                },
                checkbookType: "SIMPLE",
            })

            return Promise.resolve(response.data)
        },
        error => {
            dispatch({
                type: HANDOVER_CHECKBOOK_FAILURE,
                message: getErrorMessage(error),
            })
            return Promise.reject()
        },
    )
}

/**
 * Action made by an intervenant
 * @param {*} number checkbook number
 * @param {*} value checkbook value
 * @param {*} barCode checkbook barCode
 */
export const handOverCheckbookWithBarcode = (number, value, barCode) => dispatch => {
    dispatch({
        type: HANDOVER_CHECKBOOK_REQUEST,
    })

    const body = {
        id: number,
        valeur: value,
        codeBarre: barCode,
    }

    return post(INTERVENANTS_ENDPOINTS.validateCheckbookWithBarcode, body).then(
        response => {
            // If the response does not contain id
            // Extract the id from the barCode
            const checkbook = {
                id: response.data.id ? response.data.id : Number(response.data.codeBarre.substring(1, 10)),
                millesime: response.data.millesime ? Number(response.data.millesime) : response.data.millesime,
                beneficiaryName: toUpper(response.data.nomBeneficiaire),
                barCode: response.data.codeBarre,
                facialValue: response.data.valeur ? Number(response.data.valeur) : response.data.valeur,
                state: response.data.etat,
                validity: response.data.validationCodeBarre === "VALIDE" ? "Valide" : "En rejet",
                rejectionReason: response.data.causeRejectionCodeBarre,
            }

            dispatch({
                type: HANDOVER_CHECKBOOK_SUCCESS,
                checkbook,
                checkbookType: "BARCODE",
            })

            return Promise.resolve(checkbook)
        },
        error => {
            dispatch({
                type: HANDOVER_CHECKBOOK_FAILURE,
                message: getErrorMessage(error),
            })
            return Promise.reject()
        },
    )
}

/**
 * Action made by an intervenant
 * @param checkbooks
 * @returns {function(*): *}
 */
export const handOverCheckbookListWithBarCode =
    (checkbooks = {}) =>
    dispatch => {
        dispatch({
            type: HANDOVER_CHECKBOOK_REQUEST,
        })

        return post(INTERVENANTS_ENDPOINTS.validateCheckbookListWithBarcode, checkbooks).then(
            response => {
                const checkbooks = {}
                response.data.forEach(function (checkbook) {
                    checkbooks[checkbook.codeBarre] = {
                        id: checkbook.id ? checkbook.id : Number(checkbook.codeBarre.substring(1, 10)),
                        millesime: checkbook.millesime ? Number(checkbook.millesime) : checkbook.millesime,
                        beneficiaryName: toUpper(checkbook.nomBeneficiaire),
                        barCode: checkbook.codeBarre,
                        facialValue: checkbook.valeur ? Number(checkbook.valeur) : checkbook.valeur,
                        state: checkbook.etat,
                        validity: checkbook.validationCodeBarre === "VALIDE" ? "Valide" : "En rejet",
                        rejectionReason: checkbook.causeRejectionCodeBarre,
                    }
                })

                dispatch({
                    type: HANDOVER_CHECKBOOK_SUCCESS,
                    checkbooks,
                    checkbookType: "BARCODE_LIST",
                })

                return Promise.resolve()
            },
            error => {
                dispatch({
                    type: HANDOVER_CHECKBOOK_FAILURE,
                    message: getErrorMessage(error),
                })
                return Promise.reject()
            },
        )
    }

export const createSimpleHandOver = checkbooks => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.CREATE_SIMPLE_HANDOVER].name,
    })

    return post(INTERVENANTS_ENDPOINTS.createSimpleHandOver, checkbooks).then(
        response => {
            const refund = {
                id: response.data.id,
                number: response.data.numRemise,
                creationDate: dayjs(response.data.dateDemande).format("DD/MM/YYYY HH:mm:ss"),
                totalAmount: response.data.montantTotal,
            }

            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.CREATE_SIMPLE_HANDOVER].name,
                payload: refund,
            })

            return Promise.resolve(refund)
        },
        error => {
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.CREATE_SIMPLE_HANDOVER].name,
                message: getErrorMessage(error),
            })

            return Promise.reject()
        },
    )
}

export const createBarCodeHandOver = checkbooks => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.CREATE_BARCODE_HANDOVER].name,
    })

    return post(INTERVENANTS_ENDPOINTS.createBarCodeHandOver, checkbooks).then(
        response => {
            const refund = {
                id: response.data.id,
                number: response.data.numRemise,
                creationDate: dayjs(response.data.dateDemande).format("DD/MM/YYYY HH:mm:ss"),
                totalAmount: response.data.montantTotal,
            }

            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.CREATE_BARCODE_HANDOVER].name,
                payload: refund,
            })

            return Promise.resolve(refund)
        },
        error => {
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.CREATE_BARCODE_HANDOVER].name,
                message: getErrorMessage(error),
            })

            return Promise.reject()
        },
    )
}

export const createFileHandover = inputFileId => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.CREATE_FILE_HANDOVER].name,
    })

    const data = new FormData()
    const file = document.getElementById(inputFileId).files[0]

    data.append("refundDepositFile", file, file.name)

    return post(INTERVENANTS_ENDPOINTS.createFileHandOver, data).then(
        () => {
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.CREATE_FILE_HANDOVER].name,
            })
            return Promise.resolve()
        },
        error => {
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.CREATE_FILE_HANDOVER].name,
                message: getErrorMessage(error),
            })
            return Promise.reject()
        },
    )
}

export const generateHandOverPdf = remiseId => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.GENERATE_HANDOVER_PDF].name,
    })

    return get(INTERVENANTS_ENDPOINTS.generatePdf(remiseId), {responseType: "blob"}).then(
        response => {
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.GENERATE_HANDOVER_PDF].name,
            })

            FileSaver.saveAs(response.data, "Bordereau_de_remise.pdf")
            return Promise.resolve()
        },
        error => {
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.GENERATE_HANDOVER_PDF].name,
                message: getErrorMessage(error),
            })
            return Promise.reject()
        },
    )
}

/**
 * Remove a checkbook
 * @param {*} id
 */
export const removeCheckbook = id => ({
    type: REMOVE_CHECKBOOK,
    id,
})

/**
 * Clear all checkbooks
 */
export const clearCheckbooks = () => ({
    type: CLEAR_CHECKBOOKS,
})
