/**
 * Enumeration for product types.
 */
export const ProductTypes = {
    CHECKBOOK: "TITRE",
    TITLE: "TITRE",
    ACCOUNT: "COMPTE",
    UNKNOWN: "INCONNU",
}
/**
 * Product types options for select list
 */
export const ProductTypesOptions = [
    {key: "UNKNOWN", value: null, text: "Non renseigné"},
    {key: "TITLE", value: "TITRE", text: "Titre CESU"},
    {key: "ACCOUNT", value: "COMPTE", text: "Compte CESU"},
]
